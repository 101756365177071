<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>我的用户中心</span>
      </div>
      <div>
        <div class="pad_10_0" style="margin-bottom: 6px">消费概况</div>
        <div class="wh_f bor_1_hui pad_20 boxs bor_5">
          <div class="flex_1">
            <div>
              <span class="fz_40 em">{{ age.total_amount }}</span>
              <span>元</span>
            </div>
            <div>
              <div
                class="disp bor_255"
                style="width: 10px; height: 10px; background-color: #dc2d22"
              ></div>
              <span class="mag_l-5">累计消费</span>
            </div>
          </div>
          <div class="flex_1">
            <div>
              <span class="fz_40 em">{{ age.year_amount }}</span>
              <span>元</span>
            </div>
            <div>
              <div
                class="disp bor_255"
                style="width: 10px; height: 10px; background-color: #f56808"
              ></div>
              <span class="mag_l-5">本年消费</span>
            </div>
          </div>
          <div class="flex_1">
            <div>
              <span class="fz_40 em">{{ age.moth_amount }}</span>
              <span>元</span>
            </div>
            <div>
              <div
                class="disp bor_255"
                style="width: 10px; height: 10px; background-color: #52c41a"
              ></div>
              <span class="mag_l-5">本月消费</span>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-tabs
      class="ba_white mag_t-10"
      style="padding: 10px 20px 0"
      v-model="activeName"
    >
      <el-tab-pane label="支出明细" name="first">
        <div v-if="activeName === 'first'">
          <el-card class="box-card mag_t-20">
            <div>
              <div class="pad_10_0" style="margin-bottom: 6px">消费明细</div>
              <div class="">
                <el-table
                  :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                  :data="list"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="order_goods[0].goods_name"
                    label="标题"
                    width="280"
                  >
                  </el-table-column>
                  <el-table-column prop="order_amount" label="金额" width="280">
                  </el-table-column>
                  <el-table-column prop="create_time" label="时间">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
      <el-tab-pane label="收入明细" name="second">
        <div v-if="activeName === 'second'">
          <el-card class="box-card mag_t-20">
            <div>
              <div class="pad_10_0" style="margin-bottom: 6px">收入明细</div>
              <div class="">
                <el-table
                  :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                  :data="listTo"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="goods_snap.goods_name"
                    label="标题"
                    width="280"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="goods_snap.total_original_price"
                    label="金额"
                    width="280"
                  >
                  </el-table-column>
                  <el-table-column prop="create_time" label="时间">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-card>
          <el-empty
            v-if="listTo.length == 0"
            description="暂无收入明细 !"
          ></el-empty>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getorderList, getQianbao, getShouHou } from "../../api/api";
// import { getShouHou } from "../../api/store";

export default {
  name: "myuserIndex",
  data() {
    return {
      activeName: "first",
      age: [],
      list: [],
      listTo: [],
      p: 1,
      s: 100,
      total: null,
    };
  },
  created() {
    this.$store.state.leftMenuId = 2;
  },
  methods: {
    handleSizeChange(newSize) {
      this.s = newSize;
      console.log(newSize);
      this.getList(this.p, this.s);
    },
    handleCurrentChange(newPage) {
      this.p = newPage;
      console.log(newPage);
      this.getList(this.p, this.s);
    },
    getList(p, s) {
      getorderList({
        type: "all",
        pay_status: 1,
        page_no: p,
        page_size: s,
      }).then((res) => {
        this.list = res.data.data.lists;
        this.total = res.data.data.count;
      });
    },
  },
  mounted() {
    this.getList(this.p, this.s);

    const defl = {
      moth_amount: "0",
      total_amount: "0",
      year_amount: "0",
    };
    getQianbao().then((res) => {
      // var result = res?.data?.data[0];
      var {
        data: {
          data: [result],
        },
      } = res;
      result = result || defl;
      this.age = result;
    });

    getShouHou({
      type: "status_ing",
    }).then((res) => {
      this.listTo = res.data.data.lists;
    });
  },
};
</script>

<style scoped>
::v-deep .el-tabs__item.is-active {
  color: #dc2d22;
  opacity: 1;
}
::v-deep .el-tabs__item:hover {
  color: #dc2d22;
  cursor: pointer;
  opacity: 1;
}
::v-deep .el-tabs__active-bar {
  background-color: #dc2d22;
}
</style>
