<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18 em">业务下单</div>
      <TopActive></TopActive>
      <!--      <div class="ba_white pad_50_0">-->
      <!--        <el-steps :active="active"  align-center>-->
      <!--          <el-step v-for="(item,index) in order" :key="index" :title="item"></el-step>-->
      <!--        </el-steps>-->
      <!--      </div>-->
      <div>
        <div class="ba_white mag_t-30">
          <div class="">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
              <div class="em" style="padding: 10px 0 0 20px">上传报告</div>
              <div class="ddisp bor_b_xu pad_20 split_2">
                <el-form-item label="上传报告文件" prop="上传报告文件">
                  <inputUpload class="mag_l-20" @upload="hand"></inputUpload>
                </el-form-item>
              </div>
              <div class="em" style="padding: 10px 0 0 20px">订单信息</div>
              <div class="wh_f bor_b_hui pad_20">
                <div class="flex_1">
                  <div class="pad_10_0 wz_hui fz_14">业务信息</div>
                  <div class="ddisp">
                    <div class="topdq">
                      <img
                        :src="
                          $store.state.userOrderDetaild.order_goods[0]
                            .goods_image
                        "
                        style="width: 80px"
                        alt=""
                      />
                    </div>
                    <div class="topdq pad_l_5 boxs">
                      <p class="em">
                        {{
                          $store.state.userOrderDetaild.order_goods[0]
                            .goods_name
                        }}
                      </p>
                      <!--                      <p class="wz_hui pad_10_0 fz_14">默认 (此为规格信息)</p>-->
                    </div>
                  </div>
                </div>
                <div class="flex_1 wh_f fz_14 wz_hui">
                  <div class="flex_1">
                    订单总价
                    <div class="wz_black pad_10_0">
                      ￥{{ $store.state.userOrderDetaild.order_amount }}
                    </div>
                  </div>
                  <div class="flex_1">
                    订单状态
                    <div class="wz_black pad_10_0">
                      {{ $store.state.userOrderDetaild.order_status_desc }}
                    </div>
                  </div>
                  <div class="flex_1">
                    下单留言
                    <div class="pad_10_0 wz_hui">
                      {{ $store.state.userOrderDetaild.baining_remark }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="wh_f pad_20">
                <div class="flex_1"></div>
                <div class="flo_r fz_14">
                  <div class="wz_rigdq" style="padding: 50px 0 20px">
                    <span
                      @click="addHeYan"
                      class="wz_white bor_3 xsgb"
                      style="padding: 20px 30px; background-color: #d9001b"
                      >提交</span
                    >
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getjdbg_Add, addZL } from "../../api/api";

export default {
  name: "scjdbg",
  data() {
    return {
      rules: {
        上传报告文件: [
          { required: true, message: "请上传解读报告", trigger: "blur" },
        ],
      },
      active: 2,
      order: ["下单支付费用", "上传需分析解读的报告", "获取解读报告"],
      form: {},
      uploadedFile: {
        url: "",
      },
    };
  },
  methods: {
    /**
     * 记录到资料库
     */
    async uploadToLibrary(uri) {
      // 获取文件名字
      let info_title = uri.replace(/^.*[\\/]/, "");
      let id = this.$store.state.userItem.id;
      addZL({
        info_path: uri,
        info_title,
        user_id: id,
      }).then((e) => {
        console.log(e);
      });
    },
    hand(file) {
      this.uploadedFile = file.response.data;
      console.log(this.uploadedFile);
    },
    addHeYan() {
      getjdbg_Add({
        sn: this.$store.state.userOrderDetaild.sn,
        report_file: this.uploadedFile.url,
        order_id: this.$store.state.userOrderDetaild.id,
      }).then(async (res) => {
        if (res.data.code == 1) {
          await this.uploadToLibrary(this.uploadedFile.url);
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.$router.push("yeWuIndex");
        } else {
          // this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped></style>
