import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Css from "../src/assets/mdmyu.css";
import animated from "animate.css";
Vue.use(animated);
// import api from "../src/api/api";
import TopMenu from ".././src/components/HeaderMenu";
import BottomMenu from ".././src/components/BottomMenu";
import ReturnTop from ".././src/components/ReturnTop";
import ImgUpload from ".././src/components/ImgUpload";
import payOk from ".././src/components/payOk";
import payNo from ".././src/components/payNo";
import payErr from ".././src/components/payErr";
import S_2 from ".././src/components/S_2";
import test from ".././src/components/test";
import inputUpload from ".././src/components/inputUpload";
import TopActive from ".././src/components/TopActive";
import xF from ".././src/components/xF";
import lookBjcl from ".././src/components/lookBjcl";
Vue.component("lookBjcl", lookBjcl);
import Pay_SE from ".././src/components/Pay_SE";
Vue.component("Pay_SE", Pay_SE);
import order_Top from ".././src/components/order_Top";
import log from ".././src/components/log";
Vue.component("log", log);
Vue.component("order_Top", order_Top);
Vue.component("xF", xF);
Vue.component("test", test);
Vue.component("S_2", S_2);
Vue.component("inputUpload", inputUpload);
Vue.component("TopActive", TopActive);
Vue.component("TopMenu", TopMenu);
Vue.component("payOk", payOk);
Vue.component("payNo", payNo);
Vue.component("payErr", payErr);
Vue.component("ReturnTop", ReturnTop);
Vue.component("ImgUpload", ImgUpload);
Vue.component("BottomMenu", BottomMenu);
Vue.config.productionTip = false;
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
window.Vue = this;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import store from "./store";
Vue.use(Css);
import axios from "axios";
Vue.prototype.$http = axios;

import "@/assets/css/class-style-h5-020.css";

// 这个方法需要放在new Vue之前 不然按F5刷新页面不会调用这个方法
router.beforeEach(function (to, from, next) {
  if (to.meta.requireAuth) {
    if (localStorage.getItem("tokenAnShui")) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
    // if (to.name === 'yeWuIndex' && from.name !== 'yeWuIndex') {
    //   next();
    // } else {
    //   next(false);
    // }
  }
});

// Vue.use(api)
new Vue({
  created() {
    document.title = "安税网"; // 将标题设置为 "安税网"
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
