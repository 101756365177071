<template>
  <div style="padding: 150px 0">
    <div style="width: 1200px" class="jz bor_1_hui">
      <div v-if="id==1" class="wz_jz fz_20 em pad_30_0">
        服务协议
      </div>
      <div v-if="id==2" class="wz_jz fz_20 em pad_30_0">
        隐私政策
      </div>
      <div style="height: 500px">
        {{ count }}
      </div>
        <div class="ddisp pad_10_0 wz_jz">
          <el-button @click="$router.back()">取消</el-button>
          <el-button :disabled="isOk" type="primary">
            我已同意并阅读
            <span v-if="isOk">( {{num}} )</span>
          </el-button>
        </div>
    </div>
  </div>
</template>

<script>
import {getloginCode, getuserInfo, getXieyi} from "../../api/api";

export default {
  name: "agreement",
  data(){
    return{
      count:null,
      id:JSON.parse(this.$route.query.uid),
      num:5,
      isOk:true
    }
  },
  methods:{
    go(){

    },
    addTime(){
      const codeChecked = setInterval(() => {
        this.num--;
        if (this.num <= 0) {
          clearInterval(codeChecked);  // 清除计时器
          this.isOk = false;  // 启用按钮
        }
      }, 1000);
    }
  },
  mounted() {
    this.addTime()
    getXieyi({
      type:this.id
    }).then(res=>{
      this.count = res.data.data.count
    })
  }
}
</script>

<style scoped>

</style>