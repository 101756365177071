<template>
  <div>
    <el-dialog
      title="订单支付"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <div class="fz_16" style="padding: 0 40px">
        <div>
          <div class="ddisp">
            <div class="wz_leftdq" style="width: 130px">产品名称:</div>
            <div>
              {{ $store.state.userOrderDetaild.order_goods[0].goods_name }}
            </div>
          </div>
          <div class="ddisp pad_20_0">
            <div class="wz_leftdq" style="width: 130px">订单编号:</div>
            <div>{{ $store.state.userOrderDetaild.sn }}</div>
          </div>
          <div class="ddisp">
            <div class="wz_leftdq" style="width: 130px">订单金额:</div>
            <div>
              <span class="wz_red"
                >¥ {{ $store.state.userOrderDetaild.order_amount }}</span
              >
            </div>
          </div>
          <div class="ddisp pad_20_0">
            <div class="wz_leftdq" style="width: 130px">下单人:</div>
            <div>{{ $store.state.userOrderDetaild.user_name }}</div>
          </div>
        </div>
        <div>
          <div class="em" style="padding: 10px 0 20px">请选择支付方式</div>
        </div>
        <div class="flex_1 ba_white ddisp">
          <div
            @click="payAdd(item.pay_way)"
            v-for="(item, index) in payItem"
            :key="index"
            class="zf_hover bor_1_hui bor_5 mag_l-20 xsgb"
            style="padding: 10px 0; width: 200px"
          >
            <img class="centerdq mag_l-20" :src="item.icon" alt="" width="36" />
            <span class="mag_l-20 centerdq fz_14">{{ item.name }}</span>
          </div>
        </div>
        <div class="wh_f">
          <div class="flex_1"></div>
          <div class="flo_r pad_t_20">
            <span slot="footer" class="dialog-footer">
              <!--            <el-button type="primary" @click="pay" style="background-color: #1784fc">立即支付</el-button>-->
              <el-button @click="xf(3)">取消</el-button>
            </span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="微信支付"
      :visible.sync="showWxpay"
      width="700px"
      center
      @close="clearTimer"
    >
      <div class="flex flex-col row-center black wz_jz">
        <!-- <img class="pay-code" :src="payInfo.config.code_url" alt=""/> -->
        <VueQr
          class="bicode"
          :logoScale="20"
          :margin="0"
          :dotScale="1"
          :text="payInfo.config.code_url"
        ></VueQr>
        <div class="m-t-8 pad_10_0" style="font-size: 18px">
          微信扫一扫，完成支付
        </div>
        <img :src="payInfo.config.code_url" alt="" />
        <div class="pay-money flex">
          <span>需支付金额：</span>
          <span style="font-size: 28px; color: #ff2214">
            {{ payInfo.config.order_amount }}
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getApiPwy, getPay, getpayType } from "../api/api";

import VueQr from "vue-qr";
export default {
  name: "xF",
  props: {
    order_item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: true,
      payItem: [],
      item: {},
      pay: [],
      showWxpay: false,
      showAlipay: false,
      payWayArr: [],
      payInfo: {
        config: {
          code_url: "",
          order_amount: 0,
        },
      },
      cancelTime: 0,
      alipayHtml: "",
    };
  },
  components: {
    VueQr,
  },
  methods: {
    childMethod() {
      console.log("我触发了");
      this.dialogVisible = true;
    },
    // 支付
    async payAdd(payWay) {
      try {
        const { data, code, msg } = await getPay({
          order_id: this.order_item.id,
          pay_way: payWay,
          from: "order",
        });
        console.log("调用支付", data);
        if (data.code == 1 && data.data.pay_way == 2) {
          console.log("进入微信支付", data);
          // 调用微信支付 存储data
          this.payInfo = data.data;
          this.showWxpay = true;
          // 调用支付
          this.createTimer();
        } else if (data.code == 1 && data.data.pay_way == 3) {
          // 支付宝支付
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = data.config; // data.config就是接口返回的form 表单字符串
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      } catch (error) {
        // loading.close();
      }
    },
    // 微信支付
    createTimer() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getOrder();
      }, 4000);
    },
    clearTimer() {
      clearInterval(this.timer);
    },
    xf(e) {
      this.$emit("child", e);
    },
    async getOrder() {
      getApiPwy(
        {
          id: this.order_item.id,
        },
        true
      ).then((res) => {
        console.log("支付res", res.data.pay_status);
        var code = res.data.code;
        if (code == 1) {
          console.log("进入检查支付是否成功阶段", res.data.data.pay_status);
          this.order = res.data;
          if (res.data.data.pay_status == 1) {
            clearInterval(this.timer);
            this.showWxpay = false;
            this.$message({
              message: "支付成功",
              type: "success",
            });
            this.xf(3);
          }
        }
      });
    },
    getPayList() {
      getpayType({
        from: "order",
        order_id: this.order_item.id,
        scene: 4,
      }).then((res) => {
        this.payItem = res.data.data.lists;
        console.log("支付方式", this.payItem);
        this.root = 2;
      });
    },
  },
  mounted() {
    console.log("this.order_item", this.order_item);
    this.dialogVisible = true;
    this.getPayList();
  },
};
</script>

<style>
.el-dialog__headerbtn {
  display: none;
}
</style>
