export const yeWus = [
  {
    id: 10,
    pid: 0,
    name: "完税产品",
    level: 1,
    image: "",
    gooods: [],
    img: true,
    // img_1: require("../../../public/assets/baogao_1.png"),
    // img_2: require("../../../public/assets/baogao_2.png"),
    sons: [
      {
        id: 9,
        pid: 10,
        name: "返税业务",
        level: 2,
        image: "",
        gooods: [
          {
            goods_id: 24,
            goods_name: "返税业务",
          },
          {
            goods_id: 30,
            goods_name: "完税产品-返税业务01",
          },
        ],
      },
      {
        id: 7,
        pid: 10,
        name: "自然人开票",
        level: 2,
        image: "",
        gooods: [
          {
            goods_id: 26,
            goods_name: "完税产品-自然人代开票",
          },
        ],
      },
      {
        id: 6,
        pid: 10,
        name: "个体户开票",
        level: 2,
        image: "",
        gooods: [
          {
            goods_id: 27,
            goods_name: "完税产品-个体户代开票",
          },
        ],
      },
      {
        id: 5,
        pid: 10,
        name: "个体户开办",
        level: 2,
        image: "",
        gooods: [
          {
            goods_id: 28,
            goods_name: "完税产品-个体户代开办",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    pid: 0,
    name: "数字化报告",
    level: 1,
    image: "",
    img: true,
    // img_1: require("../../../public/assets/guquan_1.png"),
    // img_2: require("../../../public/assets/guquan_2.png"),
    gooods: [
      {
        goods_id: 23,
        goods_name: "数字化报告解读",
      },
      {
        goods_id: 21,
        goods_name: "数字化报告-税务风险报告",
      },
    ],
    sons: [
      {
        id: 2,
        pid: 1,
        name: "获取报告",
        level: 2,
        image: "",
        gooods: [],
      },
      {
        id: 3,
        pid: 1,
        name: "解读报告",
        level: 2,
        image: "",
        gooods: [],
      },
    ],
  },
  {
    id: 15,
    pid: 0,
    name: "股权设计",
    level: 1,
    image: "",
    img: true,
    // img_1: require("../../../public/assets/nashui_1.png"),
    // img_2: require("../../../public/assets/nashui_2.png"),
    gooods: [
      {
        goods_id: 32,
        goods_name: "股权设计-股权激励",
      },
    ],
  },
  {
    id: 14,
    pid: 0,
    name: "企业助贷",
    level: 1,
    image: "",
    img: true,
    // img_1: require("../../../public/assets/qiye_1.png"),
    // img_2: require("../../../public/assets/qiye_2.png"),
    gooods: [
      {
        goods_id: 33,
        goods_name: "企业助贷",
      },
    ],
  },
  {
    id: 8,
    pid: 0,
    name: "纳税策划",
    level: 1,
    image: "",
    img: true,
    // img_1: require("../../../public/assets/wanshui_1.png"),
    // img_2: require("../../../public/assets/wanshui_2.png"),
    gooods: [
      {
        goods_id: 25,
        goods_name: "纳税策划",
      },
    ],
  },
  {
    id: 4,
    pid: 0,
    name: "咨询业务",
    level: 1,
    image: "",
    img: true,
    // img_1: require("../../../public/assets/wanshui_1.png"),
    // img_2: require("../../../public/assets/wanshui_2.png"),
    gooods: [
      {
        goods_id: null,
        goods_name: "",
      },
      {
        goods_id: 34,
        goods_name: "",
      },
      {
        goods_id: 29,
        goods_name: "",
      },
      {
        goods_id: 35,
        goods_name: "",
      },
    ],
  },
];
