<template>
  <div style="background-color: #f6f6f5">
    <div class="jz ddisp" style="width: 1200px">
      <div class="ba_white xyy boxs fz_14 topdq" style="width: 200px">
        <div v-for="(item, index) in user" :key="index">
          <div class="pad_10_0 mag_t-10">
            <span class="mag_l-20 em fz_18">{{ item.name }}</span>
          </div>
          <div
            class="wz_jz xsgb pad_5_0 fz_14"
            v-for="(items, indexs) in item.children"
            :key="indexs"
          >
            <div
              class="pad_10_0"
              v-if="isTabs(items)"
              @click="userClick(items)"
              :class="$store.state.leftMenuId == items.id ? 'wz_yell' : ''"
            >
              {{ items.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="boxs" style="padding: 20px; width: calc(100% - 200px)">
        <transition name="scale" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/api/api";
export default {
  name: "user",
  data() {
    return {
      transitionName: "transitionLeft",
      indexid: localStorage.getItem("titleId"),
      title: "用户中心",
      isChannel: false,
      user: [
        {
          id: 1,
          name: "用户中心",
          path: "userCenter",
          children: [
            {
              id: 2,
              name: "我的首页",
              path: "myuserIndex",
            },
            {
              id: 3,
              name: "编辑个人资料",
              path: "updaUser",
            },
            {
              id: 4,
              name: "修改密码",
              path: "updaPassword",
            },
            {
              id: 5,
              name: "注销登录",
              path: "",
            },
          ],
        },
        {
          id: 6,
          name: "订单中心",
          path: "orderCenter",
          children: [
            {
              id: 7,
              name: "业务订单",
              path: "orderYewu",
            },
            {
              id: 8,
              name: "渠道订单",
              path: "orderQudao",
            },
            // {
            //   id: 9,
            //   name: "完结订单",
            //   path: "orderEnd",
            // },
            {
              id: 10,
              name: "待支付",
              path: "orderAwait",
            },
          ],
        },
      ],
    };
  },
  methods: {
    isTabs(item) {
      let { id, name, path } = item;
      if (name == "渠道订单") {
        return this.isChannel;
      }
      return true;
    },
    isShowChannel() {
      getUser().then((res) => {
        let { labels } = res.data.data;
        console.log("用户信息", labels);
        if (labels.indexOf("渠道") !== -1) {
          this.isChannel = true;
        }
      });
    },
    userClick(e) {
      if (e.path != "") {
        this.$router.push(e.path);
        this.$store.state.leftMenuId = e.id;
        this.title = e.name;
      } else {
        this.$confirm("确认注销登录吗?", "注销提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$store.commit("userInfo", {});
            window.localStorage.removeItem("tokenAnShui");
            window.localStorage.removeItem("userInfoAnShui");
            this.$router.push("login");
            this.$message({
              type: "success",
              message: "注销登录成功!",
            });
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.isShowChannel();
  },
};
</script>

<style scoped></style>
