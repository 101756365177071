<template>
  <div class="ba_white mag_b_20">
    <div class="pad_20 wh_f bor_b_xu">
      <div style="width: 120px">
        <span><img class="centerdq" src="../assets/shibai.png" width="26px" alt=""></span>
        <span class="centerdq mag_l-10 fz_20 em" style="color: #d9001b">支付失败</span>
      </div>
    </div>
    <div class="bor_b_xu">
      <div class="wh_f fz_14 pad_20_0" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">订单编号:</div>
        <div class=""><span class="centerdq mag_l-20">dawdwa</span></div>
      </div>
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">订单价格:</div>
        <div class=""><span class="centerdq mag_l-20" style="color: #d9001b">￥dawdwa</span></div>
      </div>
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">下单人:</div>
        <div class=""><span class="centerdq mag_l-20">dawdwa</span></div>
      </div>
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">业务名称:</div>
        <div class=""><span class="centerdq mag_l-20">dawdwa</span></div>
      </div>
    </div>
    <div class="pad_20 ddisp">
      <el-button>重新支付</el-button>
      <el-button @click="dialogVisible = true">取消订单</el-button>
    </div>
    <el-dialog
        title="请确认"
        :visible.sync="dialogVisible"
        width="30%">
      <div class="wz_black">
        <div class="pad_10_0 fz_14 xt">
          确定要取消订单吗?
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      <div class="wz_jz">
        <el-button class="jz" @click="dialogVisible = false">关闭</el-button>
        <el-button class="jz" style="margin-left: 50px" type="primary" @click="dialogVisible = false">确定</el-button>
      </div>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "payOk",
  data(){
    return{
      dialogVisible:false
    }
  }
}
</script>

<style scoped>

</style>
