<template>
  <div class="jz">
    <div class="cads bor_1_hui pad_10 boxs ba_white mag_t-20">
      <div
        class="fz_16 em pad_10 boxs"
        style="border-bottom: #969896 1px dashed"
      >
        订单信息
      </div>
      <div class="jz boxs" style="padding: 0 20px">
        <el-descriptions class="ddxx" title="" style="padding: 20px 0">
          <el-descriptions-item label="订单编号"
            ><span>{{ item.sn }}</span></el-descriptions-item
          >
          <!--                <el-descriptions-item label="订单状态"><span>{{ item.order_status_desc }}</span></el-descriptions-item>-->
          <el-descriptions-item label="订单金额"
            ><span>{{ item.order_amount }}</span></el-descriptions-item
          >
          <el-descriptions-item label="支付状态">
            <span>
              {{ pay_status[item.pay_status] }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="支付方式">
            <span v-if="item.pay_status != 0">
              {{ pay[item.pay_way - 1] }}</span
            >
            <span v-if="item.pay_status == 0">未支付</span>
            <!--          <span>{{pay[item.pay_way] }}</span>-->
          </el-descriptions-item>
          <el-descriptions-item label="下单时间"
            ><span>{{ item.create_time }}</span></el-descriptions-item
          >
          <el-descriptions-item label="到期日期" v-if="item.service_end_time"
            ><span>{{
              timestampToTime(item.service_end_time)
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="订单类型"
            ><span>{{ item.order_type_desc }}</span></el-descriptions-item
          >
          <el-descriptions-item label="买家留言"
            ><span>{{ item.baining_remark }}</span></el-descriptions-item
          >
          <el-descriptions-item label="订单来源"
            ><span>{{
              terminal[item.order_terminal - 1]
            }}</span></el-descriptions-item
          >
        </el-descriptions>
      </div>
    </div>

    <div class="cads bor_1_hui pad_10 mag_t-20 boxs ba_white">
      <div
        class="fz_16 em pad_10 boxs"
        style="border-bottom: #969896 1px dashed"
      >
        产品信息
      </div>
      <div class="jz boxs">
        <div>
          <el-table
            class="mag_t-20"
            :data="item.order_goods"
            :header-cell-style="{ background: '#f5f8ff', color: '#333333' }"
            style="width: 100%"
          >
            <el-table-column prop="order_id" label="产品编号">
            </el-table-column>
            <el-table-column label="产品信息">
              <template slot-scope="scope">
                <div class="wh_f">
                  <div class="czjz_flex" style="width: 60px">
                    <img
                      style="width: 60px"
                      class="centerdq"
                      :src="item.order_goods[0].goods_image"
                      alt=""
                    />
                  </div>
                  <div class="flex_1 hh pad_l_5">
                    {{ item.order_goods[0].goods_name }}
                    <span class="topdq ba_gr"></span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_price"
              v-if="item.is_set_price == 0"
              label="产品价格(元)"
            >
              <template slot-scope="scope">
                <div>
                  <span>待报价</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="item.is_set_price == 1"
              prop="goods_price"
              label="产品价格(元)"
            >
            </el-table-column>
            <el-table-column
              v-if="item.pay_status == 0"
              label="产品实付金额(元)"
            >
              <template slot-scope="scope">
                <div>
                  <span>未支付</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="item.pay_status != 0"
              prop="order_amount"
              label="产品实付金额(元)"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getKBDetail } from "../api/api";
export default {
  name: "order_Top",
  data() {
    return {
      item: {},
      sn: "",
      pay: ["余额支付", "微信支付", "支付宝支付"],
      terminal: [
        "微信小程序",
        "微信公众号",
        "手机H5",
        "Pc",
        "苹果app",
        "安卓app",
      ],
      pay_status: ["未支付", "已支付"],
    };
  },
  mounted() {
    this.item = this.$store.state.userOrderDetaild.item;
    this.sn = this.item.sn;
    this.getDetail();
  },
  methods: {
    getDetail() {
      getKBDetail({ sn: this.sn }).then((res) => {
        let data = res.data.data;
        console.log("详情", data);
        this.item = { ...this.item, ...data };
      });
    },
    // 时间搓(秒) 转换 时间
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
  },
};
</script>

<style scoped></style>
