<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18 em">
        填写代开票要求
      </div>
      <TopActive></TopActive>
      <div>
        <div class="ba_white mag_t-30">
          <div class="pad_20">
            <el-form ref="form" :model="form" size="small" label-width="170px" :rules="rules" >
              <div class="em" style="padding: 10px 0 10px 20px">自然人信息</div>
              <div class="ddisp bor_b_xu split_2">
                <el-form-item label="姓名" prop="user_name">
                  <el-input v-model="form.user_name" placeholder="请输入姓名" style="width: 300px"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="user_tel">
                  <el-input v-model="form.user_tel" placeholder="请输入手机号" style="width: 300px"></el-input>
                </el-form-item>
              </div>
<!--              代开票资料-->
              <div class="em" style="padding: 10px 0 10px 20px">代开票资料</div>
              <div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="发票类目" prop="invoice_category">
                      <el-input v-model="form.invoice_category" placeholder="请输入发票类目" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="商品编号" prop="item_name">
                      <el-input v-model="form.item_name" placeholder="请输入商品编号" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="企业名称" prop="enterprise_name">
                      <el-input v-model="form.enterprise_name" placeholder="请输入企业名称" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="企业税号" prop="enterprise_tax">
                      <el-input v-model="form.enterprise_tax" placeholder="请输入企业税号" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="企业地址">
                      <el-input v-model="form.enterprise_addr" placeholder="请输入企业地址" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="企业电话">
                      <el-input type="number"
                                oninput="if(value.length > 11) value=value.slice(0, 11)"
                                v-model="form.enterprise_tel" placeholder="请输入企业电话" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="企业开户行">
                      <el-input v-model="form.enterprise_bank" placeholder="请输入企业开户行" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="企业开户账号">
                      <el-input v-model="form.enterprise_bank_card" placeholder="请输入企业开户账号" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="开票总额" prop="invoice_max">
                      <el-input type="number" v-model="form.invoice_max" placeholder="请输入开票总额" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="单价">
                      <el-input type="number"
                                oninput="if(value.length > 8) value=value.slice(0, 8)"
                                @input="inputUpda" v-model="form.unit_price"
                                placeholder="请输入开票金额" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="数量">
                      <el-input v-model="form.invoice_count" placeholder="请输入数量" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="单位">
                      <el-input v-model="form.unit_name"  placeholder="请输入单位" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="规格">
                      <el-input v-model="form.specification" placeholder="请输入规格" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="身份证号" prop="identification">
                      <el-input v-model="form.identification" placeholder="请输入身份证号"  style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="自然人银行卡号">
                      <el-input v-model="form.person_bank_card" placeholder="请输入自然人银行卡号" style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="备注">
                      <el-input v-model="form.remark" placeholder="请输入备注"  style="width: 300px"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
                <!--              上传资料文件-->
              <div class="em" style="padding: 10px 0 10px 20px">上传资料文件</div>
                <div>
                  <el-form-item label="上传身份证扫描件" prop="demo">
<!--                    <ImgUpload @Value="Touxiang"></ImgUpload>-->
                    <img v-if="form.identification_file" class="topdq disp" :src="$store.state.httpsDown+form.identification_file" width="148" height="148" alt="" style="z-index: 10">
                    <div class="disp">
                      <inputUpload class="mag_l-20 disb" @upload="Touxiang"></inputUpload>
                      <el-button class="disb" @click="addZL" type="primary"
                                 style="background-color:#02bd6e;
                               margin:10px 0 0 20px">从资料库中选择</el-button>
                    </div>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="上传合同" prop="demo">
                    <inputUpload class="mag_l-20" @upload="hetong"></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="上传银行流水" prop="demo">
                    <inputUpload class="mag_l-20" @upload="yinhangliushui"></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="上传成果文件" prop="demo">
                    <inputUpload class="mag_l-20" @upload="chengguowenjian"></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="上传业务相关证据材料" prop="demo">
                    <inputUpload class="mag_l-20" @upload="yewuxiangguanzhengjucailiao"></inputUpload>
                  </el-form-item>
                </div>
              <div class="wz_rigdq pad_t_20 bor_t_hui">
                <el-button @click="add" type="primary" style="width: 120px">提交</el-button>
              </div>
              </div>
            </el-form>
          </div>
          <el-dialog
              :visible.sync="ZLShow"
              title="在资料库中选择"
              width="50%">
            <div>
              <hr>
              <el-table
                  :data="ZLList"
                  style="width: 100%">
                <el-table-column
                    width="40">
                  <template slot-scope="scope">
                    <el-radio class="radio" :label="scope.row" v-model="radio" @change.native="getCurrentRow(scope.row)" >&nbsp;</el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="用户头像">
                  <template slot-scope="scope">
                    <div class="flex">
                      <el-image :src="$store.state.httpsDown+scope.row.info_path" style="width: 34px; height: 34px"></el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="info_title"
                    label="资料名称"
                    width="180">
                </el-table-column>
              </el-table>
            </div>
            <div>
              <el-pagination
                  class="wz_jz"
                  @size-change="SizeChange"
                  @current-change="CurrentChange"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="100"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="count"
                  style="margin: 20px 0">
              </el-pagination>
            </div>
            <div class="wz_rigdq" style="margin-top: 10px">
              <el-button size="small" @click="ZLShow = false">关 闭</el-button>
              <el-button size="small" type="primary" @click="addZl">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getjZrrkp_Ziliao, getorderdetail, getZLList} from "../../api/api";

export default {
  user_name: "kpzl_err",
  data(){
    return{
      ZLList:{},
      radio: '',
      ZLShow:false,
      ZL:{},
      rules: {
        user_name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        user_tel: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { pattern: /^\d{11}$/, message: '电话号码格式不正确', trigger: 'blur' }
        ],
        invoice_category: [{required: true, message: '请输入发票类目', trigger: 'blur'}],
        item_name: [{required: true, message: '请输入商品编号', trigger: 'blur'}],
        enterprise_name: [{required: true, message: '请输入企业名称', trigger: 'blur'}],
        enterprise_tax: [{required: true, message: '请输入企业税号', trigger: 'blur'}],
        invoice_max: [{required: true, message: '请输入开票总额', trigger: 'blur'}],
        identification: [{required: true, message: '请输入身份证号', trigger: 'blur'}],
        demo: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      form:{
        user_name:this.$store.state.userOrderDetaild.user_name,
        user_tel:this.$store.state.userOrderDetaild.user_tel
      },
      uploadedFile: null,
    }
  },
  methods:{
    addZl(){
      this.form.identification_file = this.ZL.info_path
      this.ZLShow = false
    },
    SizeChange(newSize){
      this.pageSize = newSize
      this.addZl(this.pageIndex,this.pageSize)
    },
    // 分页钩子函数
    CurrentChange(newPage){
      this.pageIndex=newPage
      this.addZl(this.pageIndex,this.pageSize)
    },
    // 单选框选中数据
    getCurrentRow(val) {
      this.ZL = val;
    },
    inputUpda(){
      this.form.unit_price = this.form.unit_price.replace(/[^\u0030-\u0039\u002E]/g, '');
      // 限制小数点后最多只能输入两位数
      const decimalIndex = this.form.unit_price.indexOf('.');
      if (decimalIndex !== -1) {
        const decimalPart = this.form.unit_price.substring(decimalIndex + 1);
        if (decimalPart.length > 2) {
          this.form.unit_price = this.form.unit_price.substring(0, decimalIndex + 3);
        }
      }
    },
    hetong(file) {
      this.form.contract_file = file.response.data.url;
      console.log(file.response.data)
    },
    yinhangliushui(file) {
      this.form.bank_statement_file = file.response.data.url;
      console.log(file.response.data)
    },
    chengguowenjian(file) {
      this.form.outcome_file = file.response.data.url;
      console.log(file.response.data)
    },
    yewuxiangguanzhengjucailiao(file) {
      this.form.evidence_file = file.response.data.url;
      console.log(file.response.data)
    },

    addZL(){
      getZLList({
        user_id:this.$store.state.userInfo.id
      }).then(res=>{
        this.count = res.data.data.count
        this.ZLList = res.data.data.lists
        this.ZLShow = true
      })
    },
    Touxiang(e){
      this.form.identification_file=e.response.data.url
      // this.form.identification_file=e
    },
    add(){
      this.form.sn = this.$store.state.userOrderDetaild.sn
      this.form.order_id = this.$store.state.userOrderDetaild.id
      getjZrrkp_Ziliao(this.form).then(res=>{
        if (res.data.code==1){
          this.$router.push('yeWuIndex')
        }
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>