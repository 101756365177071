<template>
<div>
  <div class="ba_white pad_50_0">
    <el-steps :active="$store.state.userOrderDetaild.step_value+1"  align-center>
      <el-step v-for="(item,index) in $store.state.userOrderDetaild.step_list"
               :key="index"
               :title="item.label"></el-step>
    </el-steps>
  </div>
</div>
</template>

<script>
export default {
  name: "TopActive",
  data(){
    return{
      active:1,
      order:[]
    }
  }
}
</script>

<style scoped>

</style>