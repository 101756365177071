import Vue from "vue";
import VueRouter from "vue-router";
import home from "../view/home/home";
import Index from "../view/Index/Index";
import user from "../view/user/user";
import login from "../view/login/login";
import userInfo from "../view/login/userInfo";
import forgetPassword from "../view/login/forgetPassword";
import agreement from "../view/login/agreement";
import err from "../view/err/err";
import userCenter from "../view/user/userCenter";
import myuserIndex from "../view/user/myuserIndex";
import updaUser from "../view/user/updaUser";
import updaPassword from "../view/user/updaPassword";
import gth_detail from "../view/user/gth_detail";
import delLogin from "../view/user/delLogin";
import orderCenter from "../view/user/orderCenter";
import orderYewu from "../view/user/orderYewu";
import orderQudao from "../view/user/orderQudao";
import orderEnd from "../view/user/orderEnd";
import orderAwait from "../view/user/orderAwait";
import yeWuIndex from "../view/yeWu/yeWuIndex";
import detail from "../view/yeWu/detail";
import order from "../view/order/order";
import policy from "../view/policy/policy";
import CaseData from "../view/policy/CaseData";
import Case from "../view/policy/Case";
import kb from "../view/err/kb";
import srbsxx from "../view/payOrder/srbsxx";
import kpzl_err from "../view/payOrder/kpzl_err";
import txbd from "../view/payOrder/txbd";
import txkpzl from "../view/payOrder/txkpzl";
import fxjd from "../view/payOrder/fxjd";
import scjdbg from "../view/payOrder/scjdbg";
import sckbzl from "../view/payOrder/sckbzl";
import gth_qhsq from "../view/user/gth_qhsq";
import gth_zxsq from "../view/user/gth_zxsq";
import gth_dzbg from "../view/user/gth_dzbg";
import gthkpDetail from "../view/Detail/gthkpDetail";
import zrrdkpDetail from "../view/Detail/zrrdkpDetail";
import fsDetail from "../view/Detail/fsDetail";
import nschDetail from "../view/Detail/nschDetail";
import gqsjDetail from "../view/Detail/gqsjDetail";
import szhbgDetail from "../view/Detail/szhbgDetail";
import jdbgDetail from "../view/Detail/jdbgDetail";
import hqbgDetail from "../view/Detail/hqbgDetail";
import gthRenew from "../view/user/gth_renew.vue";
import batchOrder from "../view/order/batchOrder";
import bantchPay from "../view/order/bantchPay";
import paySuccess from "../view/order/paySuccess";

Vue.use(VueRouter);
export default new VueRouter({
  mode: "history",
  // mode: "hash",
  routes: [
    {
      path: "/",
      component: home,
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: home,
      meta: {}, // 添加该字段，表示进入这个路由是需要登录的 ,
      children: [
        {
          path: "/Index",
          name: "Index",
          component: Index,
          meta: { requireAuth: false }, // 首页,
        },
        {
          path: "/srbsxx",
          name: "srbsxx",
          component: srbsxx,
          meta: { requireAuth: false }, // 输入标识信息,
        },

        {
          path: "/gthkpDetail",
          name: "gthkpDetail",
          component: gthkpDetail,
          meta: { requireAuth: false }, // 个体户开票,
        },
        {
          path: "/zrrdkpDetail",
          name: "zrrdkpDetail",
          component: zrrdkpDetail,
          meta: { requireAuth: false }, // 输入标识信息,
        },
        {
          path: "/fsDetail",
          name: "fsDetail",
          component: fsDetail,
          meta: { requireAuth: false }, // 输入标识信息,
        },
        {
          path: "/nschDetail",
          name: "nschDetail",
          component: nschDetail,
          meta: { requireAuth: false }, // 输入标识信息,
        },
        {
          path: "/gqsjDetail",
          name: "gqsjDetail",
          component: gqsjDetail,
          meta: { requireAuth: false }, // 输入标识信息,
        },
        {
          path: "/szhbgDetail",
          name: "szhbgDetail",
          component: szhbgDetail,
          meta: { requireAuth: false }, // 输入标识信息,
        },
        {
          path: "/jdbgDetail",
          name: "jdbgDetail",
          component: jdbgDetail,
          meta: { requireAuth: false }, // 解读报告详情,
        },
        {
          path: "/hqbgDetail",
          name: "hqbgDetail",
          component: hqbgDetail,
          meta: { requireAuth: false }, // 解读报告详情,
        },
        // {
        //     path: '/unpaid',
        //     name: 'unpaid',
        //     component: unpaid,
        //     meta: { requireAuth: false },// 待支付,
        // },
        {
          path: "/txkpzl",
          name: "txkpzl",
          component: txkpzl,
          meta: { requireAuth: false }, // 上传代开票资料,
        },
        {
          path: "/kpzl_err",
          name: "kpzl_err",
          component: kpzl_err,
          meta: { requireAuth: false }, // 待支付的上传待开票资料,
        },
        {
          path: "/txbd",
          name: "txbd",
          component: txbd,
          meta: { requireAuth: false }, // 待支付的填写表单,
        },
        {
          path: "/fxjd",
          name: "fxjd",
          component: fxjd,
          meta: { requireAuth: false }, // 分析解读,
        },
        {
          path: "/scjdbg",
          name: "/scjdbg",
          component: scjdbg,
          meta: { requireAuth: false }, // 上传解读报告,
        },
        {
          path: "/sckbzl",
          name: "/sckbzl",
          component: sckbzl,
          meta: { requireAuth: false }, // 上传开办资料,
        },
        {
          path: "/user",
          name: "user",
          component: user,
          meta: { requireAuth: true }, // 用户中心 ,
          children: [
            {
              path: "/",
              component: myuserIndex,
            },
            {
              path: "/userCenter",
              name: "userCenter",
              component: userCenter,
              meta: { requireAuth: true }, // 用户中心 ,
            },
            {
              path: "/gth_qhsq",
              name: "gth_qhsq",
              component: gth_qhsq,
              meta: { requireAuth: true }, // 个体户代开办 迁回申请 ,
            },
            {
              path: "/gth_dzbg",
              name: "gth_dzbg",
              component: gth_dzbg,
              meta: { requireAuth: true }, // 个体户代开办 地址变更 ,
            },
            {
              path: "/gth_zxsq",
              name: "gth_zxsq",
              component: gth_zxsq,
              meta: { requireAuth: true }, // 个体户代开办 注销申请 ,
            },
            {
              path: "/gth_renew/:id",
              name: "gth_renew",
              component: gthRenew,
              meta: { requireAuth: true }, // 个体户代开办 续费 ,
            },
            {
              path: "/myuserIndex",
              name: "myuserIndex",
              component: myuserIndex,
              meta: { requireAuth: true }, // 我的首页 ,
            },
            {
              path: "/updaUser",
              name: "updaUser",
              component: updaUser,
              meta: { requireAuth: true }, // 编辑个人资料 ,
            },
            {
              path: "/updaPassword",
              name: "updaPassword",
              component: updaPassword,
              meta: { requireAuth: true }, // 修改密码 ,
            },
            {
              path: "/gth_detail",
              name: "gth_detail",
              component: gth_detail,
              meta: { requireAuth: true }, // 个体户订单详情 ,
            },
            {
              path: "/delLogin",
              name: "delLogin",
              component: delLogin,
              meta: { requireAuth: true }, // 注销登录 ,
            },
            {
              path: "/orderCenter",
              name: "orderCenter",
              component: orderCenter,
              meta: { requireAuth: true }, // 订单中心 ,
            },
            {
              path: "/orderYewu",
              name: "orderYewu",
              component: orderYewu,
              meta: { requireAuth: true }, // 业务订单 ,
            },
            {
              path: "/orderQudao",
              name: "orderQudao",
              component: orderQudao,
              meta: { requireAuth: true }, // 渠道订单 ,
            },
            {
              path: "/orderEnd",
              name: "orderEnd",
              component: orderEnd,
              meta: { requireAuth: true }, // 完结订单 ,
            },
            {
              path: "/orderAwait",
              name: "orderAwait",
              component: orderAwait,
              meta: { requireAuth: true }, // 待支付 ,
            },
          ],
        },
        {
          path: "/login",
          name: "login",
          component: login,
          meta: {}, // 登录 ,
        },
        {
          path: "/kb",
          name: "kb",
          component: kb,
          meta: {}, // 空白页 ,
        },
        {
          path: "/policy",
          name: "policy",
          component: policy,
          meta: {}, // 政策法规 ,
        },
        {
          path: "/Case",
          name: "Case",
          component: Case,
          meta: {}, // 行业案例 ,
        },
        {
          path: "/CaseData",
          name: "CaseData",
          component: CaseData,
          meta: {}, // 行业案例 , 政策法规 , 详情 ,
        },
        {
          path: "/order",
          name: "order",
          component: order,
          meta: {}, // S2普通下单
        },
        {
          path: "/yeWuIndex",
          name: "yeWuIndex",
          component: yeWuIndex,
          meta: {}, // 业务中心 ,
        },
        {
          path: "/batchOrder/:id",
          name: "batchOrder",
          component: batchOrder,
          meta: {}, // 批量下单 ,
        },
        {
          path: "/bantchPay/:id",
          name: "bantchPay",
          component: bantchPay,
          meta: {}, // 批量订单支付 ,
        },
        {
          path: "/paySuccess",
          name: "paySuccess",
          component: paySuccess,
          meta: {}, // 批量订单支付 ,
        },
        {
          path: "/userInfo",
          name: "userInfo",
          component: userInfo,
          meta: {}, // 注册用户 ,
        },
        {
          path: "/detail",
          name: "detail",
          component: detail,
          meta: {}, // 业务详情 ,
        },
        {
          path: "/forgetPassword",
          name: "forgetPassword",
          component: forgetPassword,
          meta: {}, // 忘记密码
        },
        {
          path: "/agreement",
          name: "agreement",
          component: agreement,
          meta: {}, // 协议
        },
      ],
    },
    {
      path: "/*",
      name: "err",
      component: err,
      meta: { requireAuth: true },
    },
  ],
});

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
