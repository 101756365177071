<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="wh_f czjz_flex" style="padding: 0px 0">
        <span class="centerdq flex_1 fz_20">我的订单</span>
        <!-- <div
          class="wh_f centerdq"
          v-if="false"
          style="float: right; width: 600px"
        >
          <div class="flex_1">
            <el-input
              placeholder="请输入订单编号"
              v-model="SelectInput"
              style="border: 1px solid #dd2d22; border-radius: 5px"
            >
              <template slot="prepend"><i class="el-icon-search"></i></template>
            </el-input>
          </div>
          <div class="pad_l_20">
            <el-button
              type="danger"
              @click="selecOk"
              style="background-color: #df2d22"
              >搜索</el-button
            >
          </div>
        </div> -->
      </div>
      <div>
        <div style="padding: 10px 0 20px">
          <div class="xd">
            <span
              @click="bddSpan"
              :class="indexSpan == 'all' ? 'titSpan_xz' : ''"
              class="titSpan xsgb wz_black"
            >
              全部
            </span>
            <span
              @mousemove="item.isTrue = true"
              @mouseleave="item.isTrue = false"
              v-for="(item, index) in titleList"
              :key="index"
            >
              <span
                class="titSpan xsgb wz_black"
                :class="indexSpan == index ? 'titSpan_xz' : ''"
                @click="addSpan(item, index)"
                >{{ item.name }}</span
              >
              <div
                v-if="item.isTrue"
                class="jd pad_t_10 wh_max boxs"
                style="bottom: -34px; height: 30px"
              >
                <span
                  @click="cddSpan(items, index)"
                  class="xsgb hover_yellow"
                  style="margin: 10px; font-size: 12px"
                  v-for="(items, indexs) in item.sons"
                  :key="indexs"
                >
                  {{ items.name }}
                </span>
              </div>
            </span>
          </div>
        </div>

        <div
          class="bor_1_hui bor_5 mag_t-30"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="wh_f fz_14 pad_16_24">
            <div class="flex_1">
              订单编号:<span class="mag_l-10">{{ item.sn }}</span>
            </div>
            <div class="flex_1">
              下单时间:<span class="mag_l-10">{{ item.create_time }}</span>
            </div>
            <div class="flex_1" v-if="item.order_status_desc != '待发货'">
              订单状态:<span class="mag_l-10">{{
                item.order_status_desc
              }}</span>
            </div>
            {{ item.order_status_desc }}
            <div class="flex_1" v-if="item.order_status_desc == '待发货'">
              订单状态:
              <!-- <span class="mag_l-10">待审核</span> -->
              <span class="mag_l-10"> {{ getStatus(item) }}</span>
            </div>
          </div>
          <div
            class="wh_f"
            style="
              border-top: 1px solid #ebebeb;
              border-bottom: 1px solid #ebebeb;
              padding: 15px 23px;
            "
          >
            <div>
              <img
                :src="item.order_goods[0].goods_image"
                class="bor_5"
                width="138"
                height="138"
              />
            </div>
            <div class="flex_1 boxs pad_l_20">
              <div class="em fz_16">{{ item.order_goods[0].goods_name }}</div>
              <div class="fz_13 wz_hui">
                <div class="pad_15_0">
                  支付金额 :
                  <span
                    v-if="item.is_set_price == 1"
                    class="fz_18 mag_l-10 centerdq"
                    style="color: #dc2c21"
                    >¥<span class="fz_26 ce mag_l-5">{{
                      item.order_amount
                    }}</span></span
                  >
                  <span
                    v-if="item.is_set_price == 0"
                    class="fz_18 mag_l-10 centerdq wz_red"
                    >待报价</span
                  >
                </div>
              </div>
              <div class="xt fz_13 wz_hui">
                <div class="pad_b_10">
                  姓名: <span class="wz_black">{{ item.user_name }}</span>
                </div>
                <div class="pad_t_10">
                  手机号: <span class="wz_black">{{ item.user_tel }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="wh_f" style="padding: 15px 20px">
            <div class="flex_1"></div>
            <div>
              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 7 &&
                  item.step_value == 0
                "
                @click="txbd(item)"
                >填写表单</el-button
              >
              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 6 &&
                  item.step_value == 0
                "
                @click="kpzl_err(item)"
                >填写开票资料</el-button
              >
              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 4 &&
                  item.pay_status == 1
                "
                @click="sckbzl(item)"
                >上传开办资料</el-button
              >
              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 2 &&
                  item.pay_status == 1
                "
                @click="scbgwj(item)"
                >上传报告文件</el-button
              >

              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 2 &&
                  item.pay_status == 1
                "
                @click="renew(item)"
                >续费</el-button
              >

              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 5 &&
                  item.pay_status == 1
                "
                @click="txkpzl(item)"
                >填写开票资料</el-button
              >
              <el-button
                v-if="
                  !item.submit &&
                  item.baining_step_type == 1 &&
                  item.pay_status == 1
                "
                type="primary"
                @click="srbsxx(item)"
                >输入标识信息</el-button
              >
              <el-button
                v-if="
                  item.baining_step_type == 1 ||
                  item.baining_step_type == 2 ||
                  item.baining_step_type == 3
                "
                @click="lxkf"
                type="primary"
                >在线咨询</el-button
              >

              <el-button
                v-if="
                  item.step_list.length == item.step_value + 1 &&
                  item.service_end == 0
                "
                type="danger"
                @click="renew(item)"
                style="background-color: #dd2d22; margin-left: 16px"
                >续费</el-button
              >

              <el-button
                @click="payPay(item)"
                v-if="
                  item.baining_step_type != '7' &&
                  item.pay_status == 0 &&
                  item.is_set_price == 1
                "
                type="danger"
                style="background-color: #dd2d22; margin-left: 16px"
                >去支付</el-button
              >

              <!--              纳税策划的支付按钮 报价完再显示-->
              <el-button
                v-if="
                  item.baining_step_type == 7 &&
                  item.pay_status == 0 &&
                  parseFloat(item.order_goods[0].original_price).toFixed(2) !=
                    parseFloat(item.order_amount).toFixed(2)
                "
                @click="payPay(item)"
                type="danger"
                style="background-color: #dd2d22; margin-left: 16px"
                >去支付</el-button
              >

              <el-dropdown
                v-if="item.baining_step_type == '4'"
                @command="hand($event, item)"
              >
                <!-- {{ item }} -->
                <span class="el-dropdown-link xsgb mag_l-15"> 更多 </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="
                      item.step_list.length == item.step_value + 1 &&
                      Math.floor(new Date(item.create_time).getTime() / 1000) <
                        item.service_end_time &&
                      item.service_end == 0
                    "
                    command="option1"
                    divided
                    >变更地址</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      item.step_list.length == item.step_value + 1 &&
                      item.backList.length < 1 &&
                      Math.floor(new Date(item.create_time).getTime() / 1000) <
                        item.service_end_time &&
                      item.service_end == 0
                    "
                    command="option2"
                    divided
                    >迁回申请</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      item.step_list.length == item.step_value + 1 &&
                      item.cancelList.length < 1 &&
                      Math.floor(new Date(item.create_time).getTime() / 1000) <
                        item.service_end_time &&
                      item.service_end == 0
                    "
                    command="option3"
                    divided
                    >注销申请</el-dropdown-item
                  >

                  <el-dropdown-item command="option4" divided
                    >订单详情</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                v-if="item.baining_step_type != '4'"
                @command="hands($event, item)"
              >
                <span class="el-dropdown-link xsgb mag_l-15"> 更多 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="option4" divided
                    >订单详情</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>

        <div v-if="list == ''" class="wz_jz">
          <el-empty description="没有数据哦"></el-empty>
        </div>

        <div class="pad_t_10">
          <!--              :current-page="currentPage4"-->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[20, 50, 100]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <xF v-if="xfadd" @child="xfEx" :order_item="order_item"></xF>
    <lookBjcl v-if="lookBjcladd" @child="lookBjcl"></lookBjcl>
  </div>
</template>

<script>
import {
  getfsDetail,
  getgthkpxxDetail,
  gethqbgDetail,
  getjdbgDetail,
  getKBDetail,
  getLookShop,
  getnschDetail,
  getorderList,
  getOrderXF,
  getOrderYewu,
  getTopList,
  getYewuIndex,
  getzrrkpDetail,
  getzxDetail,
} from "../../api/api";
import { getshopDetail, getYewuOrderList } from "../../api/test";
export default {
  name: "orderYewu",
  data() {
    return {
      SelectInput: "",
      titleList: [],
      indexSpan: "all",
      xfadd: false,
      lookBjcladd: false,
      list: [],
      pageIndex: 1,
      pageSize: 20,
      count: 0,
      sele: "all",
      order_item: {},
    };
  },
  methods: {
    getStatus(item) {
      let { step_list, step_value } = item;

      if (step_value + 1 >= step_list.length) {
        return "已完结";
      }

      let value = step_value + 1;
      let step = step_list.filter((item) => item.value == value)[0];

      return "待" + step.label;
    },
    payPay(e) {
      this.$store.commit("userOrderDetaild", e);
      this.order_item = e;
      this.xfadd = true;
    },
    renew(item) {
      console.log(item);
      this.$router.push("gth_renew/" + item.id);
    },
    lxkf() {
      window.open(
        "https://im.anshuiwang.com.cn/index/index/home?visiter_id=&visiter_name=东方神起&avatar=&business_id=1&groupid=1&special=1"
      );
    },
    // 上传开办资料
    sckbzl(e) {
      this.$store.commit("userOrderDetaild", e);
      this.$router.push("/sckbzl");
    },
    // 上传开办资料
    kpzl_err(e) {
      this.$store.commit("userOrderDetaild", e);
      this.$router.push("/kpzl_err");
    },
    // 上传开办资料
    txbd(e) {
      this.$store.commit("userOrderDetaild", e);
      this.$router.push("/txbd");
    },
    scbgwj(e) {
      this.$store.commit("userOrderDetaild", e);
      this.$router.push("/scjdbg");
    },
    txkpzl(e) {
      this.$store.commit("userOrderDetaild", e);
      this.$router.push("/txkpzl");
    },
    // 点击筛选全部
    bddSpan() {
      this.indexSpan = "all";
      this.getGoodsList("all");
    },
    addSpan(e, i) {
      this.indexSpan = i;
      this.sele = e.id;
      this.getGoodsList(this.sele);
    },
    cddSpan(e, i) {
      this.indexSpan = i;
      this.sele = e.id;
      this.getGoodsList(this.sele);
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(this.sele);
    },
    handleCurrentChange(newPage) {
      this.pageIndex = newPage;
      this.getList(this.sele);
    },
    // 点击业务分类筛选订单
    getGoodsList(e) {
      // getorderList({
      //   type: "all",
      //   category_id: e,
      //   page_no: this.pageIndex,
      //   page_size: this.pageSize,
      //   pay_status: "1",
      // }).then((res) => {
      //   this.list = res.data.data.lists;
      //   this.count = res.data.data.count;
      // });
      this.getList(e);
    },

    // 点击订单下拉的选择事件
    hand(command, e) {
      getKBDetail({
        sn: e.sn,
      }).then((res) => {
        let add = res.data.data;
        if (add) {
          add = {};
        }
        add.list = res.data.data;
        add.item = e;
        add.item.order_goods[0].order_amount = e.order_amount;
        this.$store.commit("userOrderDetaild", add);
        switch (command) {
          case "option1":
            this.$router.push("gth_dzbg");
            break;
          case "option2":
            this.$router.push("gth_qhsq");
            break;
          case "option3":
            this.$router.push("gth_zxsq");
            break;
          case "option4":
            this.$router.push("gth_detail");
            break;
          case "option5": {
            this.$router.push("gth_renew/" + e.id);
            break;
          }
        }
      });
    },
    // 点击订单下拉的选择事件
    hands(command, e) {
      let add = {};
      add.item = e;
      add.item.order_goods[0].order_amount = e.order_amount;
      switch (parseInt(e.baining_step_type)) {
        case 1:
          gethqbgDetail({
            sn: e.sn,
          }).then((res) => {
            add.list = res.data.data;
            add.list.user_name = e.user_name;
            add.list.user_tel = e.user_tel;
            this.$store.commit("userOrderDetaild", add);
            this.$router.push("hqbgDetail");
          });
          break;
        case 2:
          getjdbgDetail({
            sn: e.sn,
          }).then((res) => {
            add.list = res.data.data;
            add.list.user_name = e.user_name;
            add.list.user_tel = e.user_tel;
            this.$store.commit("userOrderDetaild", add);
            this.$router.push("jdbgDetail");
          });
          break;
        case 3:
          getzxDetail({
            sn: e.sn,
          }).then((res) => {
            bdd = res.data.data;
            add.list = res.data.data;
            this.$store.commit("userOrderDetaild", add);
            this.$router.push("gqsjDetail");
          });
          break;
        case 4:
          break;
        case 5:
          getgthkpxxDetail({
            sn: e.sn,
          }).then((res) => {
            if (res.data.data.length == 0) {
              add.list = {};
              add.list.user_name = e.user_name;
              add.list.user_tel = e.user_tel;
            } else {
              add.list = res.data.data;
            }
            this.$store.commit("userOrderDetaild", add);

            this.$router.push("gthkpDetail");
          });
          break;
        case 6:
          getzrrkpDetail({
            sn: e.sn,
          }).then((res) => {
            add.list = res.data.data;
            this.$store.commit("userOrderDetaild", add);
            this.$router.push("zrrdkpDetail");
          });
          break;
        case 7:
          getnschDetail({
            sn: e.sn,
          }).then((res) => {
            add.list = res.data.data;
            this.$store.commit("userOrderDetaild", add);
            this.$router.push("nschDetail");
          });
          break;
        case 8:
          var item = {
            item: add.item,
            list: "没有数据",
          };
          var bdd = [];
          getfsDetail({
            sn: e.sn,
          }).then((res) => {
            if (Array.isArray(res.data.data) && res.data.data.length === 0) {
              this.$store.commit("userOrderDetaild", item);
            } else {
              bdd = res.data.data;
              add.list = res.data.data;
              this.$store.commit("userOrderDetaild", add);
            }
          });
          setTimeout(() => {
            this.$router.push("fsDetail");
          }, 300);
          break;
        case 9:
          break;
      }
    },
    // 在页面中的某个方法中使用 testAxios 发送请求
    fetchData() {
      getLookShop().then((res) => {
        if (res.data.code == -1) {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 支付
    pay(e) {},
    xfEx() {
      this.xfadd = false;
      this.getList("all", 1, 10);
    },
    // 续费操作
    xf(e) {
      console.log("订单信息", e);
      this.$confirm("确定续费此订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getOrderXF({
            sn: e.sn,
            goods_id: e.order_goods[0].goods_id,
            order_id: e.id,
          }).then((res) => {
            console.log("续费结果", res);
            this.getList("all", 1, 10);
          });
        })
        .catch(() => {});
    },
    // 输入标识信息
    srbsxx(e) {
      // 在某个组件中的方法中提交 mutation
      this.$store.commit("userOrderDetaild", e);
      this.$router.push({
        path: "/srbsxx",
      });
    },
    // 续费操作
    lookBjcl(e) {
      switch (e) {
        // 打开续费框
        case 1:
          this.lookBjcladd = true;
          break;
        case 2:
          // 确定
          this.lookBjcladd = false;
          break;
        case 3:
          // 取消
          this.lookBjcladd = false;
          break;
      }
    },
    // 分析解读
    fxjd() {
      this.$router.push({
        name: "yeWuIndex",
      });
    },
    // 订单列表
    getList(e) {
      getorderList({
        type: "all",
        category_id: e,
        page_no: this.pageIndex,
        page_size: this.pageSize,
        pay_status: "1",
        order_type: "5", // 普通订单
      }).then((res) => {
        this.list = res.data.data.lists;
        this.count = res.data.data.count;
      });
    },
    selecOk() {
      getorderList({
        type: "all",
        good_title: this.SelectInput,
      }).then((res) => {
        this.list = res.data.data.lists;
        this.count = res.data.data.count;
      });
    },
  },
  created() {
    this.$store.state.leftMenuId = 8;
  },
  mounted() {
    this.fetchData();
    this.getList("all", this.pageIndex, this.pageSize);
    // 导航栏
    getYewuIndex().then((res) => {
      if (res.status == 200) {
        this.titleList = res.data.data.lists;
        this.titleList = res.data.data.lists.map((item) => {
          return { ...item, isTrue: false };
        });
      }
    });
  },
};
</script>

<style scoped></style>
