<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18 em">填写代开票要求</div>
      <TopActive :value="list"></TopActive>
      <div>
        <div class="ba_white mag_t-30">
          <div class="pad_20">
            <el-form
              ref="form"
              :model="form"
              size="small"
              label-width="170px"
              :rules="rules"
            >
              <div class="em" style="padding: 10px 0 10px 20px">个体户信息</div>
              <div class="ddisp bor_b_xu split_2">
                <el-form-item label="姓名" prop="user_name">
                  <el-input
                    v-model="form.user_name"
                    placeholder="请输入姓名"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="user_tel">
                  <el-input
                    v-model="form.user_tel"
                    placeholder="请输入手机号"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
              <!--              办理方式-->
              <div class="em" style="padding: 10px 0 10px 20px">
                选择办理方式
              </div>
              <div>
                <el-form-item label="办理方式" prop="handle_type">
                  <el-radio v-model="form.handle_type" :label="0"
                    >线上办理</el-radio
                  >
                  <el-radio v-model="form.handle_type" :label="1"
                    >线下办理</el-radio
                  >
                </el-form-item>
              </div>
              <!--              票面信息-->
              <div class="em" style="padding: 10px 0 10px 20px">票面信息</div>
              <div>
                <el-form-item label="开票类型">
                  <el-radio v-model="form.invoice_type" :label="0"
                    >普票</el-radio
                  >
                  <el-radio v-model="form.invoice_type" :label="1"
                    >专票</el-radio
                  >
                </el-form-item>
                <div class="ddisp">
                  <div>
                    <el-form-item label="发票内容" prop="invoice_content">
                      <el-input
                        v-model="form.invoice_content"
                        placeholder="请输入发票内容"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="规格型号" prop="specification">
                      <el-input
                        v-model="form.specification"
                        placeholder="请输入规格型号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="单位名称" prop="unit_name">
                      <el-input
                        v-model="form.unit_name"
                        placeholder="请输入单位"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="数量" prop="invoice_number">
                      <el-input
                        type="number"
                        oninput="if(value.length > 8) value=value.slice(0, 8)"
                        v-model="form.invoice_number"
                        placeholder="请输入数量"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="开票金额" prop="invoice_amount">
                      <el-input
                        type="number"
                        oninput="if(value.length > 8) value=value.slice(0, 8)"
                        @input="inputUpda"
                        v-model="form.invoice_amount"
                        placeholder="请输入开票金额"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="金额大写" prop="amount_word">
                      <el-input
                        disabled
                        v-model="form.amount_word"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="备注">
                      <el-input
                        v-model="form.remark"
                        placeholder="请输入备注"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <!--                    prop="invoice_mailing_addr"-->
                    <el-form-item label="发票邮寄地址">
                      <el-input
                        v-model="form.invoice_mailing_addr"
                        placeholder="请输入地址"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <!--                    prop="invoice_email"-->
                    <el-form-item label="电子邮箱">
                      <el-input
                        v-model="form.invoice_email"
                        placeholder="请输入电子邮箱"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="申请人(法人)" prop="applicant">
                      <el-input
                        v-model="form.applicant"
                        placeholder="请输入申请人"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div v-if="form.handle_type == 0">
                <div>
                  <el-form-item label="上传合同" prop="contract_file">
                    <inputUpload
                      class="mag_l-20"
                      @upload="contract_file"
                    ></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="上传银行流水" prop="bank_statement_file">
                    <inputUpload
                      class="mag_l-20"
                      @upload="bank_statement_file"
                    ></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="上传成果文件" prop="outcome_file">
                    <inputUpload
                      class="mag_l-20"
                      @upload="outcome_file"
                    ></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item
                    label="上传业务相关证据材料"
                    prop="evidence_file"
                  >
                    <inputUpload
                      class="mag_l-20"
                      @upload="evidence_file"
                    ></inputUpload>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="身份证扫描件" prop="identification_file">
                    <inputUpload
                      class="mag_l-20"
                      @upload="identification_file"
                    ></inputUpload>
                  </el-form-item>
                </div>
              </div>

              <!--              开票单位信息-->
              <div>
                <div class="em" style="padding: 10px 0 10px 20px">
                  开票单位信息
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="开票单位" prop="issuing_office">
                      <el-input
                        v-model="form.issuing_office"
                        placeholder="请输入开票单位"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="税号" prop="issuing_office_tax">
                      <el-input
                        v-model="form.issuing_office_tax"
                        placeholder="请输入税号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="地址">
                      <el-input
                        v-model="form.issuing_office_addr"
                        placeholder="请输入地址"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="电话" prop="issuing_office_tel">
                      <el-input
                        type="number"
                        oninput="if(value.length > 11) value=value.slice(0, 11)"
                        v-model="form.issuing_office_tel"
                        placeholder="请输入电话"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="银行开户号">
                      <el-input
                        v-model="form.issuing_office_bank"
                        placeholder="请输入银行开户号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item
                      label="银行账号"
                      prop="issuing_office_bank_card"
                    >
                      <el-input
                        v-model="form.issuing_office_bank_card"
                        placeholder="请输入银行账号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <!--                收票单位信息-->
                <div class="em" style="padding: 10px 0 10px 20px">
                  收票单位信息
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="收票单位" prop="receive_office">
                      <el-input
                        v-model="form.receive_office"
                        placeholder="请输入收票单位"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="税号" prop="receive_office_tax">
                      <el-input
                        v-model="form.receive_office_tax"
                        placeholder="请输入税号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="地址">
                      <el-input
                        v-model="form.receive_office_addr"
                        placeholder="请输入地址"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="电话">
                      <el-input
                        type="number"
                        oninput="if(value.length > 11) value=value.slice(0, 11)"
                        v-model="form.receive_office_tel"
                        placeholder="请输入电话"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="银行开户号">
                      <el-input
                        v-model="form.receive_office_bank"
                        placeholder="请输入银行开户号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="银行账号">
                      <el-input
                        v-model="form.receive_office_bank_card"
                        placeholder="请输入电话"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div v-if="form.handle_type == 1">
                <div class="pad_b_20">
                  <span class="em">请将身份证邮寄至以下地址</span>
                </div>
                <div>
                  <div>
                    <el-form-item label="地址">
                      <span>广西南宁市良庆区XXX路XX号XXXX大厦XX层XXX公司</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="收件人">
                      <span>周生生</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="联系电话">
                      <span>18900010001</span>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="wz_rigdq pad_t_20 bor_t_hui">
                <el-button @click="add" type="primary" style="width: 120px"
                  >提交</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getjgthdkp_Ziliao, addZL } from "../../api/api";

export default {
  name: "txkpzl",
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      list: {
        active: 2,
        order: [
          "下单支付费用",
          "选择办理方式并上传申请表",
          "资料预审核",
          "等待办结",
          "查看或下载发票",
        ],
      },
      rules: {
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        user_tel: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          {
            pattern: /^\d{11}$/,
            message: "电话号码格式不正确",
            trigger: "blur",
          },
        ],
        invoice_content: [
          { required: true, message: "请输入发票内容", trigger: "blur" },
        ],
        specification: [
          { required: true, message: "请输入规格型号", trigger: "blur" },
        ],
        unit_name: [{ required: true, message: "请输入单位", trigger: "blur" }],
        invoice_number: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
        invoice_amount: [
          { required: true, message: "请输入开票金额", trigger: "blur" },
        ],
        applicant: [
          { required: true, message: "请输入申请人", trigger: "blur" },
        ],
        contract_file: [
          { required: true, message: "请上传合同", trigger: "blur" },
        ],
        bank_statement_file: [
          { required: true, message: "请上传上传银行流水", trigger: "blur" },
        ],
        outcome_file: [
          { required: true, message: "请上传成果文件", trigger: "blur" },
        ],
        evidence_file: [
          {
            required: true,
            message: "请上传业务相关证据材料",
            trigger: "blur",
          },
        ],
        identification_file: [
          {
            required: true,
            message: "请上传业务相关证据材料",
            trigger: "blur",
          },
        ],
        issuing_office: [
          { required: true, message: "请输入开票单位", trigger: "blur" },
        ],
        issuing_office_tax: [
          { required: true, message: "请输入税号", trigger: "blur" },
        ],
        handle_type: [
          { required: true, message: "请选择办理方式", trigger: "blur" },
        ],
        invoice_type: [
          { required: true, message: "请选择开票类型", trigger: "blur" },
        ],
        receive_office: [
          { required: true, message: "请输入收票单位", trigger: "blur" },
        ],
        invoice_mailing_addr: [
          { required: true, message: "请输入地址", trigger: "blur" },
        ],
        amount_word: [
          { required: true, message: "请输入金额大写", trigger: "blur" },
        ],
        invoice_email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
        ],
        receive_office_tax: [
          { required: true, message: "请输入税号", trigger: "blur" },
        ],
        demo: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      form: {
        user_name: this.$store.state.userOrderDetaild.user_name,
        user_tel: this.$store.state.userOrderDetaild.user_tel,
        invoice_content: "",
        specification: "",
        unit_name: "",
        handle_type: parseInt("0"),
        invoice_type: parseInt("0"),
        invoice_number: "",
        invoice_amount: null,
        applicant: "",
        contract_file: "",
        bank_statement_file: "",
        outcome_file: "",
        issuing_office: "",
        issuing_office_tax: "",
        receive_office: "",
        evidence_file: "",
        identification_file: "",
        invoice_email: "",
        invoice_mailing_addr: "",
        remark: "默认",
      },
      uploadedFile: null,
    };
  },
  methods: {
    /**
     * 记录到资料库
     */
    async uploadToLibrary(uri) {
      // 获取文件名字
      let info_title = uri.replace(/^.*[\\/]/, "");
      let id = this.$store.state.userItem.id;
      return await addZL({
        info_path: uri,
        info_title,
        user_id: id,
      });
    },
    add() {
      if (this.form.handle_type == 1) {
        this.form.sn = this.$store.state.userOrderDetaild.sn;
        this.form.order_id = this.$store.state.userOrderDetaild.id;
        if (!this.form.receive_office) {
          this.$message({
            message: "请输入收票单位!",
            type: "error",
          });
        } else if (!this.form.receive_office_tax) {
          this.$message({
            message: "请输入收票单位税号!",
            type: "error",
          });
        } else if (
          this.form.receive_office_tax != "" &&
          this.form.receive_office != ""
        ) {
          this.form.sn = this.$store.state.userOrderDetaild.sn;
          this.form.order_id = this.$store.state.userOrderDetaild.id;
          getjgthdkp_Ziliao(this.form).then(async (res) => {
            if (res.data.code == 1) {
              await this.autoUpFile();
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$router.push("orderYewu");
            }
          });
        }
      } else {
        if (
          this.form.identification_file &&
          this.form.contract_file &&
          this.form.bank_statement_file &&
          this.form.outcome_file &&
          this.form.evidence_file
        ) {
          if (!this.form.receive_office) {
            this.$message({
              message: "请输入收票单位!",
              type: "error",
            });
          } else if (!this.form.receive_office_tax) {
            this.$message({
              message: "请输入收票单位税号!",
              type: "error",
            });
          } else if (
            this.form.receive_office_tax != "" &&
            this.form.receive_office != ""
          ) {
            this.form.sn = this.$store.state.userOrderDetaild.sn;
            this.form.order_id = this.$store.state.userOrderDetaild.id;
            getjgthdkp_Ziliao(this.form).then(async (res) => {
              if (res.data.code == 1) {
                await this.autoUpFile();
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$router.push("orderYewu");
              }
            });
          }
        } else {
          this.$message({
            message: "请上传文件!",
            type: "error",
          });
          // 执行非空验证未通过的逻辑
        }
      }
    },
    inputUpda() {
      const amount = this.form.invoice_amount;
      const amountWord = this.convertToUpperCase(amount);
      this.form.amount_word = amountWord;
    },
    convertToUpperCase(amount) {
      if (isNaN(amount)) {
        return "请输入有效的金额";
      }

      const digits = "零壹贰叁肆伍陆柒捌玖";
      const units = ["", "拾", "佰", "仟", "万", "拾", "佰", "仟", "亿"];
      const integerPart = "整";

      let upperCaseAmount = "";
      let integer = Math.floor(amount); // 取整数部分
      let decimal = Math.round((amount - integer) * 100); // 取小数部分并四舍五入

      if (integer === 0) {
        upperCaseAmount = digits[0];
      } else {
        let integerStr = integer.toString();
        let digitCount = integerStr.length;

        for (let i = 0; i < digitCount; i++) {
          let digit = parseInt(integerStr.charAt(i));
          let unit = digitCount - i - 1;

          if (digit !== 0) {
            upperCaseAmount += digits[digit] + units[unit];
          } else {
            // 处理连续的零，只保留一个零
            if (
              i < digitCount - 1 &&
              parseInt(integerStr.charAt(i + 1)) !== 0
            ) {
              upperCaseAmount += digits[digit];
            }
          }
        }
      }

      if (decimal === 0) {
        upperCaseAmount += integerPart;
      }

      return upperCaseAmount;
    },

    // 合同
    contract_file(file) {
      this.form.contract_file = file.response.data.url;
      console.log(file.response.data);
    },
    // 银行流水
    bank_statement_file(file) {
      this.form.bank_statement_file = file.response.data.url;
    },
    // 成果文件
    outcome_file(file) {
      this.form.outcome_file = file.response.data.url;
    },
    // 相关证据材料
    evidence_file(file) {
      this.form.evidence_file = file.response.data.url;
    },
    // 身份证扫描件
    identification_file(file) {
      this.form.identification_file = file.response.data.url;
    },
    async autoUpFile() {
      let fss = [
        "contract_file",
        "bank_statement_file",
        "outcome_file",
        "evidence_file",
        "identification_file",
      ];
      // 判断字段文件是否存在
      for (let i = 0; i < fss.length; i++) {
        let fs = fss[i];
        if (this.form[fs]) {
          await this.uploadToLibrary(this.form[fs]);
        }
      }
    },
    addHeYan() {},
  },
};
</script>

<style scoped></style>
