<template>
  <div style="width: 1200px;margin: 0 auto">
    <el-tabs class="ba_white pad_20 mag_t-20" v-model="activeName">
      <el-tab-pane label="订单详情" name="1">
        <order_Top></order_Top>
        <el-form ref="form" :model="form" size="small" label-width="160px" :rules="rules" >
          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs" style="border-bottom: #969896 1px dashed">基本信息</div>
            <div class="ddisp mag_t-20">
              <div class="pad_t_10">
                <el-form-item label="姓名" prop="user_name">
                  <el-input v-model="$store.state.userOrderDetaild.item.user_name" placeholder="请输入姓名" style="width: 300px"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="手机号" prop="user_tel">
                  <el-input v-model="$store.state.userOrderDetaild.item.user_tel" placeholder="请输入手机号" style="width: 300px"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs" style="border-bottom: #969896 1px dashed">报告标识信息</div>
            <div class="ddisp mag_t-20">
              <div class="pad_t_10">
                <el-form-item label="税号">
                  <el-input v-model="form.taxpayer_id" placeholder="请输入税号" style="width: 300px"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>


          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs"
                 style="border-bottom: #969896 1px dashed">审核信息</div>
              <div class="pad_t_10 mag_t-20">
                <el-form-item label="审核意见">
                  <span>{{form.review_comments}}</span>
                </el-form-item>
              </div>
              <div class="pad_t_10">
                <el-form-item label="附件">
                  <div v-if="this.form.review_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.review_file"
                       target="_blank">{{this.form.review_file.substring(this.form.review_file.lastIndexOf("/") + 1)}} ↓</a>
                  </div>
                  </el-form-item>
              </div>
          </div>
          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs"
                 style="border-bottom: #969896 1px dashed">报告文件</div>
              <div class="pad_t_10 mag_t-20">
                <el-form-item label="附件">
                  <div v-if="this.form.report_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.report_file"
                       target="_blank">{{this.form.report_file.substring(this.form.report_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>
                  </el-form-item>
              </div>
          </div>

          <div class="wz_rigdq pad_t_20 bor_t_hui">
            <el-button @click="$router.back()" type="primary" style="width: 120px">返回</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="流程日志" name="2">
        <log></log>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
export default {
  name: "hqbgDetail",
  data(){
    return{
      form:this.$store.state.userOrderDetaild.list,
      // fj:this.$store.state.userOrderDetaild.list.report_file.substring(this.$store.state.userOrderDetaild.list.report_file.lastIndexOf("/") + 1),
      activeName:"1",
    }
  },
}
</script>

<style scoped>

</style>