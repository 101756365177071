<template>
    <div style="background-color: #2a2a2a" class="text-white text-body-2">
        <div class="d-flex justify-space-around text-body-1 pt-5">
            <div class="d-flex align-center justify-center">
                <img style="width: 100px; height: 100px" src="@/assets/images/组 369@3x.png"></img>
                <span>一站式税务合规</span>
            </div>
            <div class="d-flex align-center justify-center">
                <img style="width: 100px; height: 100px" src="@/assets/images/组 358@3x.png"></img>
                <span>风控报告</span>
            </div>
            <div class="d-flex align-center justify-center">
                <img style="width: 100px; height: 100px" src="@/assets/images/组 375@3x.png"></img>
                <span>融资方案</span>
            </div>
            <div class="d-flex align-center justify-center">
                <img style="width: 100px; height: 100px" src="@/assets/images/组 371@3x.png"></img>
                <span>节税筹划方案</span>
            </div>
            <div class="d-flex align-center justify-center">
                <img style="width: 100px; height: 100px" src="@/assets/images/组 364@3x.png"></img>
                <span>专家一对一</span>
            </div>
        </div>
        <div class="divider">
            <div class="content"></div>
        </div>
        <div class="d-flex pt-3 pb-10">
            <div class="text-center w-100">
                <div class="footer-links text-body-1 font-weight-bold">
                    <div>业务中心</div>
                    <div>案例中心</div>
                    <div>帮助中心</div>
                    <div>业务工具</div>
                    <div>联系我们</div>
                </div>
                <div class="footer-links">
                    <a href="#">数字化系统报告</a>
                    <a href="#">行业案例库</a>
                    <a href="#">注册及登录</a>
                    <a href="#">在线咨询</a>
                    <a href="#">关于我们</a>
                </div>
                <div class="footer-links">
                    <a href="#">节税产品</a>
                    <a href="#">法律法规库</a>
                    <a href="#">下单流程</a>
                    <a href="#">各省份税务APP下载</a>
                    <a href="#">商务合作</a>
                </div>
                <div class="footer-links">
                    <a href="#">税务筹划</a>
                    <a href="#"></a>
                    <a href="#">用户服务</a>
                    <a href="#"></a>
                    <a href="#">人才计划</a>
                </div>
            </div>
            <div class="d-flex justify-space-around " style="width: 600px;">
                <div class="d-flex flex-column align-center justify-center">
                    <img style="width: 100px; height: 100px" src="@/assets/images/安税网公众号.jpg"></img>
                    <span>安税网公众号</span>
                </div>
                <div class="d-flex flex-column align-center justify-center">
                    <img style="width: 100px; height: 100px" src="@/assets/images/安税网小程序.jpg"></img>
                    <span>安税网小程序</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    // Component logic here
}
</script>

<style scoped>
.divider {
    text-align: center;
    position: relative;
    margin: 20px 0;
}

.divider:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    z-index: -1;
}

.divider .content {
    background-color: #706261;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    height: 1px;
    margin: 0 70px;
}

.footer-links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* 5 columns */
    grid-gap: 10px;
    /* Adjust gap between links */
}


.footer-links a {
    color: #fff;
    text-decoration: none;
    padding: 5px 0;
}

.footer-links div {
    padding-bottom: 10px;
}

.footer-links a:hover {
    text-decoration: underline;
}
</style>
