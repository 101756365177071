<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>卡片名称</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
        我是内容
      </div>
    </el-card>
  </div>
</template>

<script>
import {getUser} from "../../api/api";

export default {
  name: "userCenter",
  data(){
    return{
      user:{}
    }
  },
  mounted() {
    getUser().then(res=>{
      console.log(res)
    })
  }
}
</script>

<style scoped>

</style>