<template>
  <div style="min-height: 600px">
    <div>
      <S_2 :goods="item"></S_2>
    </div>
  </div>
</template>
<script>
export default {
  name: "order",
  data() {
    return {
      item: this.$store.state.good,
      root: 2,
      goodsDetails: {},
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped></style>
