<template>
  <div>
    <el-upload
      class="upload-demo"
      :action="$store.state.address"
      :headers="head"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      :file-list="uploadedFile ? [uploadedFile] : []"
      :accept="acceptFormats"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <span slot="tip" class="el-upload__tip mag_l-15"
        >单个文件不超过100M，支持pdf、doc/docx、xls/xlsx等文件格式</span
      >
    </el-upload>
  </div>
</template>

<script>
import { addZL } from "../api/api";
export default {
  name: "inputUpload",
  data() {
    return {
      acceptFormats: ".pdf,.doc,.docx,.xls,.jpeg,.png,.jpg,.xlsx",
      fileList: [],
      uploadedFile: null,
      head: {
        version: 1,
        token: localStorage.getItem("tokenAnShui"),
      },
    };
  },
  methods: {
    handleBeforeUpload(file) {
      // 清空已上传文件
      this.uploadedFile = null;
    },
    handleUploadSuccess(response, file) {
      this.uploadedFile = file;
      // this.uploadToLibrary(response);
      this.$emit("upload", file);
    },
    /**
     * 记录到资料库
     */
    uploadToLibrary(response) {
      let {
        data: { uri, name: info_title },
      } = response;
      let { userInfo } = this.$store.state;
      let { id } = userInfo;

      // 去掉 http://
      let info_path = uri.replace("http://", "");
      info_path = info_path.replace("https://", "");
      // 去掉 第一个 / 之前的
      info_path = info_path.replace(/^[^/]*\//, "");

      addZL({
        info_path,
        info_title,
        user_id: id,
      }).then((e) => {
        console.log(e);
      });
    },
  },
};
</script>

<style scoped></style>
