<template>
  <div>
    <div class="wh_1200 jz" style="padding-bottom: 30px">
      <div class="wh_f">
        <div class="flex_1">
          <div class="bor_10 boxs" style="width: calc(100% - 50px);margin-top: 16px">
            <div class="ba_white bor_10 wh_f" style="margin-top: 24px;padding: 20px 24px">
              <div v-if="false" class="flex_1 czjz_flex">
                <el-breadcrumb separator="/">
                  <el-breadcrumb-item :to="{ path: '/Index' }">首页</el-breadcrumb-item>
                  <el-breadcrumb-item >{{$store.state.mbx.name_2}}</el-breadcrumb-item>
                  <el-breadcrumb-item >{{$store.state.mbx.name_3}}</el-breadcrumb-item>
                </el-breadcrumb>
                {{$store.state.mb}}
              </div>
              <div @click="routerBack" class="disp flo_r xsgb" style="color: #8C8C8C">
                返回列表 <i class="el-icon-right"></i>
              </div>
            </div>
            <div class="pad_20 ba_white" style="margin-top: 16px">
              <div class="wz_jz">
                <div class="fz_18 em" style="padding-bottom: 24px">
                  {{breadcrumb.title}}
                </div>
                <div class="wz_hui wh_f">
                  <div class="flex_1">
                    作者: <span class="wz_black">{{breadcrumb.author }}</span>
                  </div>
                  <div class="flex_1">
                    浏览量: <span class="wz_black">{{ breadcrumb.visit }}</span>
                  </div>
                  <div class="flex_1">
                    发布时间: <span class="wz_black">{{breadcrumb.create_time }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="ba_white pad_20 fz_14">
              <div style="padding: 8px 0">
                内容简介
              </div>
              <div class="wz_huis">
                {{breadcrumb.content_validity }}
              </div>
            </div>
            <div class="ba_white pad_20" v-html="breadcrumb.content">
            </div>
          </div>
        </div>
        <div style="padding: 24px 0">
          <div class="bor_10 ba_white" style="width: 306px">
            <div class="bor_b_hui" style="padding: 16px 24px">
              <span><img src="../../assets/new.png" height="24" class="centerdq" alt=""></span>
              <span class="centerdq fz_14" style="margin-left: 4px">最新政策</span>
            </div>
            <div class="pad_0_20">
              <div class="wh_f czjz_flex pad_10_0" v-for="(item,index) in Case.one" :key="index" style="color: #595959">
                <div style="width: 10px">
                  <div class="bor_10" style="width: 6px;height: 6px;background-color: #f5690b"></div>
                </div>
                <div @click="add(item,1)" class="ccsl_1 flex_1 fz_14 xsgb">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="bor_10 ba_white" style="width: 306px;margin-top: 24px">
            <div class="bor_b_hui" style="padding: 16px 24px">
              <span><img src="../../assets/new.png" height="24" class="centerdq" alt=""></span>
              <span class="centerdq fz_14" style="margin-left: 4px">推荐案例</span>
            </div>
            <div class="pad_0_20">
              <div class="wh_f czjz_flex pad_10_0" v-for="(item,index) in Case.two" :key="index" style="color: #595959">
                <div style="width: 10px">
                  <div class="bor_10" style="width: 6px;height: 6px;background-color: #f5690b"></div>
                </div>
                <div @click="add(item,2)" class="ccsl_1 flex_1 fz_14 xsgb">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCaseDetail, getCaseList} from "../../api/api";

export default {
  name: "CaseData",
  data() {
    return {
      SelectInput: '',
      breadcrumb:{},
      Case:{
        one:{},
        two:{}
      }
    }
  },
  methods:{
    add(e,i){
      e.isNow = i
      this.$store.commit('caseList', e);
      this.$router.push({ path: '/kb', force: true });
    },
    routerBack(){
      if (this.$store.state.isCase==0){
        this.$router.push({ path: 'policy', force: true });
      }else{
        this.$router.push({ path: 'Case', force: true });
      }
    }
  },
  mounted() {
    this.breadcrumb = this.$store.state.caseList.id
    getCaseDetail({
      id:this.breadcrumb
    }).then(res=>{
      this.breadcrumb = res.data.data
    })
    getCaseList({
      section_code:0,
      page_no:1,
      page_size:10
    }).then(res=>{
      this.Case.one = res.data.data.lists
    })
    getCaseList({
      section_code:1,
      page_no:1,
      page_size:10
    }).then(res=>{
      this.Case.two = res.data.data.lists
    })
  }
}
</script>

<style scoped>

</style>
