<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18 em">业务下单</div>
      <TopActive></TopActive>
      <!--    <div class="ba_white pad_50_0">-->
      <!--      <el-steps :active="$store.state.userOrderDetaild.step_value"  align-center>-->
      <!--        <el-step v-for="(item,index) in $store.state.userOrderDetaild.step_list" :key="value" :title="item.label"></el-step>-->
      <!--      </el-steps>-->
      <!--    </div>-->
      <div>
        <div class="ba_white mag_t-30">
          <div class="">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
              <div class="em" style="padding: 10px 0 0 20px">
                请输入标识信息
              </div>
              <div class="ddisp bor_b_xu pad_20 split_2">
                <el-form-item label="税号" prop="taxpayer_id">
                  <el-input
                    v-model="form.taxpayer_id"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="em" style="padding: 10px 0 0 20px">订单信息</div>
              <div class="wh_f bor_b_hui pad_20">
                <div class="flex_1">
                  <div class="pad_10_0 wz_hui fz_14">业务信息</div>
                  <div class="ddisp">
                    <div class="topdq">
                      <img
                        :src="
                          $store.state.userOrderDetaild.order_goods[0]
                            .goods_image
                        "
                        style="width: 80px"
                        alt=""
                      />
                    </div>
                    <div class="topdq pad_l_5 boxs">
                      <p class="em">
                        {{
                          $store.state.userOrderDetaild.order_goods[0]
                            .goods_name
                        }}
                      </p>
                      <!--                    <p class="wz_hui pad_10_0 fz_14">默认 (此为规格信息)</p>-->
                    </div>
                  </div>
                </div>
                <div class="flex_1 wh_f fz_14 wz_hui">
                  <div class="flex_1">
                    订单总价
                    <div class="wz_black pad_10_0">
                      ￥{{ $store.state.userOrderDetaild.order_amount }}
                    </div>
                  </div>
                  <div class="flex_1">
                    订单状态
                    <div class="wz_black pad_10_0">
                      {{ $store.state.userOrderDetaild.order_status_desc }}
                    </div>
                  </div>
                  <div class="flex_1">
                    下单留言
                    <div class="pad_10_0 wz_hui">
                      {{ $store.state.userOrderDetaild.baining_remark }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="wh_f pad_20">
                <div class="flex_1"></div>
                <div class="flo_r fz_14">
                  <div class="wz_rigdq" style="padding: 50px 0 20px">
                    <span
                      @click="addHeYan"
                      class="wz_white bor_3 xsgb"
                      style="padding: 20px 30px; background-color: #d9001b"
                      >提交核验</span
                    >
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  gethqbg_Heyan,
  getOrderActive,
  getorderdetail,
  getorderList,
} from "../../api/api";

export default {
  name: "srbsxx",
  data() {
    return {
      active: 2,
      order: ["下单支付报告费用", "输入标识信息", "核验标识信息", "获取报告"],
      form: {
        taxpayer_id: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        taxpayer_id: [
          { required: true, message: "请输入税号", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
      // pid:this.$route.query.pid,
      list: {},
    };
  },
  methods: {
    addHeYan() {
      if (this.form.taxpayer_id != "") {
        gethqbg_Heyan({
          sn: this.$store.state.userOrderDetaild.sn,
          taxpayer_id: this.form.taxpayer_id,
          order_id: this.$store.state.userOrderDetaild.id,
        }).then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.$router.push("yeWuIndex");
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.$message.error("请填写税号");
      }
    },
    addActive() {
      getOrderActive({ type: this.list.baining_step_type }).then((res) => {
        this.order = res.data.data;
      });
    },
  },
  mounted() {
    // getorderdetail({id:this.pid}).then(res=>{
    //   this.list = res.data.data
    //   this.addActive()
    // })
  },
};
</script>

<style scoped></style>
