<template>
  <div>
    <div class="wh_1200 jz" style="padding-bottom: 30px">
      <div slot="header" class="wh_f czjz_flex" style="padding: 38px 0 20px">
        <span class="centerdq flex_1 fz_20">政策法规库</span>
        <div class="wh_f centerdq" style="float: right;width: 600px">
          <div class="flex_1">
            <el-input placeholder="请输入关键词搜索" v-model="selectInput" style="border: 1px solid #dd2d22;border-radius: 5px">
              <template slot="prepend"><i class="el-icon-search"></i></template>
            </el-input>
          </div>
          <div class="pad_l_20"><el-button @click="Select" type="danger" style="background-color: #df2d22">搜索</el-button></div>
        </div>
      </div>
      <div class="wh_f">
        <div class="flex_1">
          <div class="ba_white bor_10 boxs" style="width: calc(100% - 50px)">
            <div class="pad_20">
              <el-empty class="ba_white" v-if="faGui.length==0" description="暂无数据 !"></el-empty>

              <div v-for="(item,index) in faGui" :key="index">
                <div class="ccsl_2 fz_16">
                  {{ item.title }}</div>
                <div class="fz_14 ccsl_2 mag_10_0 wz_hui">{{item.content_validity}}</div>
                <div class="wh_f" style="margin: 27px 0;">
                  <div class="flex_1 fz_13">
                    <span class="wz_hui">{{ item.create_time }}</span>
                    <span class="wz_ju mag_l-20">{{ item.classify_title }}</span>
                  </div>
                  <div class="flo_r fz_14">
                    <span class="wz_ju bor_5 xsgb" @click="add(item)" style="padding: 10px 26px;background-color: #FFE6E6;">查看详情</span>
                  </div>
                </div>
              </div>
            </div>

            <div class=" pad_10 mag_t-10 wz_ju ba_white">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageSize"
                  :page-sizes="[10, 20, 30]"
                  :page-size="100"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
        <div>
          <div class="bor_10 ba_white" style="width: 306px">
            <div class="bor_b_hui" style="padding: 16px 24px">
              <span><img src="../../assets/new.png" height="24" class="centerdq" alt=""></span>
              <span class="centerdq fz_14">最新政策</span>
            </div>
            <div class="pad_0_20">
              <div class="wh_f czjz_flex pad_10_0" v-for="(item,index) in Case" :key="index" style="color: #595959">
                <div style="width: 10px">
                  <div class="bor_10" style="width: 6px;height: 6px;background-color: #f5690b"></div>
                </div>
                <div @click="add(item)" class="ccsl_1 xsgb flex_1 fz_14">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFaGui} from "../../api/api";

export default {
  name: "policy",
  data(){
    return{
      SelectInput:'',
      total:0,  //总数据条数
      pageIndex:1,  //当前所在页默认是第一页
      pageSize:10,  //每页显示多少行数据 默认设置为10
      faGui:[],
      selectInput:'',
      Case:[]
    }
  },
  methods:{
    handleSizeChange(newSize){
      this.pageSize = newSize
      console.log(newSize)
      this.getList(this.pageIndex,this.pageSize)
    },
    handleCurrentChange(newPage){
      this.pageIndex=newPage
      console.log(newPage)
      this.getList(this.pageIndex,this.pageSize)
    },
    getList(i,s){
      getFaGui({page_no:i,page_size:s,section_code: 0}).then(res=>{
        this.faGui = res.data.data.lists
        this.Case = res.data.data.lists
        this.total = res.data.data.count
      })
    },
    add(e){
      this.$store.commit('caseList', e);
      this.$router.push({ path: '/CaseData', force: true });
    },
    Select(){
      getFaGui({
        title:this.selectInput,
        page_no:this.pageIndex,
        page_size:this.pageSize}).then(res=>{
        this.faGui = res.data.data.lists
        this.total = res.data.data.count
      })
    }
  },
  mounted() {
    this.getList(this.pageIndex,this.pageSize)

    this.$store.commit('isCase',0);
  }
}
</script>

<style scoped>

</style>