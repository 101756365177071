<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>卡片名称</span>
      <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
    </div>
    <div>
      我是内容
    </div>
  </el-card>
</template>

<script>
import {getUser} from "../../api/api";

export default {
  name: "orderCenter",
  data(){
    return{
      user:{}
    }
  },
  mounted() {
    getUser().then(res=>{
      console.log(res)
    })
  },
  methods:{
  }
}
</script>

<style scoped>

</style>