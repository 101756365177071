<template>
  <div>
    <div
      class="jz"
      style="width: 1200px; padding-bottom: 30px; min-height: 100vh"
    >
      <div>
        <div class="pad_30_0 fz_18" style="display: flex; align-items: center">
          <span
            @click="back"
            class="bor_3 xsgb wz_white fz_14"
            style="
              margin-right: 10px;
              background-color: #d9001b;
              padding: 14px 20px;
            "
          >
            返回
          </span>
          <div class="">业务详情</div>
        </div>

        <div v-if="goodsDetails">
          <div class="ba_white wh_f pad_20">
            <div style="width: 330px">
              <div style="width: 300px">
                <div>
                  <el-image
                    style="height: 300px; width: 300px"
                    :src="goodsDetails.image"
                    :fit="$store.state.fits"
                    :preview-src-list="[goodsDetails.image, ...img]"
                  ></el-image>
                </div>
              </div>
              <div class="ddisp bhh ccgl pad_10_0" style="width: 300px">
                <div
                  v-for="(item, index) in goodsDetails.goods_image"
                  :key="index"
                  style="width: 100px; height: 100px; margin: 0 10px"
                >
                  <el-image
                    style="height: 100px; width: 100px"
                    :src="item"
                    :preview-src-list="img"
                  ></el-image>
                </div>
              </div>
            </div>
            <div class="flo_r flex_1">
              <div class="em pad_b_20 ccsl_1" style="font-size: 22px">
                {{ goodsDetails.name }}
              </div>
              <div
                class="pad_20 boxs wz_hui bor_10"
                style="background-color: #f5f5f5"
              >
                <div>
                  <span class="centerdq">市场价</span
                  ><span
                    class="mag_l-10 centerdq"
                    style="text-decoration: line-through"
                    >￥ {{ goodsDetails.tax_price }}</span
                  >
                </div>
                <div class="mag_t-10">
                  <span class="centerdq">安税价</span>
                  <span
                    v-show="goodsDetails.is_set_price == 1"
                    class="wz_red fz_20 centerdq mag_l-10"
                  >
                    ￥{{ goodsDetails.sell_price }}
                  </span>

                  <span
                    v-show="goodsDetails.is_set_price == 0"
                    class="wz_red fz_20 centerdq mag_l-10"
                  >
                    待报价
                  </span>
                  <span
                    v-if="goodsDetails.shipping_tips"
                    class="fz_12 mag_l-10"
                    style="
                      padding: 3px 5px;
                      border: 1px solid #008080;
                      color: #008080;
                    "
                  >
                    {{ goodsDetails.shipping_tips }}
                  </span>
                </div>
              </div>
              <div class="pad_20 wz_hui">
                <span>成交量: {{ goodsDetails.sales_num }}</span>
                <span class="fz_20 xt" style="margin: 0 10px">|</span>
                <span>浏览量: {{ goodsDetails.click_num }}</span>
              </div>
              <div class="wz_hui pad_20">
                <div>
                  类型
                  <span
                    class="mag_l-10"
                    style="
                      padding: 5px 10px;
                      background-color: #ffedef;
                      border: 1px solid #dd1b33;
                      color: #dd1b33;
                    "
                  >
                    {{ goodsDetails.category_name[0] }}
                  </span>
                </div>
                <div class="pad_20_0">
                  数量
                  <el-input-number
                    class="mag_l-10"
                    size="mini"
                    v-model="numage"
                    @change="handleChange"
                    :min="1"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
              <div class="wh_f">
                <div class="flex_1"></div>
                <div class="flo_r">
                  <span v-if="!loginUser" class="wz_red fz_14"
                    >下单前, 请先登录</span
                  >
                  <span
                    v-if="loginUser && isChannel"
                    class="bor_3 xsgb wz_white fz_14 bor_10 mag_l-30"
                    @click="toBatchOrder"
                    style="background-color: #d9001b; padding: 14px 20px"
                    >批量下单</span
                  >

                  <span
                    v-if="loginUser"
                    class="bor_3 xsgb wz_white fz_14 bor_10 mag_l-30"
                    @click="addShoping"
                    style="background-color: #d9001b; padding: 14px 20px"
                    >直接下单</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--    业务模块-->
          <div class="ba_white topdq wh_f pad_20_0 mag_t-30 boxs">
            <!--      推荐业务-->
            <div class="topdq" style="width: 240px">
              <div class="xt pad_b_20 bor_b_hui fz_18 em">
                <span class="mag_l-30">推荐业务</span>
              </div>
              <div>
                <div
                  class="xsgb"
                  @click="pageGo(item)"
                  v-for="(item, index) in goodsDetails.recommend"
                  :key="index"
                >
                  <div
                    class="jz mag_t-10 boxs bor_10 bor_1_hui"
                    style="width: 90%"
                  >
                    <div>
                      <img
                        :src="item.image"
                        class="wh_max bor_10 img_size"
                        alt=""
                      />
                    </div>
                    <div class="pad_10 boxs">
                      <div class="pad_10_0">
                        <div class="ccsl_2">{{ item.name }}</div>
                      </div>
                      <div class="ddisp">
                        <div
                          class="fz_20 wz_ju"
                          style="color: #dc2d22"
                          v-if="item.is_set_price == 0"
                        >
                          待报价
                        </div>
                        <div
                          v-if="item.is_set_price == 1"
                          style="color: #dc2d22"
                        >
                          ¥<span class="fz_20 wz_ju mag_l-5">{{
                            item.sell_price
                          }}</span>
                        </div>
                        <div class="wz_hui flo_r fz_13 bottomdq">
                          成交量{{ item.sales_num }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ddisp flex_1 topdq">
              <!--        业务详情-->
              <div
                class="boxs topdq boxs"
                style="
                  width: calc(100% - 200px);
                  padding: 0 10px 20px 10px;
                  border-right: 1px solid #f6f6f6;
                  border-left: 1px solid #f6f6f6;
                "
              >
                <div class="bor_b_hui pad_b_20 xt fz_18 em">业务详情</div>
                <div
                  class="img_fwb"
                  v-html="goodsDetails.content"
                  style="min-height: 200px"
                ></div>
              </div>
              <!--        小程序二维码-->
              <div class="pad_b_20 topdq" style="width: 200px">
                <div class="xt pad_b_20 bor_b_hui">
                  <span class="mag_l-30 fz_18 em">手机端访问</span>
                </div>
                <div class="pad_10 boxs">
                  <!-- <img src="../../assets/wxxcx.png" style="width: 100%" /> -->
                  <img v-if="qcurl" :src="qcurl" alt="" style="width: 100%" />
                </div>
                <div class="wz_jz">安税网小程序</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { getQianbao, getQc, getUser } from "../../api/api";
import { getshopDetail } from "../../api/test";
import VueQr from "vue-qr";
let loading;

export default {
  name: "detail",
  data() {
    return {
      isChannel: false,
      qcurl: false,
      numage: 1,
      // id:this.$route.query.pid,
      imgUrl: "",
      img: [],
      // 商品数据
      goodsDetails: this.$store.state.goodsDetails,
      order_item: {},
      loginUser: localStorage.getItem("userInfoAnShui"),
    };
  },
  components: {
    VueQr,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    toBatchOrder() {
      this.$router.push({
        path: "/batchOrder/" + this.goodsDetails.id,
      });
    },
    isShowChannel() {
      getUser().then((res) => {
        let { labels } = res.data.data;
        let { id } = this.goodsDetails;
        if (labels && labels.indexOf("渠道") !== -1 && id == 63) {
          this.isChannel = true;
        }
      });
    },
    getQcUrl() {
      let { id } = this.goodsDetails;

      getQc({
        visit: 1,
        page: "pages/goods_detail/goods_detail",
        scene: id,
      }).then((e) => {
        let {
          data: {
            data: { filepath = null },
          },
        } = e;
        let imgHttp = this.$store.state.imgHttp;
        console.log(this.$store.state.imgHttp);
        console.log(filepath);
        if (filepath) {
          this.qcurl = imgHttp + filepath;
        }
      });
    },
    pageGo(e) {
      // 开启加载动画
      loading = Loading.service({
        lock: true,
        text: "加载中……",
        background: "rgba(255,255,255,0.7)",
      });
      // 保存点击的推荐商品
      this.$store.state.goodDetailId = e.id;
      this.getQcUrl();
      // 查询商品信息
      getshopDetail({
        goods_id: this.$store.state.goodDetailId,
        visit: 1,
      }).then((res) => {
        if (res.status == 200) {
          // 关闭加载动画
          loading.close();
          // 设置商品数据
          this.$store.state.goodsDetails = res.data.data;
          this.$router.push({ path: "/kb", force: true });
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
    addShoping() {
      getQianbao().then((res) => {
        console.log(123, res);
      });
      if (localStorage.getItem("userInfoAnShui")) {
        this.$store.commit("good", {
          list: this.goodsDetails,
          num: this.numage,
        });
        this.$router.push({
          name: "order",
        });
      } else {
        this.$notify.error({
          title: "未登录",
          message: "请先登录!",
        });
        this.$router.push("login");
      }
    },
    getGoodsInfo() {
      let id = this.$route.query.id;
      if (id) {
        getshopDetail({ goods_id: id, visit: 1 }).then((res) => {
          if (res.status == 200) {
            // 在某个组件中的方法中提交 mutation
            this.$store.commit("setGoodsDetails", res.data.data);
            this.$router.push({
              name: "detail",
            });
          }
        });
      }
    },
  },
  mounted() {
    this.getQcUrl();
    this.isShowChannel();
  },
};
</script>

<style scoped>
.img_fwb >>> img {
  width: 100%;
}
</style>
