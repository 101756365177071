<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 40px">
      <div slot="header" class="pad_20_0 wh_f czjz_flex">
        <span class="centerdq flex_1 fz_20">业务中心</span>
        <div class="wh_f centerdq" style="float: right; width: 600px">
          <div class="flex_1">
            <el-input
              placeholder="请输入关键词搜索"
              v-model="SelectInput"
              style="border: 1px solid #dd2d22; border-radius: 5px"
            >
              <template slot="prepend"><i class="el-icon-search"></i></template>
            </el-input>
          </div>
          <div class="pad_l_20">
            <el-button @click="look" type="danger">搜索</el-button>
          </div>
        </div>
      </div>
      <div class="ba_white bor_5 pad_b_20" style="">
        <div class="wz_hui pad_20 boxs fz_14 bor_b_hui">
          <div style="padding: 10px 0 20px">
            <div class="xd">
              <span>业务类型:</span>
              <span
                @click="bddSpan"
                :class="indexSpan == 'all' ? 'titSpan_xz' : ''"
                class="titSpan xsgb wz_black"
              >
                全部
              </span>
              <span
                @mousemove="item.isTrue = true"
                @mouseleave="item.isTrue = false"
                v-for="(item, index) in titleList"
                :key="index"
              >
                <span
                  class="titSpan xsgb wz_black"
                  :class="indexSpan == index ? 'titSpan_xz' : ''"
                  @click="addSpan(item, index)"
                  >{{ item.name }}</span
                >
                <div
                  v-if="item.isTrue"
                  class="jd pad_t_10 wh_max boxs"
                  style="bottom: -34px; height: 30px"
                >
                  <span
                    @click="cddSpan(items, index)"
                    class="xsgb hover_yellow wz_black"
                    style="margin-right: 10px; font-size: 12px"
                    v-for="(items, indexs) in item.sons"
                    :key="indexs"
                  >
                    {{ items.name }}
                  </span>
                </div>
              </span>
            </div>
          </div>

          <div class="pad_20_0 mag_t-10">
            排序方式:
            <span
              @click="Desc(1, '')"
              :class="sortIndex == 1 ? 'titSpan_xz' : ''"
              class="titSpan xsgb wz_black"
              >默认</span
            >
            <span
              @click="Desc(2, 'asc')"
              :class="sortIndex == 2 ? 'titSpan_xz' : ''"
              class="titSpan xsgb wz_black"
              >价格<i class="el-icon-d-caret"></i
            ></span>
            <span
              @click="Desc(3, 'desc')"
              :class="sortIndex == 3 ? 'titSpan_xz' : ''"
              class="titSpan xsgb wz_black"
              >成交量<i class="el-icon-d-caret"></i
            ></span>
          </div>
        </div>
        <div class="ddisp hh pad_10">
          <div
            class="topdq"
            v-for="(item, index) in list"
            :key="index"
            style="width: 20%"
          >
            <div
              @click="addDetail(item.id)"
              class="jz xsgb mag_t-10 boxs bor_10 bor_1_hui"
              style="width: 90%"
            >
              <div style="height: 214px">
                <img
                  :src="item.image"
                  class="wh_max img_size"
                  alt=""
                  style="border-radius: 10px 10px 0 0"
                  height="219"
                />
              </div>
              <div class="pad_10 boxs">
                <div class="pad_10_0" style="min-height: 42px">
                  <div class="ccsl_2">{{ item.name }}</div>
                </div>
                <div class="wh_f">
                  <div class="" style="color: #dc2d22; height: 20px">
                    <span v-if="item.is_set_price == 1">
                      ¥<span class="fz_26 wz_ju">{{ item.sell_price }}</span>
                    </span>
                    <span v-if="item.is_set_price == 0" class="fz_18 wz_ju">
                      待报价
                    </span>
                  </div>
                  <div class="wz_hui fz_13 flex_1">
                    <div class="czjz_flex flo_r" style="height: 100%">
                      成交量 {{ item.sales_num }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="list == ''" class="wz_jz">
          <el-empty description="没有数据哦"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLookShop,
  getOrderYewu,
  getTopList,
  getYewuIndex,
} from "../../api/api";
import { getshopDetail, getYewuOrderList } from "../../api/test";
import { Loading } from "element-ui";
let loading;
export default {
  name: "yeWuIndex",
  data() {
    return {
      SelectInput: "",
      titleList: [],
      indexSpan: "all",
      sortIndex: 1,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      list: [],
      sele: "all",
    };
  },
  methods: {
    getNewList(e) {
      if ((this.indexSpan = "all")) {
        this.sele = "";
      }
      getLookShop({
        price: e,
        category_id: this.sele,
      }).then((res) => {
        this.list = res.data.data.lists;
      });
    },
    Desc(e, desc) {
      this.sortIndex = e;
      this.getNewList(desc);
    },
    addDetail(e) {
      loading = Loading.service({
        lock: true,
        text: "加载中……",
        background: "rgba(255,255,255,0.7)",
      });
      let item = {};
      getshopDetail({ goods_id: e, visit: 1 }).then((res) => {
        console.log(e);
        if (res.status == 200) {
          loading.close();
          // 在某个组件中的方法中提交 mutation
          this.$store.commit("setGoodsDetails", res.data.data);
          this.$router.push({
            name: "detail",
          });
        }
      });
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(this.pageIndex, this.pageSize);
    },
    handleCurrentChange(newPage) {
      this.pageIndex = newPage;
      this.getList(this.pageIndex, this.pageSize);
    },
    bddSpan() {
      this.indexSpan = "all";
      this.getGoodsList();
    },
    // 点击业务类型筛选业务
    addSpan(e, i) {
      this.indexSpan = i;
      this.sele = e.id;
      let item = e;
      let { sons } = item;
      if (sons && sons.length > 0) {
        item.isTrue = true;
      } else {
        this.getOldsList(this.sele);
      }
    },
    // 点击业务类型筛选业务
    cddSpan(e, i) {
      console.log(e);
      this.indexSpan = i;
      this.sele = e.id;
      this.getOldsList(this.sele);
    },
    getList(p, s) {},
    look() {
      getLookShop({ name: this.SelectInput }).then((res) => {
        this.list = res.data.data.lists;
      });
      // SelectInput
    },
    getGoodsList(e) {
      this.$router.push({
        path: "/yeWuIndex",
        query: {
          id: e,
        },
      });
      getLookShop({ category_id: e }).then((res) => {
        this.list = res.data.data.lists;
      });
    },
    getOldsList(e) {
      this.$router.push({
        path: "/yeWuIndex",
        query: {
          id: e,
        },
      });
      getLookShop({ category_id: e }).then((res) => {
        this.list = res.data.data.lists;
      });
    },
  },
  mounted() {
    // 获取 query 参数
    let id = this.$route.query.id || this.$store.state.yewuId;
    console.log("id", id);
    if (id != null) {
      this.getGoodsList(id);
    } else if (id == "all") {
      this.getGoodsList();
    } else {
      this.getGoodsList(this.$store.state.yewuId);
    }
    getYewuIndex().then((res) => {
      if (res.status == 200) {
        this.titleList = res.data.data.lists;
        this.titleList = res.data.data.lists.map((item) => {
          return { ...item, isTrue: false };
        });
      }
    });
  },
};
</script>

<style scoped></style>
