<template>
  <div class="wz_white" style="background-color: #2b2a2a">
    <!-- <div class="jz " style="width: 1200px">
      <div class="wh_f wz_jz" style="padding: 20px 0;border-bottom: 1px solid white">
        <div class="flex_1" v-for="(item,index) in Bottomlist" :key="index">
          <img class="centerdq" style="width: 40px" :src="$store.state.imgHttp+item.url" alt="">
          <span class="mag_l-10">{{item.name}}</span>
        </div>
      </div>
      <div class="pad_10_0">
      </div>
    </div> -->
    <div class="wz_jz pad_20_0 wz_white fz_14" style="background-color: #1c1b1b">
      <span>{{ bottomBQ.copyright }}</span>
      <span class="mag_l-10">{{ bottomBQ.record_number }}</span>
    </div>
  </div>
</template>

<script>
import { getBQview, getImgUrlTop } from "../api/api";

export default {
  name: "TopMenu",
  data() {
    return {
      Bottomlist: [],
      bottomBQ: {},
    };
  },
  methods: {},
  mounted() {
    getBQview().then((res) => {
      this.bottomBQ = res.data.data;
      console.log("版权信息", res);
    });
    getImgUrlTop().then((res) => {
      this.Bottomlist = res.data.data.content[2].content.data;
    });
  },
};
</script>

<style scoped></style>
