import Vue from "vue";

/**
 * vue消息显示防抖,如果消息内容一样，则不显示
 */
// messageUtils.js

let timer;
let lastMessage = null;

export function yt_msg(message, delay = 2000, jg = false) {
  // 如果消息内容相同，则不显示
  if (lastMessage === message) {
    return;
  }
  console.log(message);

  // 清除之前的定时器
  if (timer) {
    clearTimeout(timer);
  }

  // 设置新消息
  lastMessage = message;

  // 显示消息
  // displayFunction(message);
  if (!jg) Vue.prototype.$message.error(message);
  else Vue.prototype.$message.success(message);

  // delay毫秒后清除消息
  timer = setTimeout(() => {
    lastMessage = null;
  }, delay);
}
