<template>
<div class="wz_white" style="width:60px">
<!--  <div class="jz_flex ba_hei_op xsgb" style="height: 60px;border-radius: 5px 0 0 5px">-->
<!--    <div class="wz_jz">-->
<!--      <img src="../assets/lxwm.png" height="32" width="32"/>-->
<!--&lt;!&ndash;      <p class="fz_12">联系我们</p>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="jz_flex ba_hei_op mag_t-10 xsgb" style="height: 60px;border-radius: 5px 0 0 5px">-->
<!--    <div class="wz_jz">-->
<!--      <img src="../assets/zxkf.png" height="32" width="32"/>-->
<!--&lt;!&ndash;      <p class="fz_12">在线客服</p>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
  <div @click="returnTop" class="jz_flex ba_hei_op mag_t-10 xsgb" style="height: 60px;border-radius: 5px 0 0 5px">
    <div class="wz_jz">
      <img src="../assets/fhdb.png" height="32" width="32"/>
<!--      <p class="fz_12">返回顶部</p>-->
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ReturnTop",
  methods:{
    returnTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 使用平滑滚动效果
      });
    }
  }
}
</script>

<style scoped>

</style>