<template>
<!--  流程日志-->
<div>
  <!--          流程信息-->
  <div class="bor_1_hei">
    <div class="fz_16 em pad_10 boxs" style="border-bottom: #969896 1px dashed">流程信息</div>
    <div class="jz boxs" style="padding: 0 40px">
      <div class="pad_30_0">
        <el-timeline style="width: 100%;">
          <el-timeline-item
              class=""
              v-for="(item,index) in this.$store.state.userOrderDetaild.list.orderLog"
              :key="index"
              placement="top">
            <div>
              <div class="wh_f">
                <div class="flex_1"><span class="fz_16">{{ item.content }}</span></div>
                <div class="flex_r fz_16">
                  <span class="wz_hui">{{item.create_time}}</span>
                </div>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "log",
  data(){
    return{
    }
  }
}
</script>

<style scoped>

</style>