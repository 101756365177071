<template>
  <div class="ba_white mag_b_20">
    <div class="pad_20 wh_f bor_b_xu">
      <div style="width: 200px">
        <span
          ><img
            class="centerdq"
            src="../assets/chenggong.png"
            width="26px"
            alt=""
        /></span>
        <span class="centerdq mag_l-10 fz_20 em" style="color: #70b603">
          支付成功
        </span>
      </div>
    </div>
    <div class="bor_b_xu">
      <div class="wh_f fz_14 pad_20_0" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px">订单编号:</div>
        <div class="">
          <span class="centerdq mag_l-20">{{ order_item.sn }}</span>
        </div>
      </div>
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px">订单价格:</div>
        <div class="">
          <span class="centerdq mag_l-20" style="color: #d9001b">
            ￥{{ order_item.order_amount }}
          </span>
        </div>
      </div>
      <!--      <div class="wh_f fz_14 pad_b_20" style="color: #555555">-->
      <!--        <div class="wz_rigdq" style="width: 130px;">下单人:</div>-->
      <!--        <div class=""><span class="centerdq mag_l-20">dawdwa</span></div>-->
      <!--      </div>-->
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px">业务名称:</div>
        <div class="">
          <span class="centerdq mag_l-20">{{
            order_item.order_goods[0].goods_name
          }}</span>
        </div>
      </div>
    </div>
    <div class="pad_20 ddisp">
      <el-button @click="$router.push('Index')">返回首页</el-button>
      <el-button @click="$router.push('orderYewu')">查看订单</el-button>
      <el-divider direction="vertical" style="height: 50px"></el-divider>
      <el-button
        v-if="!order_item.submit && order_item.baining_step_type == 1"
        @click="srbsxx"
      >
        输入标识信息
      </el-button>
      <el-button
        v-if="!order_item.submit && order_item.baining_step_type == 2"
        @click="scbgwj"
      >
        上传报告文件
      </el-button>
      <el-button
        v-if="!order_item.submit && order_item.baining_step_type == 4"
        @click="sckbzl"
      >
        上传开办资料
      </el-button>
      <el-button
        v-if="!order_item.submit && order_item.baining_step_type == 5"
        @click="txkpzl"
      >
        填写开票资料
      </el-button>
      <el-button
        v-if="!order_item.submit && order_item.baining_step_type == 6"
        @click="kpzl_err"
      >
        填写开票资料
      </el-button>
      <el-button
        v-if="!order_item.submit && order_item.baining_step_type == 3"
        @click="lxzj"
      >
        立即联系专家
      </el-button>
      <br />

      <!--      <el-button v-if="!order_item.submit && order_item.baining_step_type==1">-->
      <!--        专家分析解读-->
      <!--      </el-button>-->

      <!--      <el-button>咨询专家</el-button>-->
      <!--      <el-button>上传资料</el-button>-->
      <!--      <el-button>填写代开票要求</el-button>-->
      <!--      <el-button>填写代开票资料</el-button>-->
      <el-button v-if="order_item.baining_step_type == 7">填写表单</el-button>
    </div>
    <el-dialog
      title="联系专家助理微信"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div class="fz_18 wz_black">
        <div class="pad_10_0">专家助理: <span> 苟翻译</span></div>
        <div>助理微信: <span> 123456789</span></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="wz_jz">
          <el-button class="jz" @click="dialogVisible = false">关闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getorderdetail } from "../api/api";

export default {
  name: "payOk",
  data() {
    return {
      dialogVisible: false,
      order_item: {},
    };
  },
  methods: {
    srbsxx() {
      this.$router.push("srbsxx");
    },
    // 上传报告文件
    scbgwj() {
      this.$router.push("/scjdbg");
    },
    // 上传开办资料
    sckbzl() {
      this.$router.push("/sckbzl");
    },
    // 上传开办资料
    txkpzl() {
      this.$router.push("/txkpzl");
    },
    // 上传开票资料(自然人)
    kpzl_err() {
      this.$router.push("/kpzl_err");
    },
    lxzj() {
      window.open(
        "https://im.anshuiwang.com.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=1&groupid=1&special=1"
      );
    },
    orderDetail(e) {
      getorderdetail({ id: e }).then((res) => {
        console.log(res);
      });
    },
  },
  mounted() {
    let id = this.id || this.$store.state.order_id;
    getorderdetail({
      id,
    }).then((res) => {
      this.order_item = res.data.data;
      this.$store.commit("userOrderDetaild", this.order_item);
    });
  },
};
</script>

<style scoped></style>
