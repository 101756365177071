<template>
  <div class="bba jz_flex">
    <div class="ddisp bhh">
      <div class="topdq" style="width: 800px">
        <img :src="$store.state.imgHttp + haiBao" alt="" />
      </div>
      <div class="bor_1_hui topdq ba_white" style="width: 400px; height: 440px">
        <div class="wh_9 jz wz_jz">
          <div v-if="false" class="ddisp wz_jz" style="line-height: 50px">
            <div
              class="xsgb"
              @click="loginIndex = 2"
              :class="loginIndex == 2 ? 'login' : ''"
              style="height: 50px; width: 100px"
            >
              验证码登录
            </div>
            <div
              class="xsgb mag_l-30"
              @click="loginIndex = 1"
              :class="loginIndex == 2 ? '' : 'login'"
              style="height: 50px; width: 100px"
            >
              密码登录
            </div>
          </div>
          <div v-if="loginIndex == 2" class="pad_30_0">
            <div>
              <el-form ref="form" :model="form">
                <el-form-item>
                  <div class="ddisp">
                    <div style="width: 100px">
                      <div
                        class="bor_5"
                        style="width: 80px; background-color: #f5f7f9"
                      >
                        <span>中国+86</span>
                      </div>
                    </div>
                    <div style="width: calc(100% - 100px)">
                      <el-input
                        placeholder="请输入手机号"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                        maxlength="11"
                        v-model="form.account"
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="ddisp">
                    <div style="width: calc(100% - 120px)">
                      <el-input
                        placeholder="请输入验证码"
                        v-model="form.name"
                      ></el-input>
                    </div>
                    <div class="ddisp" style="width: 120px">
                      <el-button v-if="codeChecked" @click="addCode"
                        >获取验证码</el-button
                      >
                      <el-button v-if="codeChecked == false">{{
                        isCode
                      }}</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item class="wz_leftdq">
                  <el-checkbox v-model="checked">记住账号</el-checkbox>
                </el-form-item>
              </el-form>
            </div>
            <div class="ddisp pad_20_0">
              <div
                class="wz_white bor_5 xsgb"
                @click="login(1)"
                style="padding: 10px 70px; background-color: #008080"
              >
                立即登录
              </div>
            </div>
            <div class="pad_20_0">
              <span class="wz_red xsgb" @click="$router.push('userInfo')"
                >免费注册</span
              >
            </div>
          </div>
          <div v-if="loginIndex == 1" class="pad_30_0">
            <el-form ref="form" :model="form_2">
              <el-form-item>
                <el-input
                  placeholder="请输入账号或手机号"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  maxlength="11"
                  v-model="form_2.mobile"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="请输入密码"
                  type="password"
                  maxlength="12"
                  v-model="form_2.password"
                >
                </el-input>
              </el-form-item>
              <el-form-item class="wz_leftdq">
                <el-checkbox v-model="checked">记住账号</el-checkbox>
                <div class="flo_r wz_hui">
                  <span @click="$router.push('forgetPassword')" class="xsgb"
                    >忘记密码</span
                  >
                </div>
              </el-form-item>
            </el-form>
            <div class="ddisp pad_20_0">
              <div
                class="wz_white bor_5 xsgb"
                @click="login(2)"
                style="padding: 10px 70px; background-color: #008080"
              >
                立即登录
              </div>
            </div>
            <div class="pad_20_0">
              <span class="wz_red xsgb" @click="$router.push('userInfo')"
                >免费注册</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLogin,
  getloginCode,
  getloginHaibao,
  getLoginlog,
} from "../../api/api";

import { yt_msg } from "../../utils/message";

export default {
  name: "login",
  data() {
    return {
      loginIndex: 1,
      form: {},
      form_2: {
        mobile: "",
        password: "",
      },
      scene: 2, //1-账号或手机密码登录  2-手机验证码登录
      terminal: 4, //终端 1-pc管理后台;2-手机管理后台  4-PC客户端
      haiBao: "",
      checked: false,
      codeChecked: true, //验证码状态
      isCode: 60,
    };
  },
  methods: {
    // 获取验证码
    addCode() {
      getloginCode({
        mobile: this.form.account,
        key: "YZMDL",
        client: this.terminal,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.msg,
          });
        } else if (res.data.code == 1) {
          this.yanZheng();
        }
      });
    },
    yanZheng() {
      this.isCode = 60; // 设置倒计时初始值为 60 秒
      const codeChecked = setInterval(() => {
        this.isCode--;
        if (this.isCode <= 0) {
          clearInterval(codeChecked); // 清除计时器
          this.buttonDisabled = false; // 启用按钮
        }
      }, 10000);
    },
    login(e) {
      let params = {};
      if (e == 1) {
        (params.account = this.form.mobile),
          (params.password = this.form.password),
          (params.scene = this.loginIndex),
          (params.terminal = 4);
      } else {
        (params.account = this.form_2.mobile),
          (params.password = this.form_2.password),
          (params.scene = this.loginIndex),
          (params.terminal = 4);
      }
      getLogin(params).then((res) => {
        if (res.data.code == 1) {
          yt_msg("登录成功", 1000, true);
          console.log("用户登录数据", res.data.data);
          this.$store.commit("userInfo", res.data.data);
          console.log("用户登录数据", this.$store.state.userItem);
          localStorage.setItem("tokenAnShui", res.data.data.token);
          localStorage.setItem("userInfoAnShui", JSON.stringify(res.data.data));
          this.$store.state.userItem = res.data.data;
          this.$router.push("Index");
        }
      });
    },
    //绑定监听事件
    keyDown(e) {
      //如果是按回车则执行登录方法
      if (e.keyCode == 13) {
        this.login();
      }
    },
    //销毁事件
    dstroyed() {
      window.removeEventListener("keydown", this.keyDown, false);
    },
  },
  mounted() {
    getloginHaibao({ type: 2 }).then((res) => {
      this.haiBao = res.data.data.content.url;
    });
    //绑定事件，其他界面可能要写在methods里
    window.addEventListener("keydown", this.keyDown);

    // getLoginlog().then(res=>{
    //
    // })
  },
  beforeDestroy() {
    this.dstroyed();
  },
};
</script>

<style scoped>
.login {
  border-bottom: 2px solid #008080;
}
/*.bba{*/
/*background-image: url("../../assets/bgg.png");*/
/*  background-size: 100% 100%;*/
/*}*/
</style>
