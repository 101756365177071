<template>
<div>
  <div class="jz pad_b_20" style="width: 1200px">
    <el-tabs class="ba_white pad_20 mag_t-20" v-model="activeName">
      <el-tab-pane label="订单详情" name="1">
        <order_Top></order_Top>
        <div class="ba_white mag_t-20 bor_1_hui pad_20">
          <el-form ref="form" :model="form" size="small" label-width="170px">
            <div class="em" style="padding: 10px 0 10px 20px">个体户开办信息</div>
            <div class="ddisp bor_b_xu split_2">
              <el-form-item label="姓名" prop="user_name">

                <el-input v-model="$store.state.userOrderDetaild.item.user_name" placeholder="请输入姓名" style="width: 300px"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="user_tel">
                <el-input v-model="$store.state.userOrderDetaild.item.user_tel" placeholder="请输入手机号" style="width: 300px"></el-input>
              </el-form-item>
            </div>
            <!--              办理方式-->
            <div class="em" style="padding: 10px 0 10px 20px">选择办理方式</div>
            <div>
              <el-form-item label="办理方式" prop="handle_type">
                <el-radio v-model="form.handle_type" :label="0">线上办理</el-radio>
                <el-radio v-model="form.handle_type" :label="1">线下办理</el-radio>
              </el-form-item>
            </div>
            <!--              票面信息-->
            <div class="em" style="padding: 10px 0 10px 20px">票面信息</div>
            <div>
              <el-form-item label="开票类型">
                <el-radio v-model="form.invoice_type" :label="0">普票</el-radio>
                <el-radio v-model="form.invoice_type" :label="1">专票</el-radio>
              </el-form-item>
              <div class="ddisp">
                <div>
                  <el-form-item label="发票内容" prop="invoice_content">
                    <el-input v-model="form.invoice_content" placeholder="请输入发票内容" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="规格型号" prop="specification">
                    <el-input v-model="form.specification" placeholder="请输入规格型号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="单位" prop="unit_name">
                    <el-input v-model="form.unit_name" placeholder="请输入单位" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="数量" prop="invoice_number">
                    <el-input type="number"
                              oninput="if(value.length > 8) value=value.slice(0, 8)"
                              v-model="form.invoice_number"
                              placeholder="请输入数量"
                              style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="开票金额" prop="invoice_amount">
                    <el-input type="number"
                              oninput="if(value.length > 8) value=value.slice(0, 8)"
                              disabled v-model="form.invoice_amount" placeholder="请输入开票金额" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="金额大写" prop="amount_word">
                    <el-input disabled v-model="form.amount_word"  style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="备注">
                    <el-input v-model="form.remark" placeholder="请输入备注" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="发票邮寄地址" prop="invoice_mailing_addr">
                    <el-input v-model="form.invoice_mailing_addr" placeholder="请输入地址"  style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="电子邮箱" prop="invoice_email">
                    <el-input v-model="form.invoice_email" placeholder="请输入电子邮箱" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="申请人(法人)" prop="applicant">
                    <el-input v-model="form.applicant" placeholder="请输入申请人"  style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div v-if="form.handle_type==0">
              <div>
                <el-form-item label="上传合同" prop="contract_file">
                  <div v-if="this.form.contract_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.contract_file"
                       target="_blank">{{form.contract_file.substring(form.contract_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>

                </el-form-item>
              </div>
              <div>
                <el-form-item label="上传银行流水" prop="bank_statement_file">
                  <div v-if="this.form.bank_statement_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.bank_statement_file"
                       target="_blank">{{form.bank_statement_file.substring(form.bank_statement_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>

                </el-form-item>
              </div>
              <div>
                <el-form-item label="上传成果文件" prop="outcome_file">
                  <div v-if="this.form.outcome_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.outcome_file"
                       target="_blank">{{form.outcome_file.substring(form.outcome_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>

                </el-form-item>
              </div>
              <div>
                <el-form-item label="上传业务相关证据材料" prop="evidence_file">
                  <div v-if="this.form.evidence_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.evidence_file"
                       target="_blank">{{form.evidence_file.substring(form.evidence_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>

                </el-form-item>
              </div>
              <div>
                <el-form-item label="身份证扫描件" prop="identification_file">
                  <div v-if="this.form.identification_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.identification_file"
                       target="_blank">{{form.identification_file.substring(form.identification_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>

                </el-form-item>
              </div>
            </div>

            <!--              开票单位信息-->
            <div>
              <div class="em" style="padding: 10px 0 10px 20px">开票单位信息</div>
              <div class="ddisp">
                <div>
                  <el-form-item label="开票单位" prop="issuing_office">
                    <el-input v-model="form.issuing_office" placeholder="请输入开票单位" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="税号" prop="issuing_office_tax">
                    <el-input v-model="form.issuing_office_tax" placeholder="请输入税号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="地址">
                    <el-input v-model="form.issuing_office_addr" placeholder="请输入地址" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="电话" prop="issuing_office_tel">
                    <el-input type="number"
                              oninput="if(value.length > 11) value=value.slice(0, 11)"
                              v-model="form.issuing_office_tel" placeholder="请输入电话" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="银行开户号">
                    <el-input v-model="form.issuing_office_bank" placeholder="请输入银行开户号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="银行账号" prop="issuing_office_bank_card">
                    <el-input v-model="form.issuing_office_bank_card" placeholder="请输入银行账号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <!--                收票单位信息-->
              <div class="em" style="padding: 10px 0 10px 20px">收票单位信息</div>
              <div class="ddisp">
                <div>
                  <el-form-item label="收票单位" prop="receive_office">
                    <el-input v-model="form.receive_office" placeholder="请输入收票单位" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="税号" prop="receive_office_tax">
                    <el-input v-model="form.receive_office_tax" placeholder="请输入税号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="地址">
                    <el-input v-model="form.receive_office_addr" placeholder="请输入地址" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="电话">
                    <el-input type="number"
                              oninput="if(value.length > 11) value=value.slice(0, 11)"
                              v-model="form.receive_office_tel" placeholder="请输入电话" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="银行开户号">
                    <el-input v-model="form.receive_office_bank" placeholder="请输入银行开户号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="银行账号">
                    <el-input v-model="form.receive_office_bank_card" placeholder="请输入银行账号" style="width: 300px"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="ba_white mag_t-20 bor_1_hui pad_20">
          <el-form ref="form" :model="form" size="small" label-width="170px">
            <div class="em" style="padding: 10px 0 10px 20px">发票信息</div>
            <div class="ddisp split_2">
              <el-form-item label="备注" prop="user_name">
                <span>{{form.invoicing_remark}}</span>
              </el-form-item>
            </div>
            <div class="ddisp split_2">
              <el-form-item label="发票附件" prop="user_tel">
                <div v-if="this.form.invoicing_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.invoicing_file"
                     target="_blank">{{form.invoicing_file.substring(form.invoicing_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
            <div class="ddisp split_2">
              <el-form-item label="发票链接" prop="user_tel">
                <span class="wz_blue">{{form.invoicing_url}}</span>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="wz_rigdq pad_t_20 bor_t_hui">
          <el-button @click="$router.back()" type="primary" style="width: 120px">返回</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="流程日志" name="2">
        <log></log>
      </el-tab-pane>
    </el-tabs>

  </div>
<!--  {{$store.state.order_id}}-->
</div>
</template>

<script>
export default {
  name: "gthkpDetail",
  data(){
    return{
      form:this.$store.state.userOrderDetaild.list,
      activeName:"1",
      handle_type:null
    }
  },
  mounted() {
    this.handle_type = this.form.handle_type
    // console.log(this.$store.state.order_id)
  }
}
</script>

<style scoped>

</style>