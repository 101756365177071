// import request from "/src/utils/test";
import request from "/src/utils/request";
// 按需导出每个api请求

/*获取商品*/
export function getYewuOrderList(data) {
  return request.get("goods/lists", data);
}
/*获取商品详情*/

export function getshopDetail(params) {
  return request.get("pc/goodsDetail", { params: params });
}
