<template>
<div class="jz pad_b_20" style="width: 1200px">
  <el-tabs class="ba_white pad_20 mag_t-20" v-model="activeName">
    <el-tab-pane label="订单详情" name="1">
      <order_Top></order_Top>
      <el-form ref="form" :model="form" size="small" label-width="160px" :rules="rules" >
        <div class="ba_white mag_t-20 bor_1_hui pad_20">
          <div class="em" style="padding: 10px 0 10px 20px">自然人信息</div>
          <div class="ddisp bor_b_xu split_2">
            <el-form-item label="姓名" prop="user_name">
              <span class="tabSpan">{{$store.state.userOrderDetaild.item.user_tel}}</span>
            </el-form-item>
            <el-form-item label="手机号" prop="user_tel">
              <span class="tabSpan">{{$store.state.userOrderDetaild.item.user_tel}}</span>
<!--              <el-input v-model="$store.state.userOrderDetaild.item.user_tel" placeholder="请输入手机号" style="width: 300px"></el-input>-->
            </el-form-item>
          </div>
          <!--              代开票资料-->
          <div class="em" style="padding: 10px 0 10px 20px">代开票资料</div>
          <div>
            <div class="ddisp">
              <div>
                <el-form-item label="发票类目" prop="invoice_category">
                  <span class="tabSpan">{{form.invoice_category}}</span>
<!--                  <el-input v-model="form.invoice_category" placeholder="请输入发票类目" style="width: 300px"></el-input>-->
                </el-form-item>
              </div>
              <div>
                <el-form-item label="商品编号" prop="item_name">
                  <span class="tabSpan">{{form.item_name}}</span>
<!--                  <el-input v-model="form.item_name" placeholder="请输入商品编号" style="width: 300px"></el-input>-->
                </el-form-item>
              </div>
            </div>
            <div class="ddisp">
              <div>
                <el-form-item label="企业名称" prop="enterprise_name">
                  <span class="tabSpan">{{form.enterprise_name}}</span>
<!--                  <el-input v-model="form.enterprise_name" placeholder="请输入企业名称" style="width: 300px"></el-input>-->
                </el-form-item>
              </div>
              <div>
                <el-form-item label="企业税号" prop="enterprise_tax">
                  <span class="tabSpan">{{form.enterprise_tax}}</span>
<!--                  <el-input v-model="form.enterprise_tax" placeholder="请输入企业税号" style="width: 300px"></el-input>-->
                </el-form-item>
              </div>
            </div>
            <div class="ddisp">
              <div>
                <el-form-item label="企业地址">
                  <span class="tabSpan">{{form.enterprise_addr}}</span>
<!--                  <el-input v-model="form.enterprise_addr" placeholder="请输入企业地址" style="width: 300px"></el-input>-->
                </el-form-item>
              </div>
              <div>
                <el-form-item label="企业电话">
                  <span class="tabSpan">{{form.enterprise_tel}}</span>
<!--                  <el-input type="number"-->
<!--                            oninput="if(value.length > 11) value=value.slice(0, 11)"-->
<!--                            v-model="form.enterprise_tel" placeholder="请输入企业电话" style="width: 300px"></el-input>-->
                </el-form-item>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="企业开户行">
                  <span class="tabSpan">{{form.enterprise_bank}}</span>
<!--                    <el-input v-model="form.enterprise_bank" placeholder="请输入企业开户行" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="企业开户账号">
                  <span class="tabSpan">{{form.enterprise_bank_card}}</span>
<!--                    <el-input v-model="form.enterprise_bank_card" placeholder="请输入企业开户账号" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="开票总额" prop="invoice_max">
                  <span class="tabSpan">{{form.invoice_max}}</span>
<!--                    <el-input v-model="form.invoice_max" placeholder="请输入开票总额" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="单价">
                  <span class="tabSpan">{{form.unit_price}}</span>
<!--                    <el-input type="number"-->
<!--                              oninput="if(value.length > 8) value=value.slice(0, 8)"-->
<!--                              @input="inputUpda" v-model="form.unit_price"-->
<!--                              placeholder="请输入开票金额" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="数量">
                  <span class="tabSpan">{{form.invoice_count}}</span>
<!--                    <el-input v-model="form.invoice_count" placeholder="请输入数量" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="单位">
                  <span class="tabSpan">{{form.unit_name}}</span>
<!--                    <el-input v-model="form.unit_name"  placeholder="请输入单位" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="规格">
                  <span class="tabSpan">{{form.specification}}</span>
<!--                    <el-input v-model="form.specification" placeholder="请输入规格" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="身份证号" prop="identification">
                  <span class="tabSpan">{{form.identification}}</span>
<!--                    <el-input v-model="form.identification" placeholder="请输入身份证号"  style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
              </div>
              <div class="ddisp">
                <div>
                  <el-form-item label="自然人银行卡号">
                  <span class="tabSpan">{{form.person_bank_card}}</span>
<!--                    <el-input v-model="form.person_bank_card" placeholder="请输入自然人银行卡号" style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="备注">
                  <span class="tabSpan">{{form.remark}}</span>
<!--                    <el-input v-model="form.remark" placeholder="请输入备注"  style="width: 300px"></el-input>-->
                  </el-form-item>
                </div>
              </div>
            </div>
            <!--              上传资料文件-->
            <div class="em" style="padding: 10px 0 10px 20px">上传资料文件</div>
            <div>
              <el-form-item label="上传身份证扫描件" prop="identification_file">
                <div v-if="this.form.identification_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.identification_file"
                     target="_blank">{{form.identification_file.substring(form.identification_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="上传合同" prop="contract_file">
                <div v-if="this.form.contract_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.contract_file"
                     target="_blank">{{form.contract_file.substring(form.contract_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="上传银行流水" prop="bank_statement_file">
                <div v-if="this.form.bank_statement_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.bank_statement_file"
                     target="_blank">{{form.bank_statement_file.substring(form.bank_statement_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="上传成果文件" prop="outcome_file">
                <div v-if="this.form.outcome_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.outcome_file"
                     target="_blank">{{form.outcome_file.substring(form.outcome_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="上传业务相关证据材料" prop="evidence_file">
                <div v-if="this.form.evidence_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.evidence_file"
                     target="_blank">{{form.evidence_file.substring(form.evidence_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="ba_white mag_t-20 bor_1_hui pad_20">
          <div class="em" style="padding: 10px 0 10px 20px">审核信息</div>
          <div>
            <el-form-item label="审核意见" prop="user_name">
              <span>{{form.review_comments}}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="附件" prop="user_tel">
              <div v-if="form.review_file">
                <a class="wz_blue mag_0_20"
                   download="扫描件.pdf"
                   :href="this.$store.state.httpsDown+this.form.review_file"
                   target="_blank">{{form.review_file.substring(form.review_file.lastIndexOf("/") + 1)}}  ↓</a>
              </div>

            </el-form-item>
          </div>
        </div>

        <div class="ba_white mag_t-20 bor_1_hui pad_20">
          <div class="em" style="padding: 10px 0 10px 20px">发票信息</div>
          <div>
            <el-form-item label="备注" prop="user_name">
              <span>{{form.invoicing_remark}}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="发票附件" prop="user_tel">
              <div v-if="form.invoicing_file">
                <a class="wz_blue mag_0_20"
                   download="扫描件.pdf"
                   :href="this.$store.state.httpsDown+this.form.invoicing_file"
                   target="_blank">{{form.invoicing_file.substring(form.invoicing_file.lastIndexOf("/") + 1)}}  ↓</a>
              </div>

            </el-form-item>
          </div>
          <div>
            <el-form-item label="发票链接" prop="user_tel">
              <span class="wz_blue">{{form.invoicing_url}}</span>
            </el-form-item>
          </div>
        </div>

        <div class="wz_rigdq pad_t_20 bor_t_hui">
          <el-button @click="$router.back()" type="primary" style="width: 120px">返回</el-button>
        </div>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="流程日志" name="2">
      <log></log>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
export default {
  name: "zrrdkpDetail",
  data(){
    return{
      form:this.$store.state.userOrderDetaild.list,
      activeName:"1",
    }
  },
  mounted() {
    console.log(this.form)
  }
}
</script>

<style scoped>

.tabSpan{
  width: 300px;
  display: block;
}
</style>