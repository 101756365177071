import Vue from "vue";
import Vuex from "vuex";
import {} from "../api/api";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    num: 2,
    imgHttp: "https://api.anshuiwang.com.cn/",
    audioHttp: "https://api.anshuiwang.com.cn/shopapi/upload/video", //单视频上传地址
    address: "https://api.anshuiwang.com.cn/shopapi/upload/image", //单图片上传的地址
    userItem: {},
    uploadPdf: "https://api.anshuiwang.com.cn/shopapi/upload/image",
    fits: "fill",
    mbx: [], //面包屑
    orderAct: 1,
    Fuck: {},
    msg: "",
    goodsDetails: {}, //商品数据
    good: {}, //下单页面的商品数据 包含下单数量
    goodDetailId: null, //商品详情页的id
    yewuId: null, //业务中心需要查询的id,
    leftMenuId: 2, // 用户中心的侧边导航栏id
    order_id: null,
    userOrderDetaild: {},
    caseList: {}, //政法法规详情数据
    httpsDown: "https://api.anshuiwang.com.cn/", //下载的域名
    isCase: 0,
    payList: [],
    userInfo: {},
  }, // 存放数据
  getters: {
    userInfo(state) {
      // 从来本地缓存获取
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo) {
        return userInfo;
      }

      return userInfo;
    },
  }, // 计算属性
  mutations: {
    //商品详情数据
    setGoodsDetails(state, data) {
      state.goodsDetails = data;
    },
    //区分政策法规
    isCase(state, data) {
      state.isCase = data;
    },
    //商品详情数据
    userOrderDetaild(state, data) {
      state.userOrderDetaild = data;
    },
    //用户数据
    userInfo(state, data) {
      console.log(!!state.userInfo);
      // 储存到本地
      localStorage.setItem("userInfo", JSON.stringify(data));
      state.userInfo = data;
    },
    //政策法规详情数据
    caseList(state, data) {
      state.caseList = data;
    },
    //用于显示支付成功后的订单信息
    good(state, data) {
      state.good = data;
    },
    //用于提交订单存储返回的订单id 然后去查询订单信息
    order_id(state, data) {
      state.order_id = data;
    },
    //商品详情数据
    // goods_detail(state, data) {
    //     state.goods_detail = data;
    // },
  }, // 修改state中数据的一些方法
  actions: {}, // 异步方法
  modules: {}, // store模块
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
export default store;
