<template>
  <div style="padding: 150px 0">
    <div style="width: 1200px" class="jz bor_10 ba_white">
      <div class="wz_jz fz_20 em pad_30_0">
        忘记密码
      </div>
      <el-form ref="form" :model="form" style="width: 500px" class="jz">
        <el-form-item>
          <div style="padding: 20px 0 0">
            <div>
              <el-input placeholder="请输入账号" maxlength="11" v-model="form.account"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="ddisp">
            <div style="width: calc(100% - 140px)">
              <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
            </div>
            <div class="ddisp wz_rigdq" style="width: 140px">
              <el-button v-if="codeChecked" @click="addCode">获取验证码</el-button>
              <el-button v-if="codeChecked==false">{{ isCode }}</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入新密码"
                    type="password"
                    maxlength="11" v-model="form.password">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请再次输入新密码"
                    type="password"
                    maxlength="11" v-model="form.password_confirm">
          </el-input>
        </el-form-item>
        <el-form-item class="wz_leftdq">
          <el-checkbox v-model="checked">已阅读同意
            <span class="wz_red">
            <span @click="xieyi(1)">《服务协议》</span>
            <span>、</span>
            <span @click="xieyi(2)">《隐私政策》</span>
          </span>
          </el-checkbox>
        </el-form-item>
        <div class="ddisp pad_10_0 wz_jz">
          <el-button :disabled="!checked" type="primary"
                     class="wz_white bor_5 xsgb"
                     @click="go"
                     style="padding: 10px 70px;">确定</el-button>
<!--          <div class="wz_white bor_5 xsgb" @click="go" style="padding: 10px 70px;background-color: #008080">确定</div>-->
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getloginCode, getUpdaPassword, getuserInfo} from "../../api/api";

export default {
  name: "forgetPassword",
  data(){
    return{
      form:{
        register_source:4
      },
      checked:false,
      codeChecked:true,
      isCode:60
    }
  },
  methods:{

    xieyi(e){
      this.$router.push({
        path:'agreement',
        query:{uid:e}
      })
    },
    go(){
      if(this.form.password==this.form.password_confirm){
        getUpdaPassword({
          // old_password:this.form.mobile,
          // // code:this.form.code,
          // password:this.form.password,
          // confirm_password:this.form.password_confirm,
          code:this.form.code,
          mobile:this.form.account,
          account:this.form.account,
          password:this.form.password,
        }).then(res=>{
          this.$router.push('login')
        })
      }else{
        this.$message.error('两次输入的密码不一样!');
      }

    },
    // 获取验证码
    addCode(){
      getloginCode({
        mobile:this.form.account,
        // key:"YZMDL",
        // client:this.terminal
      }).then(res=>{
        if (res.data.code==0) {
          this.$notify.error({
            title: '错误',
            message: res.data.msg
          });
        }else if (res.data.code==1){
          this.yanZheng()
        }
      })
    },
    yanZheng(){
      this.codeChecked = false;
      this.isCode = 60;  // 设置倒计时初始值为 60 秒
      const codeChecked = setInterval(() => {
        this.isCode--;
        if (this.isCode <= 0) {
          clearInterval(codeChecked);  // 清除计时器
          this.codeChecked = true;  // 启用按钮
        }
      }, 1000);
    }
  }
}
</script>

<style scoped>

</style>