import axios from "axios";
import router from "../router";
import { Loading } from "element-ui";
import { yt_msg } from "./message";
let loading;
//创建一个单例（实例） 运行axios的create方法
const instance = axios.create({
  // 配置url
  baseURL: "https://api.anshuiwang.com.cn/shopapi/",
  // 超时时间
  timeout: 15000,
});
instance.interceptors.request.use(
  (config) => {
    config.headers = {
      version: 1,
      token: localStorage.getItem("tokenAnShui"),
    };

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 拦截器-响应拦截
instance.interceptors.response.use(
  (res) => {
    const { data, msg, code } = res.data;
    // 检查返回数据中的 code 值
    if (res.data.code == -1) {
      router.push("login");
    }
    if (code == -1 && msg == "登录超时，请重新登录") {
      // 清除token
      localStorage.removeItem("tokenAnShui");
    }
    if (code == -1 || code == 0) {
      yt_msg(msg);
    }
    return res;
  },
  (err) => {
    loading.close();
    yt_msg(err);
    return Promise.reject(err);
  }
);

// 整体导出
export default instance;
