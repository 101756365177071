<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18 em">上传个体户开办资料</div>
      <TopActive></TopActive>
      <div>
        <div class="ba_white mag_t-30">
          <div class="pad_20">
            <el-form
              ref="form"
              :model="form"
              size="small"
              label-width="140px"
              :rules="rules"
            >
              <div class="em" style="padding: 10px 0 10px 20px">
                个体户开办信息
              </div>

              <div class="ddisp bor_b_xu split_2">
                <el-form-item label="姓名" prop="user_name">
                  <el-input
                    v-model="form.user_name"
                    placeholder="请输入姓名"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="手机号" prop="user_tel">
                  <el-input
                    placeholder="请输入手机号"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    maxlength="11"
                    v-model="form.user_tel"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
              <!--              拟注册信息-->
              <div class="em" style="padding: 10px 0 10px 20px">拟注册信息</div>
              <div>
                <el-form-item label="企业名称(1)" prop="enterprise_name_one">
                  <el-input
                    v-model="form.enterprise_name_one"
                    placeholder="请输入准备取的企业名称"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="企业名称(2)" prop="enterprise_name_two">
                  <el-input
                    v-model="form.enterprise_name_two"
                    placeholder="请输入准备取的企业名称"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="企业名称(3)" prop="enterprise_name_three">
                  <el-input
                    v-model="form.enterprise_name_three"
                    placeholder="请输入准备取的企业名称"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="企业名称(4)" prop="enterprise_name_four">
                  <el-input
                    v-model="form.enterprise_name_four"
                    placeholder="请输入准备取的企业名称"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>

                <el-form-item label="经营范围" prop="business_scope">
                  <el-input
                    type="textarea"
                    resize="none"
                    placeholder="请输入经营范围"
                    v-model="form.business_scope"
                    style="width: 700px"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="注册资本" prop="registered_capital">
                  <el-input
                    type="number"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    maxlength="9"
                    placeholder="请输入内容"
                    v-model="form.registered_capital"
                    style="width: 300px"
                  >
                    <template slot="append">万</template>
                  </el-input>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="form.remark"
                    placeholder="请输入备注"
                    style="width: 740px"
                  ></el-input>
                </el-form-item>
              </div>
              <!--              法人及企业信息-->
              <div>
                <div class="em" style="padding: 10px 0 10px 20px">
                  法人及企业信息
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="法人姓名" prop="corporation_name">
                      <el-input
                        v-model="form.corporation_name"
                        placeholder="请输入法人姓名"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="实名手机号" prop="corporation_tel">
                      <el-input
                        v-model="form.corporation_tel"
                        placeholder="请输入实名手机号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item
                      label="身份证号"
                      prop="corporation_identification"
                    >
                      <el-input
                        v-model="form.corporation_identification"
                        placeholder="请输入身份证号"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="企业住所" prop="enterprise_address">
                      <el-input
                        v-model="form.enterprise_address"
                        placeholder="请输入企业住所"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="邮箱">
                  <el-input
                    v-model="form.email"
                    placeholder="请输入邮箱"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
              <!--              发票账户信息-->
              <div>
                <div class="em" style="padding: 10px 0 10px 20px">
                  发票账户信息
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="发票类型">
                      <el-select
                        v-model="form.invoice_type"
                        placeholder="请选择"
                        style="width: 300px"
                      >
                        <el-option
                          v-for="item in fapiaoleixing"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="发票版位">
                      <el-input
                        v-model="form.invoice_max"
                        placeholder="请输入发票版位"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="拟申请数量">
                      <el-input
                        type="number"
                        v-model="form.quantity"
                        placeholder="请输入申请数量"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="第一主营业务">
                      <el-input
                        v-model="form.first_business"
                        placeholder="或开票内容"
                        style="width: 300px"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="服务事项">
                  <el-checkbox-group v-model="service_item">
                    <el-checkbox v label="工商设立"></el-checkbox>
                    <el-checkbox label="刻章"></el-checkbox>
                    <el-checkbox label="税务报道"></el-checkbox>
                    <el-checkbox label="申请发票"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>

              <div class="em" style="padding: 10px 0 10px 20px">
                上传文件资料
              </div>
              <div>
                <el-form-item label="上传身份证扫描件" prop="id_card_img">
                  <img
                    v-if="form.id_card_img"
                    class="topdq disp"
                    :src="$store.state.httpsDown + form.id_card_img"
                    width="148"
                    height="148"
                    alt=""
                    style="z-index: 10"
                  />

                  <div class="disp">
                    <inputUpload
                      class="mag_l-20 disb"
                      @upload="Touxiang"
                    ></inputUpload>
                    <el-button
                      class="disb"
                      @click="getZLListFn"
                      type="primary"
                      style="background-color: #02bd6e; margin: 10px 0 0 20px"
                      >从资料库中选择</el-button
                    >
                  </div>
                </el-form-item>
              </div>
              <div class="wh_f pad_20">
                <div class="flex_1"></div>
                <div class="flo_r fz_14">
                  <div class="wz_rigdq" style="padding: 50px 0 20px">
                    <el-button
                      @click="$router.go(-1)"
                      type=""
                      style="width: 150px"
                      >返回</el-button
                    >

                    <el-button
                      @click="addHeYan"
                      type="primary"
                      style="width: 150px"
                      >提交</el-button
                    >
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>

      <div v-if="ZLShow">
        <el-dialog :visible.sync="ZLShow" title="在资料库中选择" width="50%">
          <div>
            <hr />
            <el-table :data="ZLList" style="width: 100%">
              <el-table-column width="40">
                <template slot-scope="scope">
                  <el-radio
                    class="radio"
                    :label="scope.row"
                    v-model="radio"
                    @change.native="getCurrentRow(scope.row)"
                    >&nbsp;</el-radio
                  >
                </template>
              </el-table-column>
              <el-table-column label="用户头像">
                <template slot-scope="scope">
                  <div class="flex">
                    <el-image
                      :src="$store.state.httpsDown + scope.row.info_path"
                      style="width: 34px; height: 34px"
                    ></el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="info_title" label="资料名称" width="180">
              </el-table-column>
            </el-table>
          </div>
          <div v-if="ZLShow">
            <el-pagination
              class="wz_jz"
              @size-change="SizeChange"
              @current-change="CurrentChange"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="count"
              style="margin: 20px 0"
            >
            </el-pagination>
          </div>
          <div class="wz_rigdq" style="margin-top: 10px">
            <el-button size="small" @click="ZLShow = false">关 闭</el-button>
            <el-button size="small" type="primary" @click="addZl"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { getjgthkb_Add, getZLList, addZL } from "../../api/api";

export default {
  name: "scjdbg",
  data() {
    return {
      isCar: true,
      service_item: [],
      // 发票类型
      fapiaoleixing: [
        {
          value: 1,
          label: "增值税普通发票",
        },
        {
          value: 2,
          label: "增值税专用发票",
        },
        {
          value: 3,
          label: "其他",
        },
      ],
      list: {
        active: 2,
        order: [
          "下单支付费用",
          "上传开办资料",
          "资料预审核",
          "等待办结",
          "税务认证",
        ],
      },
      lab: [
        { label: "工商设立" },
        { label: "刻章" },
        { label: "税务报道" },
        { label: "申请发票" },
      ],
      rules: {
        user_name: [
          { required: true, min: 2, message: "请输入姓名", trigger: "blur" },
        ],
        姓名: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        user_tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        corporation_tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        enterprise_name_one: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        enterprise_name_two: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        business_scope: [
          { required: true, message: "请输入经营范围", trigger: "blur" },
        ],
        registered_capital: [
          { required: true, message: "请输入注册资本", trigger: "blur" },
        ],
        id_card_img: [
          { required: true, message: "请上传身份证扫描件", trigger: "blur" },
        ],
        corporation_name: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        corporation_identification: [
          { required: true, message: "请输入法人身份证号", trigger: "blur" },
        ],
        enterprise_address: [
          { required: true, message: "请输入企业住所", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" },
        ],
        demo: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      form: {
        sn: this.$store.state.userOrderDetaild.sn,
        user_name: this.$store.state.userOrderDetaild.user_name,
        user_tel: this.$store.state.userOrderDetaild.user_tel,
        enterprise_name_one: "",
        enterprise_name_two: "",
        enterprise_name_three: "",
        enterprise_name_four: "",
        business_scope: "",
        registered_capital: "",
        corporation_name: "",
        corporation_tel: "",
        corporation_identification: "",
        enterprise_address: "",
        remark: "",
        email: "",
        invoice_type: "",
        invoice_max: "",
        quantity: "",
        first_business: "",
        service_item: ["工商设立"],
        id_card_img: "",
        order_id: this.$store.state.userOrderDetaild.id,
      },
      uploadedFile: null,
      ZLShow: false,
      ZLList: [],
      ZL: {},
      radio: "",
      count: null,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  methods: {
    SizeChange(newSize) {
      this.pageSize = newSize;
      this.addZl(this.pageIndex, this.pageSize);
    },
    // 分页钩子函数
    CurrentChange(newPage) {
      this.pageIndex = newPage;
      this.addZl(this.pageIndex, this.pageSize);
    },
    addZl() {
      this.isCar = false;
      this.form.id_card_img = this.ZL.info_path;
      this.ZLShow = false;
    },
    // 单选框选中数据
    getCurrentRow(val) {
      this.ZL = val;
    },
    Touxiang(e) {
      console.log(e.response);
      this.form.id_card_img = e.response.data.url;
    },
    hand(file) {
      this.uploadedFile = file.response.data;
      console.log(this.uploadedFile);
    },
    getZLListFn() {
      let { userInfo } = this.$store.state;
      let { id: nid } = userInfo;
      let {
        __ob__: {
          dep: { id: xid },
        },
      } = userInfo;
      let id = nid || xid;

      getZLList({
        user_id: id,
      }).then((res) => {
        this.count = res.data.data.count;
        this.ZLList = res.data.data.lists;
        this.ZLShow = true;
      });
    },
    /**
     * 记录到资料库
     */
    async uploadToLibrary(uri) {
      // 获取文件名字
      let info_title = uri.replace(/^.*[\\/]/, "");
      let id = this.$store.state.userItem.id;
      addZL({
        info_path: uri,
        info_title,
        user_id: id,
      }).then((e) => {
        console.log(e);
      });
    },
    addHeYan() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.subForm();
      });
    },
    subForm() {
      const result = this.service_item.join(", ");
      this.form.service_item = result;
      console.log(this.form.service_item);

      getjgthkb_Add(this.form).then(async (res) => {
        if (res.data.code == 1) {
          await this.uploadToLibrary(this.form.id_card_img);
          this.$message({
            message: "提交成功!",
            type: "success",
          });
          this.$router.push("yeWuIndex");
        }
      });
    },
  },
};
</script>

<style scoped>
.el-dialog__body {
  padding: 0 !important;
}
</style>
