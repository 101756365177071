<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18">业务下单2</div>
      <div class="ba_white pad_50_0">
        <el-steps :active="active" align-center>
          <el-step
            v-for="(item, index) in order"
            :key="index"
            :title="item"
          ></el-step>
        </el-steps>
      </div>
      <div>
        <div class="ba_white mag_t-30">
          <div class="">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
              <div class="em" style="padding: 10px 0 0 20px">基本信息</div>
              <div class="ddisp bor_b_xu pad_20 split_2">
                <el-form-item label="姓名" prop="手机号">
                  <el-input v-model="form.name" style="width: 300px"></el-input>
                </el-form-item>
              </div>
              <div class="em" style="padding: 10px 0 0 20px">订单信息</div>
              <div class="wh_f bor_b_hui pad_20">
                <div class="flex_1">
                  <div class="pad_10_0 wz_hui fz_14">业务信息</div>
                  <div class="ddisp">
                    <div class="topdq">
                      <img
                        src="../../assets/用户头像.jpg"
                        style="width: 80px"
                        alt=""
                      />
                    </div>
                    <div class="topdq pad_l_5 boxs">
                      <p class="em">数字化系统报告</p>
                      <p class="wz_hui pad_10_0 fz_14">默认 (此为规格信息)</p>
                    </div>
                  </div>
                </div>
                <div class="flex_1 wh_f fz_14 wz_hui">
                  <div class="flex_1">
                    订单总价
                    <div class="wz_black pad_10_0">￥999</div>
                  </div>
                  <div class="flex_1">
                    订单状态
                    <div class="wz_black pad_10_0">888</div>
                  </div>
                  <div class="flex_1">
                    下单留言
                    <div class="pad_10_0 wz_hui">777</div>
                  </div>
                </div>
              </div>
              <div class="wh_f pad_20">
                <div class="flex_1"></div>
                <div class="flo_r fz_14">
                  <div class="wz_rigdq" style="padding: 50px 0 20px">
                    <span
                      @click="addHeYan"
                      class="wz_white bor_3 xsgb"
                      style="padding: 20px 30px; background-color: #d9001b"
                      >提交核验</span
                    >
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fxjd",
  data() {
    return {
      active: 1,
      order: ["下单支付费用", "上传需分析解读的报告", "获取解读报告"],
      form: {},
    };
  },
};
</script>

<style scoped></style>
