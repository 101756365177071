<template>
  <div>
    <div class="ddisp">
      <div class="centerdq mag_l-10">
        <el-upload
            list-type="picture-card"
            :headers="head"
            :action="this.$store.state.address"
            :file-list="fileList"
            :on-success="handleUploadSuccess">
          <i slot="default" class="el-icon-plus"></i>
<!--          <div slot="file" slot-scope="{file}" v-if="file.response?.data.uri">-->
<!--            <img-->
<!--                class="el-upload-list__item-thumbnail"-->
<!--                :src="file.response?.data.uri || ''" alt=""-->
<!--            >-->
<!--            <span class="el-upload-list__item-actions">-->
<!--            <span-->
<!--                class="el-upload-list__item-delete"-->
<!--                @click="handleRemove(file)"-->
<!--            >-->
<!--              <i class="el-icon-delete"></i>-->
<!--            </span>-->
<!--          </span>-->
<!--          </div>-->
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImgUpload",
  props: {
    imgUrl: {
      type: String,
      required: false
    }
  },
  data(){
    return{
      // form_1:{
      //
      // },
      fileList:[],
      蒙层:0,
      head:{
        version:1,
        token:localStorage.getItem('tokenAnShui')
      },
    }
  },
  mounted() {
    if((this.imgUrl || "") != ""){
      this.fileList.push({
        uid: 0,
        response:{
          data:{
            uri: this.$store.state.imgHttp + this.imgUrl,
            url: this.imgUrl
          }
        }
      })
    }
  },
  methods:{
    // 上传图片钩子
    handleUploadSuccess(res, file, fileList){
      if (res.code == -1){
        this.$message.error(res.msg);
        this.$router.push('login')
      }else{
        this.fileList = [file]
        this.$emit('Value', res.data.url);
      }
    },
    // 删除图片
    handleRemove(file){
      this.fileList.splice(this.fileList.findIndex(item=>item.uid==file.uid), 1)
    },
  }
}
</script>

<style>
.sbyr:hover i{
  color: red!important;
}
</style>