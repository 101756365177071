<template>

  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>编辑个人资料</span>
      </div>
      <div>
        <el-form ref="form" size="small" :model="form" label-width="80px"
                 style="width: 100%">
          <el-form-item label="用户头像">
            <img v-if="isShow"
                 class="disp centerdq bor_5"
                 :src="avatar"
                 style="width: 148px;height: 148px;"
                 alt="">
            <ImgUpload class="disp centerdq ddisp" @Value="Touxiang"></ImgUpload>
          </el-form-item>
          <el-form-item label="用户名称">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input disabled v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="登录密码">
            <el-button @click="$router.push('updaPassword')" type="primary">修改密码</el-button>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flo_r pad_20" style="width: 300px">
        <el-button size="small" @click="add" type="primary">确定</el-button>
        <el-button size="small" @click="$router.back()">取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {getUser, getUserInfo} from "../../api/api";

export default {
  name: "updaUser",
  data(){
    return{
      form:{
        address:''
      },
      avatar:"",
      isShow:false
    }
  },
  methods:{
    Touxiang(e){
      this.form.avatar=e
      this.isShow=false
    },
    add(){
      this.form.mobile=''
      getUserInfo(this.form).then(res=>{
        if(res.data.code==1){
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
        }
      })
    }
  },
  created() {
    this.$store.state.leftMenuId = 3
  },
  mounted() {
    // 请求用户资料
    getUser().then(res=>{
      this.form =res.data.data
      this.avatar =res.data.data.avatar
      if (this.form.avatar!=''){
        this.isShow=true
      }
    })
    // this.form =JSON.parse(localStorage.getItem('userInfoAnShui'))
  }
}
</script>

<style scoped>

</style>