<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <!--      下单界面-->
      <div v-if="root == 1">
        <div class="pad_30_0 fz_18">业务下单</div>
        <div class="ba_white pad_50_0">
          <el-steps :active="active" align-center>
            <el-step
              v-for="(item, index) in order"
              :key="index"
              :title="item.label"
            ></el-step>
          </el-steps>
        </div>
        <div v-if="active == 1">
          <div class="ba_white mag_t-30">
            <div class="">
              <el-form
                ref="form"
                :model="form"
                label-width="80px"
                :rules="rules"
              >
                <div class="em" style="padding: 10px 0 0 20px">基本信息</div>

                <div
                  class="ddisp bor_b_xu pad_20 split_2"
                  v-if="goods.list.order_type != 4"
                >
                  <el-form-item label="姓名" prop="user_name">
                    <el-input
                      v-model="form.user_name"
                      style="width: 300px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="手机号" prop="user_tel">
                    <el-input
                      v-model="form.user_tel"
                      style="width: 300px"
                    ></el-input>
                  </el-form-item>
                </div>

                <!-- 批量下单 -->
                <div
                  class="ddisp bor_b_xu pad_20 split_4 Items"
                  v-for="(item, index) in form.formItems"
                  :key="index"
                  v-else
                >
                  <el-form-item
                    :label="'姓名' + (index + 1)"
                    :prop="'formItems.' + index + '.user_name'"
                    :rules="rules.user_name"
                  >
                    <el-input
                      v-model="item.user_name"
                      style="width: 100px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="'手机号' + (index + 1)"
                    :prop="'formItems.' + index + '.user_tel'"
                    :rules="rules.user_tel"
                  >
                    <el-input
                      v-model="item.user_tel"
                      style="width: 280px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item :label="'下单备注' + (index + 1)">
                    <el-input
                      v-model="item.baining_remark"
                      style="width: 360px; margin-left: 5px"
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="em" style="padding: 10px 0 0 20px">业务信息</div>
                <div class="wh_f bor_b_hui pad_20">
                  <div class="flex_1">
                    <div class="pad_10_0 wz_hui fz_14">业务信息</div>
                    <div class="ddisp">
                      <div class="topdq">
                        <img
                          :src="goods.list.image"
                          style="width: 80px; max-height: 80px"
                          alt=""
                        />
                      </div>
                      <div class="topdq pad_l_5 boxs">
                        <p class="em">{{ goods.list.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex_1 wh_f fz_14 wz_hui wz_jz">
                    <div v-if="goods.list.is_set_price == 1" class="flex_1">
                      单价
                      <div class="wz_black pad_10_0">
                        {{ goods.list.sell_price }}
                      </div>
                    </div>
                    <div v-if="goods.list.is_set_price == 0" class="flex_1">
                      单价
                      <div class="wz_black w pad_10_0">待报价</div>
                    </div>
                    <div class="flex_1">
                      数量
                      <div class="wz_black pad_10_0">
                        {{ goods.num * form.formItems.length }}
                      </div>
                    </div>
                    <div v-if="goods.list.is_set_price == 1" class="flex_1">
                      合计
                      <div class="wz_black pad_10_0">
                        {{
                          goods.list.sell_price *
                          goods.num *
                          form.formItems.length
                        }}
                      </div>
                    </div>
                    <div v-if="goods.list.is_set_price == 0" class="flex_1">
                      合计
                      <div class="wz_black pad_10_0">-</div>
                    </div>
                  </div>
                </div>
                <div
                  class="em"
                  style="padding: 10px 0 0 20px"
                  v-if="goods.list.order_type != 4"
                >
                  下单留言
                </div>
                <div class="pad_20 bor_b_xu" v-if="goods.list.order_type != 4">
                  <el-input
                    size="small"
                    type="textarea"
                    resize="none"
                    placeholder="选填, 下单备注留言, 100字以内"
                    v-model="form.baining_remark"
                    maxlength="100"
                    style="height: 100px !important"
                  >
                  </el-input>
                </div>
                <div class="wh_f pad_20">
                  <div class="flex_1"></div>
                  <div class="flo_r fz_14">
                    <div class="wh_f" style="width: 200px">
                      <div class="wz_rigdq" style="width: 80px; color: #555555">
                        订单总价:
                      </div>
                      <div class="flex_1 wz_rigdq">
                        <span
                          v-if="goods.list.is_set_price == 1"
                          class="centerdq"
                          >{{
                            goods.list.sell_price *
                            goods.num *
                            form.formItems.length
                          }}</span
                        >
                        <span
                          v-if="goods.list.is_set_price == 0"
                          class="centerdq"
                          >待报价</span
                        >
                      </div>
                    </div>

                    <div class="wh_f mag_t-30" style="width: 200px">
                      <div class="wz_rigdq" style="width: 80px; color: #555555">
                        应付金额:
                      </div>
                      <div
                        v-if="goods.list.is_set_price == 1"
                        class="flex_1 wz_rigdq"
                      >
                        <span class="centerdq">{{
                          goods.list.sell_price *
                          goods.num *
                          form.formItems.length
                        }}</span>
                      </div>
                      <div
                        v-if="goods.list.is_set_price == 0"
                        class="flex_1 wz_rigdq"
                      >
                        <span class="centerdq wz_red fz_18">待报价</span>
                      </div>
                    </div>
                    <div class="wz_rigdq" style="padding: 50px 0 20px">
                      <el-button
                        @click="$router.go(-1)"
                        type=""
                        style="width: 150px"
                        >返回</el-button
                      >

                      <el-button
                        @click="addOrder"
                        type="primary"
                        style="width: 150px"
                        >提交订单</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div v-if="active == 2">
          <div class="ba_white mag_t-30">
            <pay></pay>
            <div class="">
              <el-form
                ref="form"
                :model="form"
                label-width="80px"
                :rules="rules"
              >
                <div class="em" style="padding: 10px 0 0 20px">
                  请输入标识信息
                </div>
                <div class="ddisp bor_b_xu pad_20 split_2">
                  <el-form-item label="税号" prop="税号">
                    <el-input
                      v-model="form.name"
                      style="width: 300px"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="em" style="padding: 10px 0 0 20px">订单信息</div>
                <div class="wh_f bor_b_hui pad_20">
                  <div class="flex_1">
                    <div class="pad_10_0 wz_hui fz_14">业务信息</div>
                    <div class="ddisp">
                      <div class="topdq">
                        <img
                          src="../assets/用户头像.jpg"
                          style="width: 80px"
                          alt=""
                        />
                      </div>
                      <div class="topdq pad_l_5 boxs">
                        <p class="em">数字化系统报告</p>
                        <p class="wz_hui pad_10_0 fz_14">默认 (此为规格信息)</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex_1 wh_f fz_14 wz_hui">
                    <div class="flex_1">
                      订单总价
                      <div class="wz_black pad_10_0">￥999</div>
                    </div>
                    <div class="flex_1">
                      订单状态
                      <div class="wz_black pad_10_0">888</div>
                    </div>
                    <div class="flex_1">
                      下单留言
                      <div class="pad_10_0 wz_hui">777</div>
                    </div>
                  </div>
                </div>
                <div class="wh_f pad_20">
                  <div class="flex_1"></div>
                  <div class="flo_r fz_14">
                    <div class="wz_rigdq" style="padding: 50px 0 20px">
                      <span
                        @click="addHeYan"
                        class="wz_white bor_3 xsgb"
                        style="padding: 20px 30px; background-color: #d9001b"
                        >提交核验</span
                      >
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="root == 2" class="pad_30_0">
        <Pay_SE
          v-if="root == 2"
          @custom-event="parentMethod"
          :list="payItem"
          :order_ids="order_ids"
        ></Pay_SE>
        <div class="wz_rigdq" style="padding: 50px 0 20px">
          <el-button @click="root = 1" type="" style="width: 150px"
            >返回</el-button
          >
        </div>
      </div>
      <div v-if="root == 3" class="pad_30_0">
        <payOk v-if="goods.list.is_set_price == 1" @addFu="handle"></payOk>
        <!--        <payErr></payErr>-->
        <payNo v-if="goods.list.is_set_price == 0"></payNo>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAddShop,
  getOrderActive,
  getorderdetail,
  getPay,
  getpayType,
} from "../api/api";
export default {
  name: "S_2",
  props: {
    goods: Object,
    require: true,
  },
  data() {
    return {
      order_ids: [],
      active: this.$store.state.orderAct,
      form: {
        user_name: "",
        user_tel: "",
        baining_remark: "",
        // 动态表单
        formItems: [{ user_name: "", user_tel: "", baining_remark: "" }],
      },

      root: 1,
      payItem: [],
      rules: {
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        税号: [{ required: true, message: "请输入税号", trigger: "blur" }],
        user_tel: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: this.validatePhoneNumber, trigger: "blur" },
        ],
      },
      order: [],
    };
  },
  methods: {
    isChannel() {
      return false;
    },
    parentMethod() {
      this.root = 3;
    },
    validatePhoneNumber(rule, value, callback) {
      const reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("手机号格式不正确"));
      }
    },
    handle() {
      console.log("触发了");
      this.$store.state.orderAct = 2;
    },
    addHeYan() {
      alert("提交核验成功");
      this.$router.push("Index");
      this.$store.state.orderAct = 1;
    },
    orderDetail(e) {
      getorderdetail({ id: e }).then((res) => {
        this.$store.commit("userOrderDetaild", res.data.data);
      });
    },
    addOrder() {
      // 批量下單
      let isInput = true;
      if (this.goods.list.order_type == 4) {
        for (let i = 0; i < this.form.formItems.length; i++) {
          const element = this.form.formItems[i];
          if (element.user_name == "") {
            this.$message({
              message: "请填写姓名!",
              type: "warning",
            });
            isInput = false;
            return;
          } else if (element.user_tel == "") {
            this.$message({
              message: "请填写手机号",
              type: "warning",
            });
            isInput = false;
            return;
          }

          if (element.baining_remark == "") {
            element.baining_remark = "默认";
          }
        }
      } else {
        isInput = false;
        if (this.form.baining_remark == "") {
          this.form.baining_remark = "默认";
        }
        if (this.form.user_name == "") {
          this.$message({
            message: "请填写姓名!",
            type: "warning",
          });
        } else if (this.form.user_tel == "") {
          this.$message({
            message: "请填写手机号",
            type: "warning",
          });
        }
      }

      let goods = {
        item_id: this.goods.list.spec_value_list[0].id,
        goods_num: 1,
        service_time: this.goods.list.service_time,
        num: this.goods.num,
        num: 1,
      };

      let data = {
        action: "buy",
        delivery_type: 1,
        goods: [],
        use_integral: 0,
        address_id: "",
        order_type: "0",
        source: "buy_now",
        seckill_id: "",
        cart_id: "",
        user_remark: "",
        user_name: this.form.user_name,
        user_tel: this.form.user_tel,
        baining_remark: this.form.baining_remark,
        category_id: this.goods.list.category_id[0],
        baining_step_type: this.goods.list.order_type,
        is_set_price: this.goods.list.is_set_price,
        service_time: this.goods.list.service_time,
        pay: [],
        batch: false,
      };

      // 多个商品
      for (let ij = 0; ij < this.form.formItems.length; ij++) {
        data.goods.push(goods);
      }

      var formItems = [...this.form.formItems];

      if (isInput) {
        data.batch = true;
        data.formItems = this.form.formItems;
      }

      if (formItems.length < 1) {
        return;
      }

      getAddShop(data).then((res) => {
        if (res.data.code == 1) {
          this.order_item = res.data.data;
          this.orderDetail(res.data.data.order_id);
          this.$store.commit("order_id", res.data.data.order_id);
          if (this.goods.list.is_set_price == 1) {
            this.getPayList();
          } else if (this.goods.list.is_set_price == 0) {
            this.root = 3;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });

      // `order_terminal`'订单来源;1-微信小程序;2-微信公众号;3-手机H5;4-PC;5-苹果app;6-安卓app;',
      //  `order_status`'订单状态;0-待付款;1-待发货;2-待收货;3-已完成;4-已关闭',
      //  `pay_status`  '支付状态;0-待支付;1-已支付;',
      //  `pay_way`  '支付方式:1-余额支付;2-微信支付;3-支付宝支付;',
      //  `pay_time` ,
      //  `address` '地址',
      //  `delivery_type`  '配送方式：1-快递发货,2-上门自提,3-同城配送,4-虚拟发货',
      //  `goods_price` '订单商品总价',
      // if(this.goods.list.is_set_price==0){
      //   this.root=3
      // }else{
      // }
    },
    // 多订单处理
    execOrder(res) {
      var result = [];
      var ids = [];
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        if (element.data.code == 1) {
          var ds = element.data.data;
          var { order_id: id } = ds;
          ids.push(id);
          result.push(element.data.data);
        }
      }
      this.order_ids = ids;
      this.order_item = result[0];
      this.getPayList();
    },
    getPayList() {
      getpayType({
        from: "order",
        order_id: this.order_item.order_id,
        scene: 4,
      }).then((res) => {
        console.log(res);
        this.payItem = res.data.data.lists;
        console.log("支付方式", this.payItem);
        this.root = 2;
      });
    },
    addFromItem() {
      this.form.formItems.push({
        user_name: "",
        user_tel: "",
        baining_remark: "",
      });
    },
    removeFromItem(index) {
      this.form.formItems.splice(index, 1);
    },
  },
  mounted() {
    getOrderActive({ type: this.goods.list.order_type }).then((res) => {
      this.order = res.data.data;
    });
  },
};
</script>

<style>
.ddisp i {
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  top: 4px;
}
.el-icon-circle-plus-outline {
  color: #6bb5b5;
}
.el-icon-remove-outline {
  color: #d9041e;
}
.Items .el-form-item {
  margin-left: 20px;
}
.el-form-item__label {
  white-space: nowrap;
}
</style>
