<template>
  <div style="width: 1200px;margin: 0 auto">
    <el-tabs class="ba_white pad_20 mag_t-20" v-model="activeName">
      <el-tab-pane label="订单详情" name="1">
        <order_Top></order_Top>
        <el-form ref="form" :model="form" size="small" label-width="160px">

          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs"
                 style="border-bottom: #969896 1px dashed">报告文件</div>
              <div class="pad_t_10 mag_t-20">
                <el-form-item label="需要解读的报告">
                  <div v-if="this.form.report_file">
                    <a class="wz_blue mag_0_20"
                       download="扫描件.pdf"
                       :href="this.$store.state.httpsDown+this.form.report_file"
                       target="_blank">{{this.form.report_file.substring(this.form.report_file.lastIndexOf("/") + 1)}}  ↓</a>
                  </div>
                </el-form-item>
              </div>
          </div>

          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs"
                 style="border-bottom: #969896 1px dashed">解读报告</div>
            <div class="pad_t_10 mag_t-20">
              <el-form-item label="解读报告">
                <div v-if="this.form.interpret_file">
                  <a class="wz_blue mag_0_20"
                     download="扫描件.pdf"
                     :href="this.$store.state.httpsDown+this.form.interpret_file"
                     target="_blank">{{this.form.interpret_file.substring(this.form.interpret_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>

              </el-form-item>
            </div>
          </div>

          <div class="wz_rigdq pad_t_20 bor_t_hui">
            <el-button @click="$router.back()" type="primary" style="width: 120px">返回</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="流程日志" name="2">
        <log></log>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
export default {
  name: "jdbgDetail",
  data(){
    return{
      form:this.$store.state.userOrderDetaild.list,
      activeName:"1",
    }
  },
}
</script>

<style scoped>

</style>