<template>
  <div style="overflow-x: hidden">
    <el-carousel
      trigger="click"
      class=""
      style="min-width: 1200px; height: 622px"
    >
      <el-carousel-item
        style="height: 622px"
        v-for="(item, indexid) in BannerImgList"
        :key="indexid"
      >
        <div class="xsgb" style="height: 622px">
          <img
            @click="addImgBanner(item)"
            style="height: 622px"
            class="wh_max"
            :src="$store.state.imgHttp + item.cover_file"
            alt=""
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div v-if="false">
      <el-button @click="srbsxx">输入标识信息</el-button>
      <el-button @click="scbgwj">上传报告文件</el-button>
      <el-button @click="sckbzl">上传开办资料</el-button>
      <el-button @click="txkpzl">填写开票资料</el-button>
      <el-button @click="kpzl_err">填写开票资料</el-button>
      <el-button @click="$router.push('/txbd')">填写表单</el-button>
    </div>
    <div class="ba_white">
      <!--    店铺数据-->
      <div
        class="jz animate__animated animate__bounceInLeft"
        style="width: 1200px; padding: 40px 0 50px"
      >
        <div class="pad_30_0 wh_f wz_jz ba_white" style="color: #262626">
          <div class="flex_1">
            <div class="fz_46 animate__animated animate__flipInY">
              <span class="em">{{ dianPu.month_order }}</span>
            </div>
            <div class="fz_14 mag_t-15">每月业务量</div>
          </div>
          <div class="flex_1">
            <div class="fz_46 animate__animated animate__flipInY">
              <span class="em">{{ dianPu.save_tax }}</span
              ><span class="fz_24">+</span>
            </div>
            <div class="fz_14 mag_t-15">预计每年为企业节税</div>
          </div>
          <div class="flex_1">
            <div class="fz_46 animate__animated animate__flipInY">
              <span class="em">{{ dianPu.user_online }}</span
              ><span class="fz_24">+</span>
            </div>
            <div class="fz_14 mag_t-15">用户同时在线</div>
          </div>
          <div class="flex_1">
            <div class="fz_46 animate__animated animate__flipInY">
              <span class="em">{{ dianPu.options }}</span
              ><span class="fz_24">+</span>
            </div>
            <div class="fz_14 mag_t-15">全国企业的共同选择</div>
          </div>
        </div>
      </div>
      <div
        v-if="hot"
        class="wz_jz box animate__animated"
        style="width: 1200px; margin: 0 auto; padding-bottom: 80px"
      >
        <div
          class="disp wz_jz xsgb"
          @click="addhot(item)"
          style="width: 16.6%"
          v-for="(item, index) in hot"
          :key="index"
        >
          <div class="">
            <img
              class="bor_255"
              :src="$store.state.imgHttp + item.cover_file"
              width="160"
              height="160"
              alt=""
            />
          </div>
          <div class="mag_t-15">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="Index_1" style="min-height: 500px">
      <div class="jz pad_b_20 xd" style="width: 1200px">
        <div class="ddisp wz_jz pad_30_0">
          <div
            style="height: 1px; width: 300px; background-color: #fd5d3d"
          ></div>
          <div class="centerdq fz_28" style="margin: 0 5px">安税网业务体系</div>
          <div
            style="height: 1px; width: 300px; background-color: #fd5d3d"
          ></div>
          <el-button @click="yewulook" type="danger" class="jd" style="right: 0"
            >了解更多</el-button
          >
        </div>

        <div class="wh_f">
          <div
            @mousemove="addlist_1"
            @mouseleave="bddlist_1"
            class="xsgb"
            style="min-height: 350px; width: 267px; height: 352px"
          >
            <div
              class="he_max wh_9-6 bor_5 pad_20 boxs xd kp"
              :class="list_1.img ? 'ba_white' : 'xz_hover'"
            >
              <div class="" style="z-index: 1">
                <div class="fz_24 pad_5_0 em wz_jz">
                  {{ list_1.name }}
                </div>
                <!--           -->
                <div
                  style="padding: 10px 0"
                  v-for="(listitem, indexs) in list_1.sons"
                  :key="indexs"
                >
                  <span
                    @click="addGoodsDetail(listitem)"
                    class="hover_yellow xsgb ccsl_1"
                    >{{ listitem.name }}</span
                  >
                </div>
              </div>
              <div v-if="list_1.img">
                <img
                  :src="list_1.img_1"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
              <div v-if="!list_1.img">
                <img
                  :src="list_1.img_2"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
            </div>
          </div>

          <div
            @mousemove="addlist_2"
            @mouseleave="bddlist_2"
            class="xsgb"
            style="min-height: 350px; width: 267px; height: 352px"
          >
            <div
              class="he_max wh_9-6 bor_5 pad_20 boxs xd kp"
              :class="list_2.img ? 'ba_white' : 'xz_hover'"
            >
              <div class="" style="z-index: 1">
                <div class="fz_24 pad_5_0 em wz_jz">
                  {{ list_2.name }}
                </div>
                <!--           -->
                <div
                  style="padding: 10px 0"
                  v-for="(listitem, indexs) in list_2.sons"
                  :key="indexs"
                >
                  <span
                    @click="addGoodsDetail(listitem)"
                    class="hover_yellow xsgb ccsl_1"
                    >{{ listitem.name }}</span
                  >
                </div>
              </div>
              <div v-if="list_2.img">
                <img
                  :src="list_2.img_1"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
              <div v-if="!list_2.img">
                <img
                  :src="list_2.img_2"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
            </div>
          </div>

          <div
            @mousemove="addlist_3"
            @mouseleave="bddlist_3"
            class="xsgb"
            style="min-height: 350px; width: 267px; height: 352px"
          >
            <div
              class="he_max wh_9-6 bor_5 pad_20 boxs xd kp"
              :class="list_3.img ? 'ba_white' : 'xz_hover'"
            >
              <div class="" style="z-index: 1">
                <div class="fz_24 pad_5_0 em wz_jz">
                  {{ list_3.name }}
                </div>
                <!--           -->
                <div
                  style="padding: 10px 0"
                  v-for="(listitem, indexs) in list_3.sons"
                  :key="indexs"
                >
                  <span
                    @click="addGoodsDetail(listitem)"
                    class="hover_yellow xsgb ccsl_1"
                    >{{ listitem.name }}</span
                  >
                </div>
              </div>
              <div v-if="list_3.img">
                <img
                  :src="list_3.img_1"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
              <div v-if="!list_3.img">
                <img
                  :src="list_3.img_2"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
            </div>
          </div>

          <div
            @mousemove="addlist_4"
            @mouseleave="bddlist_4"
            class="xsgb"
            style="min-height: 350px; width: 267px; height: 352px"
          >
            <div
              class="he_max wh_9-6 bor_5 pad_20 boxs xd kp"
              :class="list_4.img ? 'ba_white' : 'xz_hover'"
            >
              <div class="" style="z-index: 1">
                <div class="fz_24 pad_5_0 em wz_jz">
                  {{ list_4.name }}
                </div>
                <!--           -->
                <div
                  style="padding: 10px 0"
                  v-for="(listitem, indexs) in list_4.sons"
                  :key="indexs"
                >
                  <span
                    @click="addGoodsDetail(listitem)"
                    class="hover_yellow xsgb ccsl_1"
                    >{{ listitem.name }}</span
                  >
                </div>
              </div>
              <div v-if="list_4.img">
                <img
                  :src="list_4.img_1"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
              <div v-if="!list_4.img">
                <img
                  :src="list_4.img_2"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
            </div>
          </div>

          <div
            @mousemove="addlist_5"
            @mouseleave="bddlist_5"
            class="xsgb"
            style="min-height: 350px; width: 267px; height: 352px"
          >
            <div
              class="he_max wh_9-6 bor_5 pad_20 boxs xd kp"
              :class="list_5.img ? 'ba_white' : 'xz_hover'"
            >
              <div class="" style="z-index: 1">
                <div class="fz_24 pad_5_0 em wz_jz">
                  {{ list_5.name }}
                </div>
                <!--           -->
                <div
                  style="padding: 10px 0"
                  v-for="(listitem, indexs) in list_5.sons"
                  :key="indexs"
                >
                  <span
                    @click="addGoodsDetail(listitem)"
                    class="hover_yellow xsgb ccsl_1"
                    >{{ listitem.name }}</span
                  >
                </div>
              </div>
              <div v-if="list_5.img">
                <img
                  :src="list_5.img_1"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
              <div v-if="!list_5.img">
                <img
                  :src="list_5.img_2"
                  alt=""
                  class="jd"
                  style="
                    width: 60%;
                    z-index: 0;
                    right: 0;
                    bottom: 0;
                    max-width: 100px;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Index_2" style="min-height: 500px">
      <div class="jz pad_b_20 xd" style="width: 1200px">
        <div class="ddisp wz_jz pad_30_0">
          <div
            style="height: 1px; width: 300px; background-color: #fd5d3d"
          ></div>
          <div class="centerdq fz_28" style="margin: 0 5px">政策法规</div>
          <div
            style="height: 1px; width: 300px; background-color: #fd5d3d"
          ></div>
          <el-button
            @click="$router.push('/policy')"
            type="danger"
            class="jd"
            style="right: 0"
            >了解更多</el-button
          >
        </div>
        <div class="bor_5 ba_white ddisp pad_10_0" style="min-height: 300px">
          <div class="ddisp">
            <div
              @click="caseDateil(item)"
              class="wh_5 ddisp boxs"
              style="padding: 10px 0 10px 10px"
              v-for="(item, index) in faGui"
              :key="index"
            >
              <div class="" style="width: 150px">
                <div style="width: 100px">
                  <div
                    v-if="item.classify_title"
                    class="wz_jz jz bor_5 pad_5_0 fz_14"
                    style="background-color: #ffeae6"
                  >
                    <span class="ccsl_1" style="color: #dc2d22">
                      {{ item.classify_title }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="ddisp boxs centerdq"
                style="width: calc(100% - 150px)"
              >
                <div class="flex_1" style="width: calc(100% - 50px)">
                  <span class="ccsl_1 xsgb hover_yellow">{{ item.title }}</span>
                </div>
                <div class="flo_r" style="width: 50px">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ba_white" style="min-height: 500px">
      <div class="jz pad_b_20 xd" style="width: 1200px">
        <div class="ddisp wz_jz pad_30_0">
          <div
            style="height: 1px; width: 300px; background-color: #fd5d3d"
          ></div>
          <div class="centerdq fz_28" style="margin: 0 5px">行业案例</div>
          <div
            style="height: 1px; width: 300px; background-color: #fd5d3d"
          ></div>
          <el-button
            @click="$router.push('/Case')"
            type="danger"
            class="jd"
            style="right: 0"
            >了解更多</el-button
          >
        </div>
        <div class="ddisp wz_jz">
          <div
            style="width: 20%"
            v-for="(item, index) in CaseList"
            :key="index"
          >
            <div class="wh_9 xyy bor_5 xsgb" @click="addCase(item)">
              <div>
                <img
                  class="bor_5 img_size"
                  :src="$store.state.imgHttp + item.cover_file"
                  alt=""
                />
              </div>
              <div class="pad_20">
                <div class="ccsl_2 hover_yellow">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apicmsDetail,
  getBannerList,
  getBQview,
  getCaseList,
  getFaGui,
  getImgUrlTop,
  getIndexNumber,
  getYewuIndex,
} from "../../api/api";

import { yeWus } from "./data";
import { Loading } from "element-ui";
import { getshopDetail } from "../../api/test";

let loading;
export default {
  name: "Index",
  data() {
    return {
      list: [],
      BannerImgList: [],
      dianPu: {},
      uploadedFile: null,
      faGui: [],
      CaseList: [],
      bottomBQ: {},
      yeWu: [yeWus],
      hot: [],
      imgUrl: "",
      list_1: {},
      list_2: {},
      list_3: {},
      list_4: {},
      list_5: {},
    };
  },

  methods: {
    addlist_1() {
      this.list_1.img = false;
    },
    bddlist_1() {
      this.list_1.img = true;
    },
    addlist_2() {
      this.list_2.img = false;
    },
    bddlist_2() {
      this.list_2.img = true;
    },
    addlist_3() {
      this.list_3.img = false;
    },
    bddlist_3() {
      this.list_3.img = true;
    },
    addlist_4() {
      this.list_4.img = false;
    },
    bddlist_4() {
      this.list_4.img = true;
    },
    addlist_5() {
      this.list_5.img = false;
    },
    bddlist_5() {
      this.list_5.img = true;
    },

    look(i) {
      console.log("操作前", this.yeWu[i].img);
      this.yeWu[i].img = false;
      console.log("移入了", this.yeWu[i].img);
    },
    lookback(i) {
      this.imgUrl = this.yeWu[i].img_1;
      this.yeWu[i].img = true;
      console.log("移出了", this.yeWu[i].img);
    },
    yewulook() {
      this.$store.state.yewuId = "all";
      this.$router.push("yeWuIndex");
    },
    addhot(e) {
      console.log(e);
      if (e.group_code == 1) {
        window.open(e.link_url, "_blank");
        // this.$router.push(e.link_url)
      } else if (e.group_code == 2) {
        loading = Loading.service({
          lock: true,
          text: "加载中……",
          background: "rgba(255,255,255,0.7)",
        });
        getshopDetail({ goods_id: e.link_url, visit: 1 }).then((res) => {
          if (res.status == 200) {
            loading.close();
            // 在某个组件中的方法中提交 mutation
            this.$store.commit("setGoodsDetails", res.data.data);

            // this.$store.state.goodsDetails = res.data.data
            this.$router.push({
              name: "detail",
            });
          }
        });
      }
    },
    addGoodsDetail(e) {
      this.$store.state.yewuId = e.id;
      this.$router.push({ path: "/yeWuIndex" });
    },
    addCase(e) {
      e.isNow = 2;
      this.$store.commit("caseList", e);
      this.$router.push({ path: "/CaseData", force: true });
    },
    caseDateil(e) {
      e.isNow = 1;
      this.$store.commit("caseList", e);
      this.$router.push({
        path: "/CaseData",
      });
    },
    srbsxx() {
      this.$router.push("srbsxx");
      // this.$emit('addFu');
    },
    // 上传报告文件
    scbgwj() {
      this.$router.push("/scjdbg");
      // this.$emit('scbgwj');
    },
    // 上传开办资料
    sckbzl() {
      this.$router.push("/sckbzl");
    },
    // 填写开票资料
    txkpzl() {
      this.$router.push("/txkpzl");
    },
    //待支付 填写开票资料
    kpzl_err() {
      this.$router.push("kpzl_err");
    },
    handleUploadSuccess(file) {
      this.uploadedFile = file;
    },
    // 点击轮播图
    addImgBanner(e) {
      console.log(e);
      if (e.group_code == 1) {
        console.log("跳转外部");
        window.open(e.link_url, "_blank");
      } else if (e.group_code == 2) {
        loading = Loading.service({
          lock: true,
          text: "加载中……",
          background: "rgba(255,255,255,0.7)",
        });
        getshopDetail({ goods_id: e.link_url, visit: 1 }).then((res) => {
          if (res.status == 200) {
            loading.close();
            // 在某个组件中的方法中提交 mutation
            this.$store.commit("setGoodsDetails", res.data.data);

            // this.$store.state.goodsDetails = res.data.data
            this.$router.push({
              name: "detail",
            });
          }
        });
      }
    },
  },
  mounted() {
    // let res = await getIndexNumber();
    // console.log("这是户数",res)
    let boxList = document.querySelectorAll(".box");
    window.addEventListener("scroll", scrollLoad);
    function scrollLoad() {
      let taggervalue = window.innerHeight * 0.98;
      boxList.forEach((box) => {
        let boxTop = box.getBoundingClientRect().top;
        // console.log(boxTop)
        if (boxTop <= taggervalue) {
          box.classList.add("animate__bounceInRight");
          box.classList.remove("animate__bounceOutRight");
        } else {
          box.classList.add("animate__bounceOutRight");
          box.classList.remove("animate__bounceInRight");
        }
      });
    }

    getBannerList().then((res) => {
      this.BannerImgList = res.data.data.lists;
    });
    window.Vue = this;
    apicmsDetail().then((res) => {
      this.hot = res.data.data.lists;
    });
    getCaseList({
      section_code: 1,
      page_no: 1,
      page_size: 5,
    }).then((res) => {
      this.CaseList = res.data.data.lists;
    });
    // 导航栏
    getImgUrlTop().then((res) => {
      this.list = res.data.data.content[1].content.data;
    });
    // 店铺数据
    getIndexNumber().then((res) => {
      this.dianPu = res.data.data.shop;
    });
    // 业务体系
    getYewuIndex().then((res) => {
      this.list_1 = res.data.data.lists[0];
      this.$set(this.list_1, "img", true);
      this.$set(
        this.list_1,
        "img_1",
        require("../../../public/assets/baogao_1.png")
      );
      this.$set(
        this.list_1,
        "img_2",
        require("../../../public/assets/baogao_2.png")
      );
      this.list_2 = res.data.data.lists[1];
      this.$set(this.list_2, "img", true);
      this.$set(
        this.list_2,
        "img_1",
        require("../../../public/assets/guquan_1.png")
      );
      this.$set(
        this.list_2,
        "img_2",
        require("../../../public/assets/guquan_2.png")
      );
      this.list_3 = res.data.data.lists[2];
      this.$set(this.list_3, "img", true);
      this.$set(
        this.list_3,
        "img_1",
        require("../../../public/assets/nashui_1.png")
      );
      this.$set(
        this.list_3,
        "img_2",
        require("../../../public/assets/nashui_2.png")
      );
      this.list_4 = res.data.data.lists[3];
      this.$set(this.list_4, "img", true);
      this.$set(
        this.list_4,
        "img_1",
        require("../../../public/assets/qiye_1.png")
      );
      this.$set(
        this.list_4,
        "img_2",
        require("../../../public/assets/qiye_2.png")
      );
      this.list_5 = res.data.data.lists[4];
      this.$set(this.list_5, "img", true);
      this.$set(
        this.list_5,
        "img_1",
        require("../../../public/assets/wanshui_1.png")
      );
      this.$set(
        this.list_5,
        "img_2",
        require("../../../public/assets/wanshui_2.png")
      );

      this.yeWu[0].id = res.data.data.lists[0].id;
      this.yeWu[0].pid = res.data.data.lists[0].pid;
      this.yeWu[0].name = res.data.data.lists[0].name;
      this.yeWu[0].level = res.data.data.lists[0].level;
      this.yeWu[0].image = res.data.data.lists[0].image;
      this.yeWu[0].gooods = res.data.data.lists[0].gooods;
      this.yeWu[0].sons = res.data.data.lists[0].sons;
    });
    getFaGui({
      page_no: 1,
      page_size: 10,
      section_code: 0,
    }).then((res) => {
      this.faGui = res.data.data.lists;
    });
  },
};
</script>

<style scoped>
.Index_1 {
  background-image: url("../../../public/assets/index_1.png");
  background-size: 100% 100%;
}
.Index_2 {
  background-image: url("../../../public/assets/index_2.png");
  background-size: 100% 100%;
}
.xz_hover {
  background: linear-gradient(180deg, #f9c7c7 0%, #fff5f6 100%);
}
.kp {
  box-shadow: 0px 2px 10px 0px rgba(48, 35, 35, 0.18);
  border-radius: 14px;
}
</style>
