<template>
<div>
  <div class="ba_white" style="padding: 10px 20px">
    <div class="fz_18 em pad_20_0 bor_b_hui">个体户代开办-迁回申请</div>
    <div class="wh_f pad_20_0">
      <div class="flex_1 pad_r_20">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="迁回原因" prop="back_reason">
            <el-input type="textarea" resize="none"
                      v-model="form.back_reason"
                      maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="服务协议" prop="service_agreement">
            <inputUpload class="mag_l-20" @upload="hand"></inputUpload>
          </el-form-item>
        </el-form>
        <div class="wz_rigdq">
          <el-button @click="ok" size="small" type="primary">提交申请</el-button>
          <el-button size="small" @click="$router.push('orderYewu')">取消</el-button>
        </div>
      </div>
      <div class="pad_l_30 fz_16" style="width: 240px;border-left: 1px solid #ebebeb">
        <div class=" em pad_20_0">个体户迁回流程</div>
        <el-timeline size="large " :reverse="reverse">
          <el-timeline-item
              v-for="(activity,index) in activities"
              :key="index">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {getjgthkb_Qianhui} from "../../api/api";

export default {
  name: "gth_qhsq",
  data(){
    return{
      reverse:false,
      form:{
        back_reason:'',
        service_agreement:null,
        sn:this.$store.state.userOrderDetaild.sn,
        order_id:this.$store.state.userOrderDetaild.id,
      },
      rules:{
        back_reason:[{required: true, message: '请输入迁回原因', trigger: 'blur'}],
        service_agreement:[{required: true, message: '请上传服务协议', trigger: 'blur'}]
      },
      activities: [{
        content: '发起迁回申请',
        timestamp: '2018-04-11'
      }, {
        content: '邮寄材料',
        timestamp: '2018-04-12'
      }, {
        content: '迁出地址',
        timestamp: '2018-04-13'
      }, {
        content: '若需额外付费(线下支付)',
        timestamp: '2018-04-14'
      }, {
        content: '审核材料',
        timestamp: '2018-04-15'
      }, {
        content: '迁回完成',
        timestamp: '2018-04-16'
      }]
    }
  },
  methods:{
    hand(file) {
      this.form.service_agreement = file.response.data.url;
      console.log(file.response.data)
    },
    ok(){
      this.form.sn=this.$store.state.userOrderDetaild.item.sn
      this.form.order_id=this.$store.state.userOrderDetaild.item.id
      getjgthkb_Qianhui(this.form).then(res=>{
        if (res.data.code == 1){
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.$router.push('orderYewu')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>