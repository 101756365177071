<template>
  <div class="ba_white mag_b_20">
    <div class="pad_20 wh_f bor_b_xu">
      <div style="width: 120px">
        <span><i class="el-icon-time"></i></span>
        <span class="centerdq mag_l-10 fz_20 em" style="color: #d9001b">待支付</span>
      </div>
    </div>
    <div class="bor_b_xu">
      <div class="wh_f fz_14 pad_20_0" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">订单编号:</div>
        <div class=""><span class="centerdq mag_l-20">{{ order_item.sn }}</span></div>
      </div>
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">订单价格:</div>
        <div class="">
          <span class="centerdq mag_l-20" style="color: #d9001b">
            待报价 (待客服修改报价后再支付)
<!--            ￥{{ order_item.order_amount }}-->
          </span>
        </div>
      </div>
      <div class="wh_f fz_14 pad_b_20" style="color: #555555">
        <div class="wz_rigdq" style="width: 130px;">业务名称:</div>
        <div class="">
          <span class="centerdq mag_l-20">
            {{ order_item.order_goods[0].goods_name }}
          </span>
        </div>
      </div>
    </div>
    <div class="pad_20">
      <div class="pad_b_10">
        <span class="em fz_14">请补充相关材料并提交, 由管理人员评估后再更新报价</span>
      </div>
      <div class="split_2">
        <div>
          <el-button v-if="$store.state.userOrderDetaild.baining_step_type==6" @click="kpzl_err">填写开票资料</el-button>
          <el-button v-if="$store.state.userOrderDetaild.baining_step_type==7" @click="txbd">填写表单</el-button>

        </div>
        <div class="wz_rigdq fz_13" style="color: #015478"><span class="xsgb" @click="$router.push('orderYewu')">返回订单列表</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getorderdetail} from "../api/api";

export default {
  name: "payOk",
  data(){
    return{
      dialogVisible:false,
      order_item:{}
    }
  },
  methods:{
    kpzl_err(){
      this.$store.commit('userOrderDetaild',this.order_item);
      this.$router.push('kpzl_err')
    },
    txbd(){
      this.$store.commit('userOrderDetaild',this.order_item);
      this.$router.push('txbd')
    }
  },
  mounted() {
    getorderdetail({
      id:this.$store.state.order_id
    }).then(res=>{
      this.order_item = res.data.data
      this.$store.commit('userOrderDetaild',this.order_item)
    })
  }
}
</script>

<style scoped>

</style>
