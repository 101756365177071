<template>
  <div class="header">
    <div class="header-content wh_f">
      <div class="info">
        <img
          class="bor_255 centerdq ba_white"
          :src="logo"
          height="48"
          width="48"
        />
        <p>{{ title }}</p>
      </div>
      <div class="wh_max">
        <ul style="float: right">
          <li v-for="item in list" @click="lestGO(item)">
            <p class="jzxz">{{ item.name }}</p>
            <div v-if="item.sons" class="sons wz_jz">
              <ul class="wz_jz">
                <li v-for="sub in item.sons" class="wz_jz">
                  <p @click="lestItem(sub)" class="jzxz wz_jz">
                    {{ sub.name }}
                  </p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopList, getIndexNumber } from "../api/api";

export default {
  name: "HeaderMenu",
  data() {
    return {
      logo: "/assets/logo.png",
      title: "",
      list: [],
      runing: false,
    };
  },
  async mounted() {
    let res = await getTopList();
    if (res.status == 200) {
      console.log("这是导航栏", res);
      this.list = [
        { name: "首页" },
        ...res.data.data.lists,
        {
          name: "政策法规库",
          sons: [{ name: "政策法规库" }, { name: "行业案例库" }],
        },
        { name: "培训课程" },
        { name: "用户中心" },
      ];
    }

    res = await getIndexNumber();
    if (res.status == 200) {
      this.title = res.data.data.shop.name;
      this.logo = res.data.data.shop.logo;
      this.$store.state.logo = this.logo;
    }
  },
  methods: {
    lestGO(e) {
      /**
       * 防抖代码
       */
      if (this.runing) {
        return;
      }
      this.runing = true;
      setTimeout(() => {
        this.runing = false;
      }, 1000);

      console.log("iteGom");
      switch (e.name) {
        case "首页": {
          this.$router.push("Index");
          break;
        }
        case "用户中心": {
          this.$router.push({ path: "myuserIndex" });
          break;
        }
        case "政策法规库": {
          this.$router.push({ path: "policy" });
          break;
        }
        case "行业案例库": {
          this.$router.push({ path: "Case" });
          break;
        }
        default: {
          if (this.$route.path === "/yeWuIndex") {
            this.$store.state.yewuId = e.id;
            this.$router.push({
              path: "/yeWuIndex",
              query: {
                id: e.id,
              },
            });
            window.location.href = window.location.href;
            return;
          }
          this.$store.state.yewuId = e.id;
          this.$router.push({
            path: "/yeWuIndex",
            query: {
              id: e.id,
            },
          });
        }
      }
    },
    lestItem(e) {
      this.lestGO(e);
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #dc2d22;
}

.header-content {
  max-width: 1200px;
  height: 64px;
  margin: 0 auto;
  color: white;
  gap: 20px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.info p {
  white-space: nowrap;
}

ul {
  display: flex;
  /*gap: 20px;*/
  align-items: center;
}

li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  transition: 0.2s;
  cursor: pointer;
  min-height: 64px;
  min-width: 100px;
  font-size: 14px;
}

li:hover {
  background-color: white;
  color: #dc2d22;
}

.sons {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 10;
  transition: 0.2s;
  color: #595959;
  opacity: 0;
  width: 100%;
  min-height: 0;
  max-height: 0;
  background-color: rgba(255, 255, 255, 0.84);
  box-shadow: 0px 2px 10px 0px rgba(216, 216, 216, 0.5);
}

.sons ul {
  max-width: 1000px;
  margin: 0 auto;
  gap: 24px;
}

.sons li {
  min-width: max-content;
}

.sons li:hover {
  color: #dc2d22;
  background-color: transparent;
}

li:hover .sons {
  min-height: 64px;
  max-height: 200px;
  opacity: 1;
}
</style>
