<script>
import {
  getAddShop,
  getOrderActive,
  getorderdetail,
  getPay,
  getpayType,
  getApiRenew
} from "../../api/api";

import VueQr from "vue-qr";

export default {
    components: {
    VueQr,
  },
  data() {
    return {
      order_ids: [],
      sn: null,
      payItem: [],
      order_detail: [],
      order_amount: "0.00",
      order_items: [],
      jm: false,
      item: {},
      pay: [],
      showWxpay: false,
      payInfo: {
        config: {
          code_url: "",
          order_amount: 0,
        },
      },
    };
  },
  mounted() {
    this.order_id = this.$route.params.id;
    this.sn = this.$route.query.sn;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.getPayList();
      await this.addRenew();
      await this.orderDetail(); 
    },
    async addRenew() {
      var item = {
        action: "buy",
        delivery_type: 1,
        goods: [
          {
            item_id: 62,
            goods_num: 1,
            service_time: 1,
            num: 1,
          },
        ],
        use_integral: 0,
        address_id: "",
        order_type: "0",
        source: "buy_now",
        seckill_id: "",
        cart_id: "",
        user_remark: "",
        user_name: "",
        user_tel: "",
        baining_remark: "",
        category_id: 17,
        baining_step_type: "4",
        is_set_price: 1,
        service_time: 1,
        pay: [],
        batch: true,
        formItems: [
          {
            renew: this.order_id,
            user_name: "14777777777",
            user_tel: "14777777777",
            baining_remark: "placeOrder",
          },
        ],
      };

      let result = await getAddShop(item);
      let {
        data: {
          data: { order_id },
        },
      } = result;
      this.order_ids = [order_id];
      console.log("获取到", this.order_ids);
    },
    async payAdd(payWay) {
      try {
        var result = {};
        var order_item = this.order_items[0];
        result = await getPay({
          order_id: order_item.id,
          pay_way: payWay,
          from: "order",
        });

        var { data, code, msg } = result;
        // 关闭加载蒙版
        // loading.close();
        if (data.code == 1 && data.data.pay_way == 2) {
          console.log("进入微信支付", data);
          // 调用微信支付 存储data
          this.payInfo = data.data;
          this.showWxpay = true;
          // 调用支付
          this.createTimer();
        } else if (data.code == 1 && data.data.pay_way == 3) {
          // 支付宝支付
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = data.config; // data.config就是接口返回的form 表单字符串
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      } catch (error) {
        // loading.close();
      }
    },
    // 微信支付
    createTimer() {
      this.jm = true;
      // 刷新时间
      var cancelTime = 4 * 1000;
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        // this.getOrder();
        this.getRenew();
      }, cancelTime);
    },
    clearTimer() {
      clearInterval(this.timer);
    },
    async getRenew() {
      let id = this.renew_id;
      let result = await getApiRenew({ id, renew_sn: this.sn }, this.jm);

      let {
        data: {
          code = -1,
          data: { pay_status = -1 },
        },
      } = result;

      console.log("续费", pay_status, code);
      if (code == 1) {
        console.log("进入检查支付是否成功阶段", pay_status);

        if (pay_status == 1) {
          clearInterval(this.timer);
          this.showWxpay = false;
          this.$message({
            message: "支付成功",
            type: "success",
          });

          let ids = [this.order_id]
                      // 到支付成功界面
            this.$router.push({
              path: "/paySuccess?ids=" + ids,
            });
        }
      }
    },
    async getOrder(id) {
      id = this.order_id;

      if (this.renew) {
        this.getRenew(id);
        return;
      }

      getApiPwy(
        {
          id,
        },
        this.jm
      ).then((res) => {
        console.log("支付res", res.data.pay_status);
        var code = res.data.code;

        if (code == 1) {
          console.log("进入检查支付是否成功阶段", res.data.data.pay_status);
          // this.order = res.data;
          if (res.data.data.pay_status == 1) {
            clearInterval(this.timer);
            this.showWxpay = false;
            this.$message({
              message: "支付成功",
              type: "success",
            });

            let ids = "";

            let { order_list } = res.data.data;
            for (let i = 0; i < order_list.length; i++) {
              ids += order_list[i].order_id + ",";
            }

            // 到支付成功界面
            this.$router.push({
              path: "/paySuccess?ids=" + ids,
            });
          }
        }
      });
    },

    getPayList() {
      getpayType({
        from: "order",
        order_id: this.order_id,
        scene: 4,
      }).then((res) => {
        this.payItem = res.data.data.lists;
        console.log("支付方式", this.payItem);
      });
    },
    parentMethod() {
      //返回上一页
      this.$router.go(-1);
    },
    orderDetail() {
      let id = this.order_ids[0];
      getorderdetail({ id }).then((res) => {
        this.order_detail = res.data.data;
        this.order_amount = res.data.data.order_amount;
        this.order_items = [this.order_detail];
        this.renew_id= this.order_detail.id;
        console.log("订单详情", this.order_detail);
      });
    },
  },
};
</script>

<template>
  <div class="pad_30_0">
    <div style="width: 1200px" class="ba_white mag_b_20 wh1200 jz">
      <div class="pad_20 wh_f bor_b_xu">
        <div style="width: 120px">
          <span class="centerdq"><i class="el-icon-time fz_26 centerdq" style="color: #ff2c3c"></i></span>
          <span class="centerdq mag_l-10 fz_20 em">待支付</span>
        </div>
      </div>
      <div class="bor_b_xu" v-if="order_items" v-for="(item, index) in order_items">
        <div class="wh_f fz_14 pad_20_0" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">订单编号:</div>
          <div class="">
            <span class="centerdq mag_l-20">{{ item.sn }}</span>
          </div>
        </div>
        <div class="wh_f fz_14 pad_b_20" style="color: #555555" v-if="!!sn">
          <div class="wz_rigdq" style="width: 130px">续费订单:</div>
          <div class="">
            <span class="centerdq mag_l-20">{{ sn }}</span>
          </div>
        </div>

        <div class="wh_f fz_14 pad_b_20" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">订单价格:</div>
          <div class="">
            <span class="centerdq mag_l-20" style="color: #d9001b">
              <span v-if="item.is_set_price == 0">待报价</span>
              <span v-if="item.is_set_price == 1">￥{{ item.order_amount }}</span>
            </span>
          </div>
        </div>

        <div class="wh_f fz_14 pad_b_20" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">订单数量</div>
          <div class="">
            <span class="centerdq mag_l-20" style="color: #d9001b">
              <span>{{ item.order_goods.length }}</span>
            </span>
          </div>
        </div>

        <div class="wh_f fz_14 pad_b_20" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">业务名称:</div>
          <div class="">
            <span class="centerdq mag_l-20">{{
        item.order_goods[0].goods_name
      }} 【续费1年】</span>
          </div>
        </div>
      </div>

      <div class="pad_20_0">
        <div class="pad_b_10">
          <span class="em fz_16 mag_l-30">请选择支付方式</span>
        </div>
        <div v-if="payItem" class="flex_1 ba_white ddisp" style="padding: 20px 0 40px">
          <div @click="payAdd(item.pay_way)" v-for="(item, index) in payItem" :key="index"
            class="zf_hover bor_1_hui bor_5 mag_l-20 xsgb" style="padding: 10px 0; width: 200px">
            <img class="centerdq mag_l-20" :src="item.icon" alt="" width="36" />
            <span class="mag_l-20 centerdq fz_14">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <el-dialog title="微信支付" :visible.sync="showWxpay" width="700px" center @close="clearTimer">
        <div class="flex flex-col row-center black wz_jz">
          <VueQr class="bicode" :logoScale="20" :margin="0" :dotScale="1" :text="payInfo.config.code_url"></VueQr>
          <div class="m-t-8 pad_10_0" style="font-size: 18px">
            微信扫一扫，完成支付
          </div>
          <!-- <img :src="payInfo.config.code_url" alt="" /> -->
          <div class="pay-money flex">
            <span>需支付金额：</span>
            <span style="font-size: 28px; color: #ff2214">
              {{ order_amount }}
            </span>
          </div>
        </div>
      </el-dialog>
    </div>

    <div class="wz_rigdq" style="padding: 50px 0 20px">
      <el-button @click="$router.go(-1)" type="" style="width: 150px">返回</el-button>
    </div>
  </div>
</template>
</template>

<style>
.ddisp i {
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  top: 4px;
}

.el-icon-circle-plus-outline {
  color: #6bb5b5;
}

.el-icon-remove-outline {
  color: #d9041e;
}

.Items .el-form-item {
  margin-left: 20px;
}

.el-form-item__label {
  white-space: nowrap;
}
</style>
