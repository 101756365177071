import { render, staticRenderFns } from "./sckbzl.vue?vue&type=template&id=7dd15a5b&scoped=true&"
import script from "./sckbzl.vue?vue&type=script&lang=js&"
export * from "./sckbzl.vue?vue&type=script&lang=js&"
import style0 from "./sckbzl.vue?vue&type=style&index=0&id=7dd15a5b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd15a5b",
  null
  
)

export default component.exports