<template>
<div>
  <el-dialog
      title="查看办结材料"
      :visible.sync="dialogVisible"
      width="50%">
    <div class="fz_16" style="padding: 0 40px">
      <div>
        <div class="ddisp">
          <div class="wz_leftdq" style="width: 130px">产品名称:</div>
          <div>服务续费-完税产品-个体户业务xxxxx</div>
        </div>
        <div class="ddisp pad_20_0">
          <div class="wz_leftdq" style="width: 130px">订单编号:</div>
          <div>202309061611498506</div>
        </div>
        <div class="ddisp">
          <div class="wz_leftdq" style="width: 130px">订单金额:</div>
          <div><span class="wz_red">¥1000.00</span></div>
        </div>
        <div class="ddisp pad_20_0">
          <div class="wz_leftdq" style="width: 130px">下单人:</div>
          <div>张三</div>
        </div>
      </div>
      <div class="xt fz_13">
        <div class="wh_f pad_20_0">
          <div class="flex_1 ddisp">
            <div class="wz_leftdq" style="width: 130px">营业执照</div>
            <div class="wz_blue">营业执照电子版.pdf  <i class="el-icon-download"></i></div>
          </div>
          <div class="flex_1 ddisp">
            <div class="wz_leftdq" style="width: 130px">其他材料</div>
            <div class="wz_blue">营业执照电子版.pdf  <i class="el-icon-download"></i></div>
          </div>
        </div>
        <div class="flex_1 ddisp">
          <div class="wz_leftdq" style="width: 130px">备注信息</div>
          <div>这是备注信息</div>
        </div>
      </div>
      <div class="wh_f">
        <div class="flex_1"></div>
        <div class="flo_r pad_30_0">
            <span slot="footer" class="dialog-footer">
            <el-button @click="ckbjcl(3)">关闭</el-button>
            </span>
        </div>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "xF",
  data(){
    return{
      dialogVisible:true
    }
  },
  methods:{
    ckbjcl(e){
      this.$emit('child', e);
    }
  }
}
</script>

<style scoped>

</style>