<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getIndexNumber, getUser } from "./api/api";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      name: "",
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    roload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  mounted() {
    getUser();
    getIndexNumber().then((res) => {
      this.name = res.data.data.shop.name;
      var link = document.querySelector('link[rel*="icon"]');
      link.href = res.data.data.shop.logo;
    });
  },
  components: {},
};
</script>

<style>
#app {
}
</style>
