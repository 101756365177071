<template>
<div>
  <div class="ba_white" style="padding: 10px 20px">
    <div class="fz_18 em pad_20_0 bor_b_hui">个体户代开办-注销申请</div>
    <div class="wh_f pad_20_0">
      <div class="flex_1 pad_r_20">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="注销理由" prop="cancel_reason">
            <el-input type="textarea" resize="none"
                      v-model="form.cancel_reason"
                      maxlength="100"></el-input>
          </el-form-item>
        </el-form>
        <div class="wz_rigdq">
          <el-button @click="add" size="small" type="primary">提交申请</el-button>
          <el-button size="small" @click="$router.push('orderYewu')">取消</el-button>
        </div>
      </div>
      <div class="pad_l_30 fz_16" style="width: 240px;border-left: 1px solid #ebebeb">
        <div class=" em pad_20_0">个体户注销流程</div>
        <el-timeline size="large " :reverse="reverse">
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {getjgthkb_Zhuxiao} from "../../api/api";

export default {
  name: "gth_qhsq",
  data(){
    return{
      reverse:true,
      form:{cancel_reason:''},
      rules:{
        cancel_reason:[{required: true, message: '请输入注销原因', trigger: 'blur'}]
      },
      activities: [{
        content: '发起注销申请',
        timestamp: '2018-04-15'
      }, {
        content: '审核',
        timestamp: '2018-04-13'
      },  {
        content: '上传凭证',
        timestamp: '2018-04-13'
      },  {
        content: '工商注销',
        timestamp: '2018-04-13'
      },  {
        content: '银行注销',
        timestamp: '2018-04-13'
      }, {
        content: '注销完成',
        timestamp: '2018-04-11'
      }]
    }
  },
  methods:{
    add(){
      this.form.sn = this.$store.state.userOrderDetaild.item.sn
      this.form.order_id = this.$store.state.userOrderDetaild.item.id
      getjgthkb_Zhuxiao(this.form).then(res=>{
        if (res.data.code == 1){
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.$router.push('orderYewu')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>