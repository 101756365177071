<template>
  <div>
    <div class="ba_white" style="padding: 10px 20px">
      <div class="fz_18 pad_20_0jiudb">
        <el-tabs v-model="activeName">
          <el-tab-pane label="订单详情" name="1">
            <order_Top></order_Top>
            <el-form
              :rules="rules"
              ref="form"
              inline
              :model="form"
              label-width="100px"
              size="small"
            >
              <div v-if="false" class="cads bor_1_hui pad_10 boxs">
                <!--            <order_Top></order_Top>-->
                <div
                  class="fz_16 em pad_10 boxs"
                  style="border-bottom: #969896 1px dashed"
                >
                  订单信息
                </div>
                <div class="jz boxs" style="padding: 0 40px">
                  <el-descriptions
                    class="ddxx"
                    title=""
                    style="padding: 20px 0"
                  >
                    <el-descriptions-item label="订单编号"
                      ><span>{{
                        $store.state.userOrderDetaild.item.sn
                      }}</span></el-descriptions-item
                    >
                    <!-- <el-descriptions-item label="订单状态"
                      ><span>{{
                        $store.state.userOrderDetaild.item.order_status_desc
                      }}</span></el-descriptions-item
                    > -->
                    <el-descriptions-item label="订单金额"
                      ><span>{{
                        $store.state.userOrderDetaild.item.order_amount
                      }}</span></el-descriptions-item
                    >
                    <el-descriptions-item label="支付状态"
                      ><span>{{
                        pay_status[
                          $store.state.userOrderDetaild.item.pay_status
                        ]
                      }}</span></el-descriptions-item
                    >
                    <el-descriptions-item label="支付方式"
                      ><span>{{
                        pay[$store.state.userOrderDetaild.item.pay_way]
                      }}</span></el-descriptions-item
                    >
                    <el-descriptions-item label="下单时间"
                      ><span>{{
                        $store.state.userOrderDetaild.item.create_time
                      }}</span></el-descriptions-item
                    >

                    <el-descriptions-item label="下单时间"
                      ><span>{{
                        $store.state.userOrderDetaild.item.create_time
                      }}</span></el-descriptions-item
                    >

                    <el-descriptions-item label="订单类型"
                      ><span>{{
                        $store.state.userOrderDetaild.item.order_type_desc
                      }}</span></el-descriptions-item
                    >
                    <!--                <el-descriptions-item label="买家留言"><span>{{ $store.state.userOrderDetaild.item.order_goods[0].spec_value_str }}</span></el-descriptions-item>-->
                    <el-descriptions-item label="订单来源"
                      ><span>{{
                        terminal[
                          $store.state.userOrderDetaild.item.order_terminal
                        ]
                      }}</span></el-descriptions-item
                    >
                  </el-descriptions>
                </div>
              </div>
              <div v-if="false" class="cads bor_1_hui pad_10 mag_t-20 boxs">
                <div
                  class="fz_16 em pad_10 boxs"
                  style="border-bottom: #969896 1px dashed"
                >
                  产品信息
                </div>
                <div class="jz boxs" style="padding: 0 40px">
                  <div>
                    <el-table
                      class="mag_t-20"
                      :data="$store.state.userOrderDetaild.item.order_goods"
                      :header-cell-style="{
                        background: '#f5f8ff',
                        color: '#333333',
                      }"
                      style="width: 100%"
                    >
                      <el-table-column prop="order_id" label="产品编号">
                      </el-table-column>
                      <el-table-column label="产品信息">
                        <template slot-scope="scope">
                          <div>
                            <img
                              class="centerdq"
                              :src="
                                $store.state.userOrderDetaild.item
                                  .order_goods[0].goods_image
                              "
                              alt=""
                              style="width: 50px"
                            />
                            <span class="centerdq mag_l-10">{{
                              $store.state.userOrderDetaild.item.order_goods[0]
                                .goods_name
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="goods_price" label="产品价格(元)">
                      </el-table-column>
                      <el-table-column prop="age" label="产品实付金额(元)">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>

              <div class="cads bor_1_hui pad_10 boxs mag_t-20">
                <div
                  class="fz_16 pad_10 boxs em"
                  style="border-bottom: #969896 1px dashed"
                >
                  个体户开办信息
                </div>
                <!--                个体户开办信息-->
                <div>
                  <div class="pad_10_0">
                    <p
                      class="disp centerdq bor_5"
                      style="
                        width: 5px;
                        background-color: #1784fc;
                        height: 20px;
                      "
                    ></p>
                    <span class="mag_l-10 centerdq em fz_14"
                      >个体户开办信息</span
                    >
                  </div>
                  <div class="wh_f mag_t-10">
                    <div class="flex_1">
                      <el-form-item label="姓名" prop="user_name">
                        <span>{{ form.user_name }}</span>
                      </el-form-item>
                    </div>
                    <div class="flex_1">
                      <el-form-item label="手机号" prop="user_tel">
                        <span>{{ form.user_tel }}</span>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <!--                拟注册信息-->
                <div>
                  <div class="pad_10_0">
                    <p
                      class="disp centerdq bor_5"
                      style="
                        width: 5px;
                        background-color: #1784fc;
                        height: 20px;
                      "
                    ></p>
                    <span class="mag_l-10 centerdq em fz_14">拟注册信息</span>
                  </div>
                  <div>
                    <el-form-item label="企业名称" prop="enterprise_name_one">
                      <span>{{ form.enterprise_name_one }}</span>
                      <span v-if="form.enterprise_name_two"
                        >、{{ form.enterprise_name_two }}</span
                      >
                      <span v-if="form.enterprise_name_three"
                        >、{{ form.enterprise_name_three }}</span
                      >
                      <span v-if="form.enterprise_name_four"
                        >、{{ form.enterprise_name_four }}</span
                      >
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="经营范围" prop="enterprise_name_one">
                      <span>{{ form.business_scope }}</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="注册资本" prop="registered_capital">
                      <span>{{ form.registered_capital }}</span>
                    </el-form-item>
                  </div>
                </div>
                <!--                发票账户信息-->
                <div>
                  <div class="pad_10_0">
                    <p
                      class="disp centerdq bor_5"
                      style="
                        width: 5px;
                        background-color: #1784fc;
                        height: 20px;
                      "
                    ></p>
                    <span class="mag_l-10 centerdq em fz_14"
                      >法人及企业信息</span
                    >
                  </div>
                  <div class="wh_f mag_t-10">
                    <div class="flex_1">
                      <el-form-item label="法人姓名" prop="corporation_name">
                        <span>{{ form.corporation_name }}</span>
                      </el-form-item>
                    </div>
                    <div class="flex_1">
                      <el-form-item
                        label="实名手机号"
                        prop="corporation_tel"
                        class=""
                      >
                        <span>{{ form.corporation_tel }} </span>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="wh_f mag_t-10">
                    <div class="flex_1">
                      <el-form-item
                        label="身份证号"
                        prop="corporation_identification"
                      >
                        <span>{{ form.corporation_identification }}</span>
                      </el-form-item>
                    </div>
                    <div class="flex_1">
                      <el-form-item
                        label="企业住所"
                        prop="enterprise_address"
                        class=""
                      >
                        <span>{{ form.enterprise_address }}</span>
                      </el-form-item>
                    </div>
                  </div>
                  <div>
                    <el-form-item label="邮箱">
                      <span>{{ form.email }}</span>
                    </el-form-item>
                  </div>
                </div>
                <!--                法人及企业信息-->
                <div>
                  <div class="pad_10_0">
                    <p
                      class="disp centerdq bor_5"
                      style="
                        width: 5px;
                        background-color: #1784fc;
                        height: 20px;
                      "
                    ></p>
                    <span class="mag_l-10 centerdq em fz_14">发票账户信息</span>
                  </div>
                  <div class="wh_f mag_t-10">
                    <div class="flex_1">
                      <el-form-item label="发票类型">
                        <span v-if="form.invoice_type">{{
                          invoice[form.invoice_type].label
                        }}</span>
                      </el-form-item>
                    </div>
                    <div class="flex_1">
                      <el-form-item label="发票版位" class="">
                        <span>{{ form.invoice_max }}</span>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="wh_f mag_t-10">
                    <div class="flex_1">
                      <el-form-item label="拟申请数量">
                        <span>{{ form.quantity }}</span>
                      </el-form-item>
                    </div>
                    <div class="flex_1">
                      <el-form-item label="第一主营业务" class="">
                        <span>{{ form.first_business }}</span>
                      </el-form-item>
                    </div>
                  </div>
                  <div>
                    <el-form-item label="服务事项" class="">
                      <el-checkbox-group v-model="service_item">
                        <el-checkbox disabled label="工商设立"></el-checkbox>
                        <el-checkbox disabled label="刻章"></el-checkbox>
                        <el-checkbox disabled label="税务报道"></el-checkbox>
                        <el-checkbox disabled label="申请发票"></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>
                <!--                文件资料-->
                <div>
                  <div class="pad_10_0">
                    <p
                      class="disp centerdq bor_5"
                      style="
                        width: 5px;
                        background-color: #1784fc;
                        height: 20px;
                      "
                    ></p>
                    <span class="mag_l-10 centerdq em fz_14">文件资料</span>
                  </div>
                  <div class="ddisp">
                    <div class="topdq">
                      <el-form-item label="身份证扫描件">
                        <div v-if="this.form.id_card_img">
                          <a
                            class="wz_blue mag_0_20"
                            download="扫描件.pdf"
                            :href="
                              this.$store.state.httpsDown +
                              this.form.id_card_img
                            "
                            target="_blank"
                            >{{
                              form.id_card_img.substring(
                                form.id_card_img.lastIndexOf("/") + 1
                              )
                            }}
                            ↓</a
                          >
                        </div>
                      </el-form-item>
                      <!--                        <span class="mag_0_20 xsgb">删除</span>-->
                    </div>
                  </div>
                </div>
              </div>

              <div class="cads bor_1_hui pad_10 boxs mag_t-20">
                <div
                  class="fz_16 pad_10 boxs em"
                  style="border-bottom: #969896 1px dashed"
                >
                  审核信息
                </div>
                <div class="mag_t-10">
                  <el-form-item label="审核意见">
                    <span>{{ form.review_comments }}</span>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="附件">
                    <div v-if="this.form.review_file">
                      <a
                        class="wz_blue mag_0_20"
                        download="扫描件.pdf"
                        :href="
                          this.$store.state.httpsDown + this.form.review_file
                        "
                        target="_blank"
                        >{{
                          form.review_file.substring(
                            form.review_file.lastIndexOf("/") + 1
                          )
                        }}
                        ↓</a
                      >
                    </div>
                  </el-form-item>
                </div>
              </div>

              <div class="cads bor_1_hui pad_10 boxs mag_t-20">
                <div
                  class="fz_16 pad_10 boxs em"
                  style="border-bottom: #969896 1px dashed"
                >
                  办结信息
                </div>
                <div class="wh_f mag_t-10">
                  <div class="flex_1">
                    <el-form-item label="执照下证日期">
                      <span>{{ form.get_certificate_date }}</span>
                    </el-form-item>
                  </div>
                  <div class="flex_1">
                    <el-form-item label="营业执照">
                      <div v-if="this.form.business_license_file">
                        <a
                          class="wz_blue mag_0_20"
                          download="扫描件.pdf"
                          :href="
                            this.$store.state.httpsDown +
                            this.form.business_license_file
                          "
                          target="_blank"
                          >{{
                            form.business_license_file.substring(
                              form.business_license_file.lastIndexOf("/") + 1
                            )
                          }}
                          ↓</a
                        >
                      </div>
                    </el-form-item>
                  </div>
                </div>
                <div class="wh_f">
                  <div class="flex_1">
                    <el-form-item label="其他材料">
                      <div v-if="this.form.other_file">
                        <a
                          class="wz_blue mag_0_20"
                          download="扫描件.pdf"
                          :href="
                            this.$store.state.httpsDown + this.form.other_file
                          "
                          target="_blank"
                          >{{
                            form.other_file.substring(
                              form.other_file.lastIndexOf("/") + 1
                            )
                          }}
                          ↓</a
                        >
                      </div>
                    </el-form-item>
                  </div>
                  <div class="flex_1">
                    <el-form-item label="备注信息">
                      <span>{{ form.completion_remark }}</span>
                    </el-form-item>
                  </div>
                </div>
              </div>

              <div class="cads bor_1_hui pad_10 boxs mag_t-20">
                <div
                  class="fz_16 pad_10 boxs em"
                  style="border-bottom: #969896 1px dashed"
                >
                  税务认证确认说明
                </div>
                <div class="mag_t-10">
                  <el-form-item label="确认说明">
                    <span>{{ form.tax_cert_confirmation }}</span>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="附件">
                    <div v-if="this.form.tax_cert_file">
                      <a
                        class="wz_blue mag_0_20"
                        download="扫描件.pdf"
                        :href="
                          this.$store.state.httpsDown + this.form.tax_cert_file
                        "
                        target="_blank"
                        >{{
                          form.tax_cert_file.substring(
                            form.tax_cert_file.lastIndexOf("/") + 1
                          )
                        }}
                        ↓</a
                      >
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="附加业务" name="2">
            <!--          流程信息-->
            <div
              v-for="(item, index) in form_1.add"
              :key="index"
              class="cads bor_1_hui pad_10 boxs mag_t-20"
            >
              <div
                class="fz_16 pad_10 boxs em"
                style="border-bottom: #969896 1px dashed"
              >
                变更业务
              </div>
              <div>
                <div class="fz_16 pad_10 boxs em">申请信息</div>
                <div>
                  <el-form ref="form" inline label-width="100px" size="small">
                    <div>
                      <el-form-item label="个体户名称">
                        <span>{{ item.individual_name }}</span>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="经营范围">
                        <span>{{ item.business_scope }}</span>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="变更前地址">
                        <span>{{ item.past_address }}</span>
                      </el-form-item>
                    </div>
                    <div class="split_2">
                      <div>
                        <el-form-item label="营业执照">
                          <div v-if="item.business_license_file">
                            <a
                              class="wz_blue mag_0_20"
                              download="文件.pdf"
                              :href="
                                $store.state.httpsDown +
                                item.business_license_file
                              "
                              target="_blank"
                              >{{
                                item.business_license_file.substring(
                                  item.business_license_file.lastIndexOf("/") +
                                    1
                                )
                              }}
                              ↓</a
                            >
                            <!--                            <span>{{form_1.add.corporation_name}}</span>-->
                          </div>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="税务体检报告">
                          <div v-if="item.tax_examination_report">
                            <a
                              class="wz_blue mag_0_20"
                              download="文件.pdf"
                              :href="
                                $store.state.httpsDown +
                                item.tax_examination_report
                              "
                              target="_blank"
                              >{{
                                item.tax_examination_report.substring(
                                  item.tax_examination_report.lastIndexOf("/") +
                                    1
                                )
                              }}
                              ↓</a
                            >
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                    <div>
                      <el-form-item label="备注信息">
                        <span>{{ item.remark }}</span>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </div>
              <div>
                <div class="fz_16 pad_10 boxs em">补充信息</div>
                <div>
                  <el-form ref="form" inline label-width="100px" size="small">
                    <div>
                      <el-form-item label="变更后地址">
                        <span>{{ item.new_address }}</span>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="需要额外付费">
                        <span v-if="item.need_pay == 0">否</span>
                        <span v-if="item.need_pay == 1">是</span>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="需付款金额">
                        <span>{{ item.payment_amount }}</span>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="付费凭证">
                        <div v-if="item.payment_voucher">
                          <a
                            class="wz_blue mag_0_20"
                            download="凭证.pdf"
                            :href="
                              $store.state.httpsDown + item.payment_voucher
                            "
                            target="_blank"
                            >{{
                              item.payment_voucher.substring(
                                item.payment_voucher.lastIndexOf("/") + 1
                              )
                            }}
                            ↓</a
                          >
                        </div>
                      </el-form-item>
                    </div>
                    <div class="fz_16 pad_10 boxs em">新营业执照</div>
                    <div>
                      <el-form-item label="新执照">
                        <div v-if="item.new_business_license_file">
                          <a
                            class="wz_blue mag_0_20"
                            download="文件.pdf"
                            :href="
                              $store.state.httpsDown +
                              item.new_business_license_file
                            "
                            target="_blank"
                            >{{
                              item.new_business_license_file.substring(
                                item.new_business_license_file.lastIndexOf(
                                  "/"
                                ) + 1
                              )
                            }}
                            ↓</a
                          >
                        </div>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
            <!--          迁回-->
            <div class="cads bor_1_hui pad_10 boxs mag_t-20">
              <div class="fz_16 pad_10 boxs em">迁回业务</div>
              <div>
                <el-form
                  ref="form"
                  inline
                  :model="form"
                  label-width="100px"
                  size="small"
                >
                  <div>
                    <el-form-item label="迁回原因">
                      <span>{{ form_1.QH.back_reason }}</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="服务协议">
                      <div v-if="this.form_1.QH.service_agreement">
                        <a
                          class="wz_blue mag_0_20"
                          download="扫描件.pdf"
                          :href="
                            this.$store.state.httpsDown +
                            this.form_1.QH.service_agreement
                          "
                          target="_blank"
                          >{{
                            form_1.QH.service_agreement.substring(
                              form_1.QH.service_agreement.lastIndexOf("/") + 1
                            )
                          }}
                          ↓</a
                        >
                      </div>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="需要额外付费">
                      <span v-if="form_1.QH.need_pay == 0">否</span>
                      <span v-if="form_1.QH.need_pay == 1">是</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="需要额外支付">
                      <span>{{ form_1.QH.payment_amount }}</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="付费凭证">
                      <div v-if="this.form_1.QH.payment_voucher">
                        <a
                          class="wz_blue mag_0_20"
                          download="扫描件.pdf"
                          :href="
                            $store.state.httpsDown +
                            this.form_1.QH.payment_voucher
                          "
                          target="_blank"
                          >{{
                            form_1.QH.payment_voucher.substring(
                              form_1.QH.payment_voucher.lastIndexOf("/") + 1
                            )
                          }}
                          ↓</a
                        >
                      </div>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
            <!--          注销-->
            <div class="cads bor_1_hui pad_10 boxs mag_t-20">
              <div class="fz_16 pad_10 boxs em">注销业务</div>
              <div>
                <el-form
                  ref="form"
                  inline
                  :model="form"
                  label-width="100px"
                  size="small"
                >
                  <div>
                    <el-form-item label="注销原因">
                      <span>{{ form_1.ZX.cancel_reason }}</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="已办公税银">
                      <span>{{ form_1.ZX.tax_processed }}</span>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="注销方式">
                      <el-radio v-model="form_1.ZX.cancel_type" :label="0"
                        >简易</el-radio
                      >
                      <el-radio v-model="form_1.ZX.cancel_type" :label="1"
                        >一般</el-radio
                      >
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="流程日志" name="3">
            <log></log>
            <!--          流程信息-->
            <!--          <el-timeline style="width: 100%;">-->
            <!--            <el-timeline-item-->
            <!--                class=""-->
            <!--                v-for="(item,index) in this.form.orderLog"-->
            <!--                :key="index"-->
            <!--                placement="top">-->
            <!--              <div>-->
            <!--                <div class="wh_f">-->
            <!--                  <div class="flex_1"><span class="fz_16">{{ item.content }}</span></div>-->
            <!--                  <div class="flex_r fz_16">-->
            <!--                    <span class="wz_hui">{{item.create_time}}</span>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </el-timeline-item>-->
            <!--          </el-timeline>-->
          </el-tab-pane>
        </el-tabs>
        <div class="wz_rigdq pad_t_20">
          <el-button @click="$router.push('/orderYewu')">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getjgthkb_Zhuxiao,
  getKBAddress,
  getKBDetail,
  getKBQH,
  getKBZX,
} from "../../api/api";

export default {
  name: "gth_qhsq",
  data() {
    return {
      chicked: ["否", "是"],
      id_card_img: "",
      invoice: [
        {
          value: "增值税普通发票",
          label: "增值税普通发票",
        },
        {
          value: "增值税专用发票",
          label: "增值税专用发票",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      SY: ["工商银行", "税务", "银行对公账户"],
      ZX: ["简易", "一般"],
      pay: ["余额支付", "微信支付", "支付宝支付"],
      terminal: [
        "微信小程序",
        "微信公众号",
        "手机H5",
        "Pc",
        "苹果app",
        "安卓app",
      ],
      activeName: "1",
      reverse: true,
      pay_status: ["未支付", "已支付"],
      list: {
        active: 2,
        order: [
          "下单支付费用",
          "上传开办资料",
          "资料预审核",
          "等待办结",
          "税务认证",
        ],
      },
      lab: [
        { label: "工商设立" },
        { label: "刻章" },
        { label: "税务报道" },
        { label: "申请发票" },
      ],
      service_item: [], //服务事项---复选框的值
      rules: {
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        姓名: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        user_tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        enterprise_name_one: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        tax_cert_confirmation: [
          { required: true, message: "请输入确认说明", trigger: "blur" },
        ],
        qiyemingcheng2: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        business_scope: [
          { required: true, message: "请输入经营范围", trigger: "blur" },
        ],
        completion_remark: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
        registered_capital: [
          { required: true, message: "请输入注册资本", trigger: "blur" },
        ],
        id_card_img: [
          { required: true, message: "请上传身份证扫描件", trigger: "blur" },
        ],
        corporation_name: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        get_certificate_date: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        tax_cert_file: [
          { required: true, message: "请选择上传附件", trigger: "change" },
        ],
      },
      form: {},
      form_1: {
        add: [],
        QH: {},
        ZX: {},
      },
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  methods: {
    add() {
      this.form.sn = this.$store.state.userOrderDetaild.sn;
      this.form.order_id = this.$store.state.userOrderDetaild.id;
      getjgthkb_Zhuxiao(this.form).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.$router.push("orderYewu");
        }
      });
    },
  },
  computed: {
    selectedServices() {
      // 将服务项目字符串转换为数组
      return this.form.service_item.split(", ");
    },
  },
  mounted() {
    getKBQH({ sn: this.$store.state.userOrderDetaild.item.sn }).then((res) => {
      this.form_1.QH = res.data.data;
    });
    getKBZX({ sn: this.$store.state.userOrderDetaild.item.sn }).then((res) => {
      console.log(333, res);
      this.form_1.ZX = res.data.data;
    });

    this.form_1.add = this.$store.state.userOrderDetaild.item.chaddressList;

    getKBDetail({
      sn: this.$store.state.userOrderDetaild.item.sn,
    }).then((res) => {
      this.form = res.data.data;
      if (this.form.length == 0) {
        this.form = {
          isShow: false,
          user_name: this.$store.state.userOrderDetaild.item.user_name,
          user_tel: this.$store.state.userOrderDetaild.item.user_tel,
        };
      } else {
        this.form.isShow = true;
        this.service_item = this.selectedServices;
        this.$store.state.logList = res.orderLog;
      }
      this.$store.state.userOrderDetaild.list = res.data.data;
    });
  },
};
</script>

<style scoped>
.cads {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
</style>
