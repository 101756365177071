<template>
  <div>
    <div style="width: 1200px" class="ba_white mag_b_20 wh1200 jz">
      <div class="pad_20 wh_f bor_b_xu">
        <div style="width: 120px">
          <span class="centerdq"
            ><i class="el-icon-time fz_26 centerdq" style="color: #ff2c3c"></i
          ></span>
          <span class="centerdq mag_l-10 fz_20 em">待支付</span>
        </div>
      </div>

      <div class="bor_b_xu" v-for="(item, index) in order_items">
        <div class="wh_f fz_14 pad_20_0" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">订单编号:</div>
          <div class="">
            <span class="centerdq mag_l-20">{{ item.sn }}</span>
          </div>
        </div>
        <div class="wh_f fz_14 pad_b_20" style="color: #555555" v-if="!!sn">
          <div class="wz_rigdq" style="width: 130px">续费订单:</div>
          <div class="">
            <span class="centerdq mag_l-20">{{ sn }}</span>
          </div>
        </div>

        <div class="wh_f fz_14 pad_b_20" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">订单价格:</div>
          <div class="">
            <span class="centerdq mag_l-20" style="color: #d9001b">
              <span v-if="item.is_set_price == 0">待报价</span>
              <span v-if="item.is_set_price == 1"
                >￥{{ item.order_amount }}</span
              >
            </span>
          </div>
        </div>
        <div class="wh_f fz_14 pad_b_20" style="color: #555555">
          <div class="wz_rigdq" style="width: 130px">业务名称:</div>
          <div class="">
            <span class="centerdq mag_l-20">{{
              item.order_goods[0].goods_name
            }}</span>
          </div>
        </div>
      </div>

      <div class="pad_20_0">
        <div class="pad_b_10">
          <span class="em fz_16 mag_l-30">请选择支付方式</span>
        </div>
        <div class="flex_1 ba_white ddisp" style="padding: 20px 0 40px">
          <div
            @click="payAdd(item.pay_way)"
            v-for="(item, index) in list"
            :key="index"
            class="zf_hover bor_1_hui bor_5 mag_l-20 xsgb"
            style="padding: 10px 0; width: 200px"
          >
            <img class="centerdq mag_l-20" :src="item.icon" alt="" width="36" />
            <span class="mag_l-20 centerdq fz_14">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <el-dialog
        title="微信支付"
        :visible.sync="showWxpay"
        width="700px"
        center
        @close="clearTimer"
      >
        <div class="flex flex-col row-center black wz_jz">
          <!-- <img class="pay-code" :src="payInfo.config.code_url" alt=""/> -->
          <VueQr
            class="bicode"
            :logoScale="20"
            :margin="0"
            :dotScale="1"
            :text="payInfo.config.code_url"
          ></VueQr>
          <div class="m-t-8 pad_10_0" style="font-size: 18px">
            微信扫一扫，完成支付
          </div>
          <!-- <img :src="payInfo.config.code_url" alt="" /> -->
          <div class="pay-money flex">
            <span>需支付金额：</span>
            <span style="font-size: 28px; color: #ff2214">
              {{ order_amount }}
            </span>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getApiPwy, getorderdetail, getPay, getApiRenew } from "../api/api";
import VueQr from "vue-qr";
export default {
  name: "Pay_SE",
  props: {
    list: {
      type: Array,
      required: true,
    },
    order_ids: [],
    renew: false,
    sn: null,
  },
  components: {
    VueQr,
  },
  data() {
    return {
      order_amount: "0.00",
      order_items: [],
      qcurl: false,
      jm: false,
      dialogVisible: false,
      item: {},
      pay: [],
      showWxpay: false,
      showAlipay: false,
      payWayArr: [],
      payInfo: {
        config: {
          code_url: "",
          order_amount: 0,
        },
      },
      cancelTime: 0,
      alipayHtml: "",
    };
  },
  methods: {
    // 支付
    async payAdd(payWay) {
      try {
        var result = {};
        var order_item = this.order_items[0];
        result = await getPay({
          order_id: order_item.id,
          pay_way: payWay,
          from: "order",
        });

        var { data, code, msg } = result;
        // 关闭加载蒙版
        // loading.close();
        if (data.code == 1 && data.data.pay_way == 2) {
          console.log("进入微信支付", data);
          // 调用微信支付 存储data
          this.payInfo = data.data;
          this.showWxpay = true;
          // 调用支付
          this.createTimer();
        } else if (data.code == 1 && data.data.pay_way == 3) {
          // 支付宝支付
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = data.config; // data.config就是接口返回的form 表单字符串
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      } catch (error) {
        // loading.close();
      }
    },
    // 微信支付
    createTimer() {
      this.jm = true;
      // 刷新时间
      var cancelTime = 4 * 1000;
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getOrder();
      }, cancelTime);
    },

    clearTimer() {
      clearInterval(this.timer);
    },
    async getRenew(id) {
      let result = await getApiRenew({ id, renew_sn: this.sn }, this.jm);

      let {
        data: {
          code = -1,
          data: { pay_status = -1 },
        },
      } = result;

      console.log("续费", pay_status, code);
      if (code == 1) {
        console.log("进入检查支付是否成功阶段", pay_status);

        if (pay_status == 1) {
          clearInterval(this.timer);
          this.showWxpay = false;
          this.$message({
            message: "支付成功",
            type: "success",
          });
          this.$emit("custom-event");
        }
      }
    },
    async getOrder(id) {
      id = id || this.$store.state.order_id;

      if (this.renew) {
        this.getRenew(id);
        return;
      }

      getApiPwy(
        {
          id,
        },
        this.jm
      ).then((res) => {
        console.log("支付res", res.data.pay_status);
        var code = res.data.code;

        if (code == 1) {
          console.log("进入检查支付是否成功阶段", res.data.data.pay_status);
          // this.order = res.data;
          if (res.data.data.pay_status == 1) {
            clearInterval(this.timer);
            this.showWxpay = false;
            this.$message({
              message: "支付成功",
              type: "success",
            });
            this.$emit("custom-event");
          }
        }
      });
    },
  },
  mounted() {
    console.log("this.order_ids", this.order_ids);

    if (this.$store.state.order_id) {
      getorderdetail({
        id: this.$store.state.order_id,
      }).then((res) => {
        var order_item = res.data.data;
        console.log("order_item", order_item);
        this.order_items = [order_item];
        this.order_amount = order_item.order_amount;
        // this.$store.commit("userOrderDetaild", order_item);
      });
      return;
    }
  },
};
</script>

<style scoped></style>
