<template>
  <div class="xd" style="background-color: #f6f6f6; min-width: 1200px">
    <div class="wh_max" style="z-index: 100">
      <TopMenu></TopMenu>
    </div>
    <transition name="router_gd">
      <router-view style="min-width: 1200px; min-height: calc(100vh - 64px)" />
    </transition>
    <!--  :style="{'height': 'calc(100vh - ' + bottomMenuHeight + 'px)'}"-->
    <ReturnTop class="pm" style="right: 0; top: 50%; z-index: 10"></ReturnTop>
    <Footer></Footer>
    <BottomMenu style="min-width: 1200px"></BottomMenu>
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
export default {
  name: "home",
  components: { Footer },
  data() {
    return {
      bottomMenuHeight: null,
      isTop: true,
    };
  },
  created() {
    if (this.$route.path === "/home") {
      this.$router.replace("Index");
    }
    // this.$router.push('Index')
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop >= 100) {
        this.isTop = false;
        // 添加固定样式
        // 可以通过修改组件的样式类名或直接修改内联样式来实现
      } else {
        this.isTop = true;
        // 移除固定样式
        // 恢复组件的默认样式
      }
    },
  },
  mounted() {
    // let boxList = document.querySelectorAll('.boxCss')
    // window.addEventListener('scroll',scrollLoad)
    // function scrollLoad(){
    //   let taggervalue= window.innerHeight*0.9
    //   boxList.forEach(box=>{
    //     let boxTop = box.getBoundingClientRect().top
    //     // console.log(boxTop)
    //     // console.log("🚀")
    //     if (boxTop<=taggervalue){
    //       console.log("🚀")
    //       box.classList.add('show-center')
    //       // box.classList.add('animate__animated')
    //       // box.classList.add('animate__backInRight')
    //     }else{
    //       // box.classList.remove('show-center')
    //     }
    //   })
    // }
    // window.addEventListener('scroll', this.handleScroll);
    // 获取保存在 data-he 属性中的值 bottomMenus
    // 获取 BottomMenu 的高度
    // this.bottomMenuHeight = document.getElementById('bottomMenus').clientHeight;
    // this.bottomMenuHeight = this.bottomMenuHeight + 64
    // console.log(this.bottomMenuHeight)
  },
};
</script>

<style scoped>
.ttop {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
}
</style>
