import request from "/src/utils/request";
import req from "/src/utils/req";
// 按需导出每个api请求

/*查询导航栏数据 首页*/
// export function getTopList(data){
//     return request({ url:'goodsCategory/lists', params:data })
// }
/*查询导航栏数据 首页*/
export function getTopList(data) {
  return request({ url: "/goodsCategory/lists", params: data });
}
/*查询导航栏数据 首页*/
export function getGoodstitle(data) {
  return request({ url: "order_ex.OrderExComm/stepSectionList", params: data });
}
/*查询首页 轮播图 底部导航栏 数据 首页*/
export function getImgUrlTop(data) {
  return request({ url: "Pc/getPage", params: data });
}
/*店铺信息 首页*/
export function getIndexNumber(data) {
  return request({ url: "pc/commonData", params: data });
}

/*小程序首页导航栏详情*/
export function apicmsDetail(data) {
  return request({ url: "cms.BainingCarousel/lists", params: data });
}

/*安税业务 首页*/
export function getYewuIndex(data) {
  return request({ url: "goodsCategory/lists", params: data });
}
/*资讯列表 首页 政策法规*/
export function getFaGui(data) {
  return request({ url: "cms.BainingArticle/lists", params: data });
}
/*登录页获取海报*/
export function getloginHaibao(data) {
  return request({ url: "Pc/getPage", params: data });
}
/*发送验证码*/
export function getloginCode(data) {
  return request.post("/user/resetPasswordCaptcha", data);
}
/*获取用户基本信息*/
export function getUser(data) {
  var reslut = request.get("user/info", data);
  reslut.then((res) => {
    let {
      data: { data, code },
    } = res;
    if (code === 1 && !!data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  });

  return reslut;
}
/*注册*/
export function getuserInfo(data) {
  return request.post("/register/register", data);
}
/*修改密码*/
export function getUpdaPassword(data) {
  return request.post("user/resetPassword", data);
}
/*登录*/
export function getLogin(data) {
  return request.post("login/account", data);
}
/*服务协议 隐私政策*/
export function getXieyi(data) {
  return request.get("config/getPolicyAgreement", data);
}
/*用户钱包*/
export function getQianbao(data) {
  return request.get("/order/staticOrderConsumption", data);
}
/*修改密码*/
export function getUpdaPwd(data) {
  return request.post("user/changePassword", data);
}
/*编辑个人信息*/
export function getUserInfo(data) {
  return request.post("pc/setUserInfo", data);
}
// /*获取订单*/
// export function getYewuOrderList(data) {
//     return request.post('goods.goods/lists', data);
// }
// 查询产品
export function getLookShop(params) {
  return request.get("goods/lists", { params: params });
}
// 获取产品二维码
export function getQc(params) {
  return request.get("goods/getqc", { params: params });
}
// export function getAddShop(params) {
//     return request.post('order/placeOrder', { params: params });
// }
// 下单
export function getAddShop(data) {
  return request.post("order/placeOrder", data);
}
//查询订单支付信息-待支付
export function getpayOrder(params) {
  return request.get("/order/detail", { params: params });
}
// 查询支付方式
export function getpayType(params) {
  return request.get("pay/payway", { params: params });
}
// 查询产品类型
export function getOrderYewu(params) {
  return request.get("order_ex.OrderExComm/stepSectionList", {
    params: params,
  });
}
// 查询用户资料列表
export function getZLList(params) {
  return request.get("/UserInformation/lists", { params: params });
}
// 添加用户资料
export function addZL(data) {
  return request.post("/UserInformation/add", data);
}

// 支付
export function getPay(data) {
  return request.post("pay/prepay", data);
}
// 订单列表
export function getorderList(params) {
  return request.get("order/lists", { params: params });
}
// 个体户开办详情
export function getKBDetail(params) {
  return request.get("order_ex.OrderExIndividual/detail", { params: params });
}

// 首页获取版权信息
export function getBQview(params) {
  return request.get("ShopSetting/getRecordInfo", { params: params });
}
// 个体户开票信息
export function getgthkpxxDetail(params) {
  return request.get("order_ex.OrderExIndividualInvoicing/detail", {
    params: params,
  });
}
// 自然人开票信息
export function getzrrkpDetail(params) {
  return request.get("order_ex.OrderExPersonInvoicing/detail", {
    params: params,
  });
}
// 返税信息
export function getfsDetail(params) {
  return request.get("order_ex.OrderExRefund/detail", { params: params });
}
// 纳税策划信息
export function getnschDetail(params) {
  return request.get("order_ex.OrderExTaxPlan/detail", { params: params });
}
// 获取登录页面的信息
export function getLoginlog(params) {
  return request.get("config/getConfig", { params: params });
}
// 咨询信息
export function getzxDetail(params) {
  return request.get("order_ex.OrderExReportConsult/detail", {
    params: params,
  });
}
// 获取报告信息
export function gethqbgDetail(params) {
  return request.get("order_ex.OrderExReportTax/detail", { params: params });
}
// 解读报告信息
export function getjdbgDetail(params) {
  return request.get("order_ex.OrderExReportInterpret/detail", {
    params: params,
  });
}

//获取售后记录
export function getShouHou(params) {
  return request.get("/after_sale/lists", { params: params });
}

// 个体户开办 地址变更详情
export function getKBAddress(params) {
  return request.get("order_ex.OrderExIndividualChaddress/detail", {
    params: params,
  });
}
// 个体户开办 迁回详情
export function getKBQH(params) {
  return request.get("order_ex.OrderExIndividualBack/detail", {
    params: params,
  });
}
// 个体户开办 注销详情
export function getKBZX(params) {
  return request.get("order_ex.OrderExIndividualCancel/detail", {
    params: params,
  });
}

// 查看政策法规详情
export function getCaseDetail(params) {
  return request.get("cms.BainingArticle/detail_full", { params: params });
}
// 查看政策法规列表
export function getCaseList(params) {
  return request.get("cms.BainingArticle/lists", { params: params });
}

// 支付
export function getApiPwy(params, jm) {
  if (jm) {
    return req.get("order/detail", { params: params });
  }
  return request.get("order/detail", { params: params });
}

// 续费
export function getApiRenew(params, jm) {
  if (jm) {
    return req.get("order/renew", { params: params });
  }
  return request.get("order/renew", { params: params });
}

// 首页轮播图
export function getBannerList(params) {
  return request.get("cms.BainingHotService/lists", { params: params });
}
// 订单详情
export function getorderdetail(params) {
  return request.get("order/detail", { params: params });
}
// 订单节点详情
export function getOrderActive(params) {
  return request.get("/order_ex.OrderExComm/stepList", { params: params });
}
// 获取报告_提交核验
export function gethqbg_Heyan(params) {
  return request.post("order_ex.OrderExReportTax/add", params);
}
// 解读报告_上传报告
export function getjdbg_Add(params) {
  return request.post("order_ex.OrderExReportInterpret/add", params);
}
// 个体户开办_提交开办资料
export function getjgthkb_Add(params) {
  return request.post("order_ex.OrderExIndividual/add", params);
}
// 个体户开办_提交开办资料
export function getgth_Add(params) {
  return request.post("order_ex.OrderExIndividualChaddress/add", params);
}
// 个体户开办_迁回申请
export function getjgthkb_Qianhui(params) {
  return request.post("order_ex.OrderExIndividualBack/add", params);
}
// 个体户开办_注销申请
export function getjgthkb_Zhuxiao(params) {
  return request.post("order_ex.OrderExIndividualCancel/add", params);
}
// 个体户代开票_提交开票资料
export function getjgthdkp_Ziliao(params) {
  return request.post("order_ex.OrderExIndividualInvoicing/add", params);
}
// 自然人开票_提交开票资料
export function getjZrrkp_Ziliao(params) {
  return request.post("order_ex.OrderExPersonInvoicing/add", params);
}
// 纳税策划_提交
export function getjNschAdd(params) {
  return request.post("order_ex.OrderExTaxPlan/add", params);
}
// 个体户开办续费
export function getOrderXF(params) {
  return request.post("/order/copyOrder", params);
}

// export function getpayType(data) {
//     return request.post('pay/payway', data);
// }
// export function getPay(params) {
//     return request.post('pay/prepay', { params: params });
// }
