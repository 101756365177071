<template>
  <div>
    <div class="jz wh_1200" style="padding-bottom: 30px">
      <div class="pad_30_0 fz_18 em">填写纳税策划表单</div>
      <TopActive :value="list"></TopActive>
      <div>
        <div class="ba_white mag_t-30">
          <div class="pad_20">
            <el-form
              ref="myForm"
              :model="form"
              size="small"
              label-width="200px"
              :rules="rules"
            >
              <div class="em" style="padding: 10px 0 10px 20px">个人信息</div>
              <div class="ddisp bor_b_xu split_2">
                <el-form-item label="姓名" prop="user_name">
                  <el-input
                    v-model="form.user_name"
                    placeholder="请输入姓名"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="user_tel">
                  <el-input
                    v-model="form.user_tel"
                    placeholder="请输入手机号"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
              <!--              企业信息表-->
              <div class="em split_2" style="padding: 20px 0 20px 20px">
                <div>企业信息表</div>
                <div class="wz_rigdq wz_red fz_13 xt">
                  填写完整企业信息表，可联系客服领取300元代金券，可下次下单联系客服使用
                </div>
              </div>
              <div>
                <div>
                  <div class="ddisp">
                    <div>
                      <el-form-item label="公司名称" prop="enterprise_name">
                        <el-input
                          v-model="form.enterprise_name"
                          placeholder="请输入公司名称"
                          style="width: 300px"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="主体类型" prop="principal_type">
                        <el-select
                          v-model="form.principal_type"
                          placeholder="请选择"
                          style="width: 300px"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="ddisp">
                    <div>
                      <el-form-item label="注册地" prop="enterprise_addr">
                        <el-input
                          v-model="form.enterprise_addr"
                          placeholder="请输入注册地"
                          style="width: 300px"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="注册时间" prop="reg_date">
                        <el-date-picker
                          v-model="form.reg_date"
                          value-format="yyyy-MM-dd hh:mm:ss"
                          type="date"
                          style="width: 300px"
                          placeholder="选择日期"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <div>
                    <el-form-item label="实际经营业务">
                      <el-input
                        type="textarea"
                        resize="none"
                        placeholder="请输入实际经营业务"
                        v-model="form.opera_business"
                        maxlength="100"
                        style="width: 740px"
                      >
                      </el-input>
                    </el-form-item>
                    <div class="ddisp">
                      <div>
                        <el-form-item label="上一年收入金额">
                          <el-input
                            type="Number"
                            v-model="form.previous_annua_income"
                            placeholder="单位: 元"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="上一年度应缴增值税">
                          <el-input
                            type="Number"
                            v-model="form.previous_add_tax"
                            placeholder="单位: 元"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="ddisp">
                      <div>
                        <el-form-item label="预计本年度累计收入金额">
                          <el-input
                            type="Number"
                            v-model="form.accumulated_income_year"
                            placeholder="单位: 元"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="预计本年度应缴增值税">
                          <el-input
                            type="Number"
                            v-model="form.add_tax_year"
                            placeholder="单位: 元"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="ddisp">
                      <div>
                        <el-form-item label="账面上年度利润金额">
                          <el-input
                            type="Number"
                            v-model="form.previous_proift_year"
                            placeholder="单位: 元"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="账面本年度利润金额">
                          <el-input
                            type="Number"
                            v-model="form.proift_year"
                            placeholder="单位: 元"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div>
                      <el-form-item label="希望解决的税务问题">
                        <el-input
                          type="textarea"
                          resize="none"
                          placeholder="请输入希望解决的税务问题"
                          v-model="form.need_solve_tax_issues"
                          maxlength="100"
                          style="width: 740px"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <!--企业问诊单-->
                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  <div>企业问诊单</div>
                  <div class="wz_rigdq wz_red fz_13 xt">
                    填写完整企业问诊单，可联系客服领取500元代金券，可下次下单联系客服使用
                  </div>
                </div>
                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  一、大致描述公司规模等情况
                </div>
                <div>
                  <div class="ddisp">
                    <div>
                      <el-form-item label="企业人员数量">
                        <el-input
                          type="Number"
                          v-model="form.enterprise_person_number"
                          placeholder="请输入企业人员数量"
                          style="width: 300px"
                        ></el-input>
                      </el-form-item>
                    </div>

                    <div>
                      <el-form-item label="企业人员结构">
                        <el-input
                          v-model="form.enterprise_person_structure"
                          placeholder="请输入企业人员结构"
                          style="width: 300px"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="ddisp">
                    <div>
                      <el-form-item label="运营主体数量">
                        <el-input
                          type="Number"
                          v-model="form.operation_entity_number"
                          placeholder="请输入运营主体数量"
                          style="width: 300px"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item label="运营主体是否核定">
                        <el-select
                          v-model="form.operation_entity_need"
                          placeholder="请选择"
                          style="width: 300px"
                        >
                          <el-option
                            v-for="item in caiding"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div>
                    <el-form-item label="主要销售模式或渠道及销售情况">
                      <el-input
                        type="textarea"
                        resize="none"
                        placeholder="请输入主要销售模式或渠道及销售情况"
                        v-model="form.sale_info"
                        maxlength="100"
                        style="width: 800px"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item label="近3年影响公司收入的重大项目">
                      <el-input
                        type="textarea"
                        resize="none"
                        placeholder="请输入近3年影响公司收入的重大项目"
                        v-model="form.mojor_project"
                        maxlength="100"
                        style="width: 800px"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item label="合同签订和履约情况">
                      <el-input
                        type="textarea"
                        resize="none"
                        placeholder="请输入合同签订和履约情况"
                        v-model="form.contract_info"
                        maxlength="100"
                        style="width: 800px"
                      >
                      </el-input>
                    </el-form-item>

                    <div class="em split_2" style="padding: 20px 0 20px 20px">
                      二、企业税负情况
                    </div>
                    <div class="ddisp">
                      <div>
                        <el-form-item label="开票的类目">
                          <el-input
                            v-model="form.invoice_category"
                            placeholder="请输入开票的类目"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>

                      <div>
                        <el-form-item label="不开票的收入占比情况">
                          <el-input
                            v-model="form.not_invoice_revenue"
                            placeholder="请输入不开票的收入占比情况"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="ddisp pad_b_10">
                      <div class="ddisp">
                        <div class="wz_rigdq" style="width: 200px">
                          <span class="fz_13 mag_r-10" style="color: #756266"
                            >专属占比</span
                          >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="公司进购商品、设备或支付费用时"
                            placement="top-start"
                          >
                            <img
                              class="centerdq mag_r-10"
                              src="../../assets/wenhao.png"
                              width="18"
                              alt=""
                            />
                          </el-tooltip>
                        </div>
                        <div>
                          <el-input
                            type="Number"
                            v-model="form.proprotion_ticket_special"
                            placeholder="专属占比"
                            style="width: 300px"
                          ></el-input>
                        </div>
                      </div>
                      <div class="ddisp">
                        <div class="wz_rigdq" style="width: 200px">
                          <span class="fz_13 mag_r-10" style="color: #756266"
                            >普票占比</span
                          >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="公司进购商品、设备或支付费用时"
                            placement="top-start"
                          >
                            <img
                              class="centerdq mag_r-10"
                              src="../../assets/wenhao.png"
                              width="18"
                              alt=""
                            />
                          </el-tooltip>
                        </div>
                        <div>
                          <el-input
                            type="Number"
                            v-model="form.proprotion_general_special"
                            placeholder="普票占比"
                            style="width: 300px"
                          ></el-input>
                        </div>
                      </div>
                    </div>
                    <div class="ddisp pad_b_10">
                      <div class="wz_rigdq" style="width: 200px">
                        <span class="fz_13 mag_r-10" style="color: #756266"
                          >无票占比</span
                        >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="公司进购商品、设备或支付费用时"
                          placement="top-start"
                        >
                          <img
                            class="centerdq mag_r-10"
                            src="../../assets/wenhao.png"
                            width="18"
                            alt=""
                          />
                        </el-tooltip>
                      </div>
                      <div>
                        <el-input
                          type="Number"
                          v-model="form.not_ticket_special"
                          placeholder="无票占比"
                          style="width: 300px"
                        ></el-input>
                      </div>
                    </div>

                    <div class="em split_2" style="padding: 20px 0 20px 20px">
                      三、企业年薪、年终奖情况
                    </div>
                    <div class="ddisp">
                      <div>
                        <el-form-item label="公司员工年薪20万人数">
                          <el-input
                            type="number"
                            v-model="form.yearly_salary_two"
                            placeholder="请输入公司员工年薪20万人数"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="公司员工年薪30万人数">
                          <el-input
                            type="number"
                            v-model="form.yearly_salary_three"
                            placeholder="公司员工年薪30万人数"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div>
                      <el-form-item label="如何处理高薪员工的个人所得税">
                        <el-input
                          type="textarea"
                          resize="none"
                          placeholder="请输入如何处理高薪员工的个人所得税"
                          v-model="form.high_salary_handle"
                          maxlength="100"
                          style="width: 800px"
                        >
                        </el-input>
                      </el-form-item>
                      <el-form-item label="年终奖一次性奖金金额范围">
                        <el-input
                          type="textarea"
                          resize="none"
                          placeholder="请输入年终奖一次性奖金金额范围"
                          v-model="form.bonus_amount_range"
                          maxlength="100"
                          style="width: 800px"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  四、企业往来情况
                </div>
                <div class="ddisp">
                  <div class="ddisp">
                    <div class="wz_rigdq" style="width: 200px">
                      <span class="fz_13 mag_r-10" style="color: #756266"
                        >是否有借款</span
                      >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="包括股东、员工或其他公司等"
                        placement="top-start"
                      >
                        <img
                          class="centerdq mag_r-10"
                          src="../../assets/wenhao.png"
                          width="18"
                          alt=""
                        />
                      </el-tooltip>
                    </div>
                    <div>
                      <el-radio v-model="form.head_borrow" :label="0"
                        >是</el-radio
                      >
                      <el-radio v-model="form.head_borrow" :label="1"
                        >否</el-radio
                      >
                    </div>
                  </div>
                  <div>
                    <el-form-item label="是否有利息">
                      <el-radio v-model="form.head_interest" :label="0"
                        >是</el-radio
                      >
                      <el-radio v-model="form.head_interest" :label="1"
                        >否</el-radio
                      >
                    </el-form-item>
                  </div>
                </div>

                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  五、企业分红情况
                </div>
                <div class="ddisp">
                  <div>
                    <el-form-item label="公司是否按股东占股比例分红">
                      <el-radio
                        v-model="form.to_proprotional_dividend"
                        :label="0"
                        >是</el-radio
                      >
                      <el-radio
                        v-model="form.to_proprotional_dividend"
                        :label="1"
                        >否</el-radio
                      >
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="分红税是否全额申报">
                      <el-radio v-model="form.to_dividend_tax_full" :label="0"
                        >是</el-radio
                      >
                      <el-radio v-model="form.to_dividend_tax_full" :label="1"
                        >否</el-radio
                      >
                    </el-form-item>
                  </div>
                </div>

                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  六、企业社保情况
                </div>
                <div class="ddisp pad_b_20">
                  <div class="wz_rigdq" style="width: 200px">
                    <span class="fz_13 mag_r-10" style="color: #756266"
                      >社保费用是否缴纳</span
                    >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="公司社保费用是否按国家规定全额全员缴纳"
                      placement="top-start"
                    >
                      <img
                        class="centerdq mag_r-10"
                        src="../../assets/wenhao.png"
                        width="18"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                  <div>
                    <el-radio v-model="form.to_pay_whether_social" :label="0"
                      >是</el-radio
                    >
                    <el-radio v-model="form.to_pay_whether_social" :label="1"
                      >否</el-radio
                    >
                  </div>
                  <div v-if="form.to_pay_whether_social == 0">
                    <div class="ddisp pad_t_20">
                      <div>
                        <el-form-item label="现缴纳人数">
                          <el-input
                            type="Number"
                            v-model="form.number_contributors"
                            placeholder="请输入公司员工年薪20万人数"
                            style="width: 300px"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div>
                      <el-form-item label="贵公司缴纳社保计划">
                        <el-input
                          type="textarea"
                          resize="none"
                          placeholder="请输入社保计划"
                          v-model="form.payment_social_security_plan"
                          maxlength="100"
                          style="width: 800px"
                        >
                        </el-input>
                      </el-form-item>
                      <el-form-item label="如碰到员工投诉，公司如何处理">
                        <el-input
                          type="textarea"
                          resize="none"
                          placeholder="请输入"
                          v-model="form.employee_complaints_plan"
                          maxlength="100"
                          style="width: 800px"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  七、贵司对未来的期望有哪些
                </div>
                <div>
                  <el-form-item label="未来期望">
                    <el-checkbox-group v-model="future_desire">
                      <el-checkbox label="降低税务成本"></el-checkbox>
                      <el-checkbox
                        label="规避经营风险、搭建防火墙"
                      ></el-checkbox>
                      <el-checkbox
                        label="公司股权的资产化、投融资、转让、员工激励等方面的规划设计"
                      ></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="其他">
                    <el-input
                      type="textarea"
                      resize="none"
                      placeholder="请输入"
                      v-model="form.other_desire"
                      maxlength="100"
                      style="width: 800px"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="em split_2" style="padding: 20px 0 20px 20px">
                  八、贵司若进行税务合股化、想要解决哪些方面的问题
                </div>
                <div>
                  <el-form-item label="想要解决的问题">
                    <el-checkbox-group v-model="need_solve_issues">
                      <el-checkbox label="商业提成"></el-checkbox>
                      <el-checkbox label="公司合规、无票支出"></el-checkbox>
                      <el-checkbox label="老板分红或股权转让"></el-checkbox>
                      <el-checkbox label="私卡未税进账"></el-checkbox>
                      <el-checkbox label="所得税税负率过高"></el-checkbox>
                      <el-checkbox label="个税税负率过高"></el-checkbox>
                      <el-checkbox label="员工社保合规"></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="其他">
                    <el-input
                      type="textarea"
                      resize="none"
                      placeholder="请输入"
                      v-model="form.need_solve_other_issues"
                      maxlength="100"
                      style="width: 800px"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="wz_rigdq pad_t_20 bor_t_hui">
                  <el-button @click="add" type="primary" style="width: 120px"
                    >提交</el-button
                  >
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getjNschAdd } from "../../api/api";

export default {
  user_name: "kpzl_err",
  data() {
    return {
      options: [
        {
          value: 0,
          label: "小规模",
        },
        {
          value: 1,
          label: "一般纳税人",
        },
      ],
      caiding: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      list: {
        active: 2,
        order: [
          "提交订单 (无需支付)",
          "填写表单",
          "专家出具报告",
          "专家出具合规建议书",
          "更新报价并支付",
          "查看或下载成果文件",
        ],
      },
      need_solve_issues: [],
      future_desire: [],
      rules: {
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        user_tel: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          {
            pattern: /^\d{11}$/,
            message: "电话号码格式不正确",
            trigger: "blur",
          },
        ],
        enterprise_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        zhutileixing: [
          { required: true, message: "请选择主体规模", trigger: "blur" },
        ],
        enterprise_addr: [
          { required: true, message: "请输入注册地", trigger: "blur" },
        ],
        reg_date: [
          { required: true, message: "请选择注册时间", trigger: "blur" },
        ],

        principal_type: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      form: {
        enterprise_name: "",
        principal_type: null,
        enterprise_addr: "",
        reg_date: "",
        opera_business: null,
        previous_annua_income: null,
        previous_add_tax: null,
        accumulated_income_year: null,
        add_tax_year: null,
        previous_proift_year: null,
        proift_year: null,
        need_solve_tax_issues: null,
        enterprise_person_number: null,
        enterprise_person_structure: null,
        operation_entity_number: null,
        operation_entity_need: 1,
        sale_info: null,
        mojor_project: null,
        contract_info: null,
        invoice_category: null,
        not_invoice_revenue: null,
        proprotion_ticket_special: null,
        proprotion_general_special: null,
        not_ticket_special: null,
        yearly_salary_two: null,
        yearly_salary_three: null,
        high_salary_handle: null,
        bonus_amount_range: null,
        head_borrow: 0,
        head_interest: 0,
        to_proprotional_dividend: 0,
        to_dividend_tax_full: 0,
        to_pay_whether_social: 0,
        future_desire: [],
        other_desire: null,
        need_solve_issues: [],
        need_solve_other_issues: null,
        sort: 1,
        number_contributors: "",
        payment_social_security_plan: "",
        employee_complaints_plan: "",
        user_name: this.$store.state.userOrderDetaild.user_name,
        user_tel: this.$store.state.userOrderDetaild.user_tel,
      },
      uploadedFile: null,
    };
  },
  methods: {
    add() {
      if (this.form.to_pay_whether_social == 1) {
        this.form.number_contributors = "";
        this.form.payment_social_security_plan = "";
        this.form.employee_complaints_plan = "";
      }
      console.log("这是什么,", this.form.reg_date);
      this.form.sn = this.$store.state.userOrderDetaild.sn;
      this.form.order_id = this.$store.state.userOrderDetaild.id;
      this.form.info_status = 1;
      this.form.need_solve_issues = this.need_solve_issues.join(",");
      this.form.future_desire = this.future_desire.join(",");
      getjNschAdd(this.form).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.$router.push("yeWuIndex");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    cz() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = ""; // 将每个属性的值设置为空字符串
      });
      this.$refs.myForm.reset();
    },
    hetong(file) {
      this.form.hetong = file.response.data;
      console.log(file.response.data);
    },
    yinhangliushui(file) {
      this.form.yinhangliushui = file.response.data;
      console.log(file.response.data);
    },
    chengguowenjian(file) {
      this.form.chengguowenjian = file.response.data;
      console.log(file.response.data);
    },
    yewuxiangguanzhengjucailiao(file) {
      this.form.上传yewuxiangguanzhengjucailiao = file.response.data;
      console.log(file.response.data);
    },
    Touxiang(e) {
      this.form.shangchuanshenfenzhengsaomiaojian = e;
    },
    addHeYan() {},
  },
};
</script>

<style scoped></style>
