<template>
  <div style="width: 1200px;margin: 0 auto">
    <el-tabs class="ba_white pad_20 mag_t-20" v-model="activeName">
      <el-tab-pane label="订单详情" name="1">
        <order_Top></order_Top>
        <el-form ref="form" :model="form" size="small" label-width="60px" :rules="rules" >
          <div class="ba_white mag_t-20 bor_1_hui pad_10">
            <div class="fz_16 em pad_10 boxs" style="border-bottom: #969896 1px dashed">报告文件</div>
            <div class="mag_t-20 fz_14">
<!--              <span class="mag_l-10">报告:</span>-->
<!--              <div class="disp mag_l-20" v-if="form.report_file">-->
<!--                <a class="wz_blue"-->
<!--                   download="扫描件.pdf"-->
<!--                   :href="this.$store.state.httpsDown+form.report_file"-->
<!--                   target="_blank">{{form.report_file.substring(form.report_file.lastIndexOf("/") + 1)}}  ↓</a>-->
<!--              </div>-->
              <el-form-item  label="报告" prop="user_tel">
                <div v-if="form.report_file">
                  <a class="wz_blue"
                      download="扫描件.pdf"
                      :href="this.$store.state.httpsDown+form.report_file"
                      target="_blank">{{form.report_file.substring(form.report_file.lastIndexOf("/") + 1)}}  ↓</a>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="wz_rigdq pad_t_20 bor_t_hui">
            <el-button @click="$router.back()" type="primary" style="width: 120px">返回</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="流程日志" name="2">
        <log></log>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "gqsjDetail",
  data(){
    return{
      form:this.$store.state.userOrderDetaild.list,
      activeName:"1",
    }
  },
}
</script>

<style scoped>

</style>