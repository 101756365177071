<template>
<div style="width: 1200px;margin: 0 auto">
  <el-tabs class="ba_white pad_20 mag_t-20" v-model="activeName">
    <el-tab-pane label="订单详情" name="1">
      <order_Top></order_Top>
      <el-form ref="form" :model="form" size="small" label-width="160px">
        <div class="ba_white mag_t-20 bor_1_hui pad_10">
          <div class="fz_16 em pad_10 boxs" style="border-bottom: #969896 1px dashed">办结说明</div>
          <div class="pad_t_10">
            <el-form-item label="办结说明">
              <span>{{form.completion_info}}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="附件" prop="user_tel">
              <a v-if="form.report_file" class="wz_blue mag_0_20"
                 download="扫描件.pdf"
                 :href="this.$store.state.httpsDown+this.form.report_file"
                 target="_blank">{{this.form.report_file.substring(this.form.report_file.lastIndexOf("/") + 1)}}  ↓</a>
            </el-form-item>
          </div>
        </div>
        <div class="wz_rigdq pad_t_20 bor_t_hui">
          <el-button @click="$router.back()" type="primary" style="width: 120px">返回</el-button>
        </div>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="流程日志" name="2">
      <log></log>
    </el-tab-pane>
  </el-tabs>

</div>
</template>

<script>
export default {
  name: "fsDetail",
  data(){
    return{
      form:this.$store.state.userOrderDetaild.list,
      activeName:"1",
    }
  },
  mounted() {
    console.log("这是",this.form)
  }
}
</script>

<style scoped>

</style>