<template>
  <div class="fz_40 wz_jz jz_flex whe_maxv xd ba_err">
    <div
      class="xyy jd xt pad_10_0 wz_leftdq boxs"
      style="top: 0; left: 0; width: 100%; padding-left: 50px"
    >
      系统提示
    </div>
    <div class="wz_leftdq">
      <div class="wz_blue">页面不存在</div>
      <div class="pad_10_0">即将返回首页...({{ isCode }})</div>
      <div>
        <el-button type="primary" round @click="$router.push('Index')"
          >立即返回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "err",
  data() {
    return {
      isCode: 5,
    };
  },
  methods: {},
  mounted() {
    const codeChecked = setInterval(() => {
      this.isCode--;
      if (this.isCode <= 0) {
        clearInterval(codeChecked); // 清除计时器
        this.$router.push("/Index");
      }
    }, 1000);
  },
};
</script>

<style scoped>
.ba_err {
  background-image: url("../../assets/backv2.svg");
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
