<template>
<div class="jz_flex">
  <div v-if="false" style="width: 1200px" class="jz bor_1_hui">
    <div class="wz_jz fz_20 em pad_30_0">
      用户注册
    </div>
    <el-form ref="form" :model="form" style="width: 500px" class="jz">
      <el-form-item>
        <div style="padding: 20px 0 0">
          <div>
            <el-input placeholder="请输入手机号" oninput="value=value.replace(/[^0-9.]/g,'')" maxlength="11" v-model="form.mobile"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="false">
        <div class="ddisp">
          <div style="width: calc(100% - 140px)">
            <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
          </div>
          <div class="ddisp wz_rigdq" style="width: 140px">
            <el-button v-if="codeChecked" @click="addCode">获取验证码</el-button>
            <el-button v-if="codeChecked==false">{{ isCode }}</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
            <el-input placeholder="请输入新密码"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      type="password"
                      maxlength="11" v-model="form.password">
            </el-input>
      </el-form-item>
      <el-form-item>
            <el-input placeholder="请再次输入新密码"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      type="password"
                      maxlength="11" v-model="form.password_confirm">
            </el-input>
      </el-form-item>
      <el-form-item class="wz_leftdq">
        <el-checkbox v-model="checked">已阅读同意
          <span class="wz_red">
            <span @click="xieyi(1)">《服务协议》</span>
            <span>、</span>
            <span @click="xieyi(2)">《隐私政策》</span>
          </span>
        </el-checkbox>
      </el-form-item>
      <div class="ddisp pad_10_0 wz_jz">
        <div class="wz_white bor_5 xsgb" @click="go" style="padding: 10px 70px;background-color: #008080">立即注册</div>
      </div>
      <div class="pad_20_0 wz_jz">
        <span class="wz_red xsgb" @click="$router.push('login')">已有账号, 去登录</span>
      </div>
    </el-form>
  </div>
  <div class="wh_f ba_white jz bor_10" style="width: 900px;">
    <div class="flex_1 jz_flex">
      <div class="wz_jz fz_20 em pad_30_0">
        <div class="topdq">用户注册</div>
        <div class="centerdq mag_t-20">
          <img class="bor_255 bor_1_hui centerdq" :src="$store.state.logo" width="100" height="100" alt="">
        </div>
      </div>
    </div>
    <div class="flex_1 boxs" style="padding: 30px">
      <el-form ref="form" :model="form" style="width: 500px"
               class="jz pad_20">
        <el-form-item>
          <div style="padding: 20px 0 0">
            <div>
              <el-input placeholder="请输入手机号" oninput="value=value.replace(/[^0-9.]/g,'')" maxlength="11" v-model="form.mobile"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="false">
          <div class="ddisp">
            <div style="width: calc(100% - 140px)">
              <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
            </div>
            <div class="ddisp wz_rigdq" style="width: 140px">
              <el-button v-if="codeChecked" @click="addCode">获取验证码</el-button>
              <el-button v-if="codeChecked==false">{{ isCode }}</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入新密码"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    type="password"
                    maxlength="11" v-model="form.password">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请再次输入新密码"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    type="password"
                    maxlength="11" v-model="form.password_confirm">
          </el-input>
        </el-form-item>
        <el-form-item class="wz_leftdq">
          <el-checkbox v-model="checked">已阅读同意
          <span class="wz_red">
            <span @click="xieyi(1)">《服务协议》</span>
            <span>、</span>
            <span @click="xieyi(2)">《隐私政策》</span>
          </span>
          </el-checkbox>
        </el-form-item>
        <div class="ddisp pad_10_0 wz_jz">
          <el-button :disabled="!checked" type="primary"
                     class="wz_white bor_5 xsgb"
                     @click="go"
                     style="padding: 10px 70px;">立即注册</el-button>
        </div>
        <div class="pad_20_0 wz_jz">
          <span class="wz_red xsgb" @click="$router.push('login')">已有账号, 去登录</span>
        </div>
      </el-form>
    </div>
  </div>
</div>
</template>

<script>
import {getLogin, getloginCode, getuserInfo} from "../../api/api";

export default {
  name: "userInfo",
  data(){
    return{
      form:{
        register_source:4
      },
      checked:false,
      codeChecked:true,
      isCode:60
    }
  },
  methods:{
    xieyi(e){
      this.$router.push({
        path:'agreement',
        query:{uid:e}
      })
    },
    go(){
      getuserInfo({
        mobile:this.form.mobile,
        code:this.form.code,
        password:this.form.password,
        password_confirm:this.form.password_confirm,
        register_source:this.form.register_source,
      }).then(res=>{
        if (res.data.code==1){
          let params ={}
          params.account=this.form.mobile,
              params.password=this.form.password,
              params.scene=1,
              params.terminal=4
          getLogin(params).then(res=>{
            if(res.data.code==1){
              this.$message({
                message: '登录成功',
                type: 'success'
              });
              console.log(res.data.data)
              localStorage.setItem('tokenAnShui',res.data.data.token);
              localStorage.setItem('userInfoAnShui',JSON.stringify(res.data.data));
              this.$store.state.userItem = res.data.data
              this.$router.push('Index')
            }else if(res.data.code==0){
              this.$message.error(res.data.msg);
            }
          })
        }
        console.log("注册的结果",res)
        // if (res.data.code==0) {
        //   this.$notify.error({
        //     title: '错误',
        //     message: res.data.msg
        //   });
        // }else if (res.data.code==1){
        //   this.$notify({
        //     title: '成功',
        //     message: res.data.msg,
        //     type: 'success'
        //   });
        //   console.log(res)
        // }
      })
    },
    // 获取验证码
    addCode(){
      getloginCode({
        mobile:this.form.mobile,
        key:"YZMDL",
        client:this.terminal
      }).then(res=>{
        if (res.data.code==0) {
          this.$notify.error({
            title: '错误',
            message: res.data.msg
          });
        }else if (res.data.code==1){
          this.yanZheng()
        }
      })
    },
    yanZheng(){
      this.isCode = 60;  // 设置倒计时初始值为 60 秒
      const codeChecked = setInterval(() => {
        this.isCode--;
        if (this.isCode <= 0) {
          clearInterval(codeChecked);  // 清除计时器
          this.buttonDisabled = false;  // 启用按钮
        }
      }, 1000);
    }
  }
}
</script>

<style scoped>

</style>