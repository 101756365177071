<template>
<div>

</div>
</template>

<script>
export default {
  name: "kb",
  created() {
    this.$router.back()
  }
}
</script>

<style scoped>

</style>