<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>修改密码</span>
      </div>
      <div>
        <el-form ref="form" size="small" :model="form" label-width="130px" style="width: 500px">
          <el-form-item label="手机号">
            <el-input disabled v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="原密码">
            <el-input type="password" maxlength="12" v-model="form.old_password"></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input type="password" maxlength="12" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码">
            <el-input type="password" maxlength="12" v-model="form.confirm_password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flo_r pad_20" style="width: 300px">
        <el-button size="small" @click="updaPwd" type="primary">确定</el-button>
        <el-button size="small" @click="$router.back()">取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {getUpdaPwd} from "../../api/api";

export default {
  name: "updaPassword",
  data(){
    return{
      form:{}
    }
  },
  methods:{
    updaPwd(){
      getUpdaPwd(this.form).then(res=>{
        if(res.data.code==1){
          this.$message({
            message: '成功',
            type: 'success'
          });
          this.$router.push('Index')
        }else if(res.data.code==0){
          this.$message.error(res.data.msg);
          this.$router.push('login')
        }
      })
    },
    addTitle(){

    }
  },
  created() {
    this.$store.state.leftMenuId = 4
  },
  mounted() {
    this.form =JSON.parse(localStorage.getItem('userInfoAnShui'))
  }
}
</script>

<style scoped>

</style>