<template>
<div>
  <div class="ba_white" style="padding: 10px 20px">
    <div class="fz_18 em pad_20_0 bor_b_hui">个体户代开办-变更地址申请</div>
    <div class="wh_f pad_20_0">
      <div class="flex_1 pad_r_20">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="个体户名称" prop="individual_name">
            <el-input v-model="form.individual_name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="经营范围" prop="business_scope">
            <el-input type="textarea" resize="none"
                      v-model="form.business_scope"
                      maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="变更前地址" prop="past_address">
            <el-input v-model="form.past_address" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="营业执照" prop="business_license_file">
            <inputUpload class="mag_l-20" @upload="hand"></inputUpload>
          </el-form-item>
          <el-form-item label="税务体检报告" prop="tax_examination_report">
            <inputUpload class="mag_l-20" @upload="hands"></inputUpload>
          </el-form-item>
          <el-form-item label="备注信息">
            <el-input type="textarea" resize="none"
                      v-model="form.remark"
                      maxlength="100"></el-input>
          </el-form-item>
        </el-form>
        <div class="wz_rigdq">
          <el-button size="small" @click="add" type="primary">提交申请</el-button>
          <el-button size="small" @click="$router.push('orderYewu')">取消</el-button>
        </div>
      </div>
      <div class="pad_l_30 fz_16" style="width: 240px;border-left: 1px solid #ebebeb">
        <div class=" em pad_20_0">个体户变更地址流程</div>
        <el-timeline size="large " :reverse="reverse">
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {getgth_Add} from "../../api/api";

export default {
  name: "gth_dzbg",
  data(){
    return{
      reverse:false,
      form:{

      },
      rules:{
        individual_name:[{required: true, message: '请输入个体户名称', trigger: 'blur'}],
        business_scope:[{required: true, message: '请输入经营范围', trigger: 'blur'}],
        past_address:[{required: true, message: '请输入变更前地址', trigger: 'blur'}],
        business_license_file:[{required: true, message: '请输入', trigger: 'blur'}],
        tax_examination_report:[{required: true, message: '请输入', trigger: 'blur'}],
        remark:[{required: true, message: '请输入', trigger: 'blur'}],
      },
      activities: [
          {
        content: '发起变更申请',
      },
        {
        content: '审核材料',
      },
        {
        content: '若需额外付费(线下支付)',
      },
        {
        content: '变更地址(线下办理)',
      },
        {
        content: '邮寄材料(线下办理)',
      },
        {
        content: '变更完成',
      }
      ]
    }
  },
  methods:{
    add(){
      this.form.sn = this.$store.state.userOrderDetaild.item.sn
      this.form.order_id = this.$store.state.userOrderDetaild.item.id
      getgth_Add(this.form).then(res=>{
        if (res.data.code == 1){
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.$router.push('orderYewu')
        }
      })
    },
    hand(file) {
      this.form.business_license_file = file.response.data.url;
    },
    hands(file) {
      this.form.tax_examination_report = file.response.data.url;
    },
  }
}
</script>

<style scoped>

</style>